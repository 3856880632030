import { atom, useRecoilState } from "recoil";
import {
  ChildAbiFormValues,
  EventsAndFunctionsFormValues,
  NewChildParserFormValues,
  ParentContractInfoFormValues,
  ParserMetadataFormValues,
  TokenPluginFormValues,
} from "../types";
import { useStepController } from "./ChildParserStepsController";
import { toast } from "react-toastify";
import useABIDetailsClient from "../ABIDetailsClient";
import { useChildParserFormDialogOption } from "../ChildParserForm";

const isChildParserAtom = atom<boolean>({
  key: "IS_CHILD_PARSER",
  default: false,
});

export const useIsChildParser = () => {
  const [isChildParser, updateIsChildParser] = useRecoilState(isChildParserAtom);
  const setIsChildParser = (value: boolean) => updateIsChildParser(value);

  return { isChildParser, setIsChildParser };
};

const defaultValues: NewChildParserFormValues = {
  abi: "",
  contractAddress: "",
  contractStartBlock: null,
  events: [],
  functions: [],
  name: "",
  schemaName: "",
  startBlock: null,
  plugins: [],
  eventName: null,
  selectedInput: null,
};

const formState = atom<NewChildParserFormValues>({
  key: "NEW_CHILD_PARSER_FORM",
  default: defaultValues,
});

const useNewChildParserFormValues = () => {
  const [childParserFormValue, updateChildParserFormValues] = useRecoilState(formState);
  const { handleNext } = useStepController();
  const { getChildParserFunctionsAndEvents } = useABIDetailsClient();
  const { closeChildParserFormDialog } = useChildParserFormDialogOption();

  const resetEventsFunctionsAndPlugins = () => {
    updateChildParserFormValues((values) => ({
      ...values,
      events: [],
      functions: [],
      plugins: [],
    }));
  };

  const updateParentContractInfo = (values: ParentContractInfoFormValues) => {
    updateChildParserFormValues((currentValue) => ({ ...currentValue, ...values }));
    handleNext();
  };

  const updateAbiForm = (values: ChildAbiFormValues) => {
    updateChildParserFormValues((currentValue) => ({ ...currentValue, ...values }));
    getChildParserFunctionsAndEvents(values.abi);
    handleNext();
  };

  const { setIsChildParser } = useIsChildParser();
  const updateEventsAndFunctionsForm = ({ events, functions }: EventsAndFunctionsFormValues) => {
    if (events.length + functions.length === 0) {
      toast.error("Please select at least one event.");
      return;
    }
    updateChildParserFormValues((currentValue) => ({ ...currentValue, events, functions }));
    handleNext();
  };

  const updateContractStartBlock = (startBlock: number) => {
    updateChildParserFormValues((currentValue) => ({
      ...currentValue,
      contractStartBlock: startBlock,
    }));
  };

  const updateTokenPluginForm = (values: TokenPluginFormValues) => {
    updateChildParserFormValues((currentValue) => ({ ...currentValue, ...values }));
    handleNext();
  };

  const resetForm = () => {
    updateChildParserFormValues(defaultValues);
    setIsChildParser(false);
  };

  const updateParserMetadata = (values: ParserMetadataFormValues) => {
    updateChildParserFormValues((currentValue) => {
      return { ...currentValue, ...values };
    });
    setIsChildParser(true);
    closeChildParserFormDialog();
  };

  return {
    childParserFormValue,
    updateAbiForm,
    updateContractStartBlock,
    resetEventsFunctionsAndPlugins,
    updateParentContractInfo,
    updateEventsAndFunctionsForm,
    updateTokenPluginForm,
    updateParserMetadata,
    resetForm,
  };
};

export default useNewChildParserFormValues;

import React from "react";
import NewNotifications from "./NewNotifications";
import ListSubscriptions from "./ListSubscriptions";
import { Stack } from "@mui/material";
import Spacing from "../../common/Spacing";

const Notifications = () => {
  return (
    <div>
      <Stack direction={"row"} justifyContent="end">
        <NewNotifications />
      </Stack>
      <Spacing spacing={2} />
      <ListSubscriptions />
    </div>
  );
};

export default Notifications;

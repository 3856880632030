import React from "react";
import { useForm } from "react-hook-form";
import { Stack } from "@mui/material";
import StepsController from "./StepsController";
import useNewIndexerFormValues from "./FormStateHandler";
import Button from "@mui/material/Button";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import { styled } from "@mui/material/styles";

const VisuallyHiddenInput = styled("input")({
  clip: "rect(0 0 0 0)",
  clipPath: "inset(50%)",
  height: 1,
  overflow: "hidden",
  position: "absolute",
  bottom: 0,
  left: 0,
  whiteSpace: "nowrap",
  width: 1,
});

const UploadSubgraph = () => {
  const { updateSubgraphFile } = useNewIndexerFormValues();
  const methods = useForm({});
  const { handleSubmit } = methods;

  const onSubmit = () => updateSubgraphFile();

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Stack spacing={3}>
        <Button component="label" variant="contained" startIcon={<CloudUploadIcon />}>
          Upload subgraph zip file
          <VisuallyHiddenInput type="file" accept={".zip"} />
        </Button>
        <StepsController />
      </Stack>
    </form>
  );
};

export default UploadSubgraph;

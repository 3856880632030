import React, { useEffect, useMemo } from "react";
import { useDashBoardNavigator } from "./EventOrFunctionDashBoardHelper";
import { Autocomplete, Grid, Stack, TextField, Tooltip } from "@mui/material";
import Spacing from "../../common/Spacing";
import Card from "@mui/material/Card";
import Typography from "@mui/material/Typography";
import Graph from "./Graph";
import { ComponentType } from "../details/types";
import { atom, useRecoilState, useResetRecoilState } from "recoil";
import DefaultAPIDataValues, { APIData } from "../../common/rest-client/types";
import { GraphTypes } from "./Types";
import { fromISOToLocalString } from "../../common/date";
import Table from "./Table";
import { useMetabaseCollections } from "../metabase/MetabaseClient";
import useIndexerDetailsByID from "../IndexerDetailsClient";
import { useParams } from "react-router-dom";
import StatisticsTabs, { StatisticsTabsType } from "./StatisticsTabs";
import Button from "@mui/material/Button";
import useUnmarshalApiGetRequest from "../../common/rest-client/unmarshalClient";
import useUser from "../../user/useUser";
import { copyTextToClipboard } from "../../common/clipboard/copy";
import Mixpanel, { MixpanelEvents } from "../../../MixpanelConfig";
import { toast } from "react-toastify";

const statistics = [
  {
    path: (name: string, type: string, indexerId: string) =>
      `/v1/parser/${indexerId}/events/${name}/count-over-time?range=30`,
    name: "Count over time",
    type: ComponentType.event,
    graphType: GraphTypes.Chart,
  },
  {
    path: (name: string, type: string, indexerId: string) =>
      `/v1/parser/${indexerId}/functions/${name}/count-over-time?range=30`,
    name: "Count over time",
    type: ComponentType.function,
    graphType: GraphTypes.Chart,
  },
  {
    path: (name: string, type: string, indexerId: string) =>
      `/v1/parser/${indexerId}/events/${name}/gas-used-over-time?range=30`,
    name: "Gas used over time",
    type: ComponentType.event,
    graphType: GraphTypes.Chart,
  },
  {
    path: (name: string, type: string, indexerId: string) =>
      `/v1/parser/${indexerId}/functions/${name}/gas-used-over-time?range=30`,
    name: "Gas used over time",
    type: ComponentType.function,
    graphType: GraphTypes.Chart,
  },
  {
    path: (name: string, type: string, indexerId: string) =>
      `/v1/parser/${indexerId}/events/${name}/active-users?range=30`,
    name: "Daily active users",
    type: ComponentType.event,
    graphType: GraphTypes.Chart,
  },
  {
    path: (name: string, type: string, indexerId: string) =>
      `/v1/parser/${indexerId}/functions/${name}/active-users?range=30`,
    name: "Daily active users",
    type: ComponentType.function,
    graphType: GraphTypes.Chart,
  },
  {
    path: (name: string, type: string, indexerId: string) =>
      `/v1/parser/${indexerId}/events/${name}/top-callers?range=30`,
    name: "Top callers",
    type: ComponentType.event,
    graphType: GraphTypes.List,
    columns: ["from", "count"],
  },
  {
    path: (name: string, type: string, indexerId: string) =>
      `/v1/parser/${indexerId}/functions/${name}/top-callers?range=30`,
    name: "Top callers",
    type: ComponentType.function,
    graphType: GraphTypes.List,
    columns: ["from", "count"],
  },
];

const statisticsAtom = atom<Array<APIData<any>>>({
  key: "FUNCTION_OR_EVENT_DASHBOARD_STATE",
  default: statistics.map(() => DefaultAPIDataValues),
});

const useStatistics = () => {
  const [statisticsState, updateStatistics] = useRecoilState(statisticsAtom);
  const reset = useResetRecoilState(statisticsAtom);
  const get = useUnmarshalApiGetRequest();
  const getAllStatistics = (apiKey: string, name: string, type: string, indexerId: string) => {
    statistics.forEach((statistic, index) => {
      if (statistic.type !== type) return;
      get(
        apiKey,
        (setterOrUpdater) => {
          if (typeof setterOrUpdater === "function") {
            updateStatistics((valOrUpdater) => {
              const newState = [...valOrUpdater];
              newState[index] = setterOrUpdater(valOrUpdater[index]);
              return newState;
            });
          } else {
            updateStatistics((valOrUpdater) => {
              const newState = [...valOrUpdater];
              newState[index] = setterOrUpdater;
              return newState;
            });
          }
        },
        statistic.path(name, type, indexerId)
      );
    });
  };
  return {
    statisticsState,
    getAllStatistics,
    reset,
  };
};

const getCurl = (url: string, apiKey: string): string => {
  return `curl -X GET ${url}&auth_key=${apiKey}`;
};

const FunctionOrEventDashboard = () => {
  const {
    type: componentType,
    name: componentName,
    updateComponentNameAndType,
    setComponentNameAndType,
  } = useDashBoardNavigator();
  const { apiKey } = useUser();
  const { statisticsState, getAllStatistics, reset } = useStatistics();
  const { indexerDetails } = useIndexerDetailsByID();
  const indexedEventsAndFunctions = indexerDetails.data.indexedEventsAndFunctions;
  const { cloneHistory, getCloneHistory } = useMetabaseCollections();
  const { id } = useParams();
  const indexerID = id || "";

  useEffect(() => {
    if (!!apiKey && !!componentName && !!componentType) {
      reset();
      getAllStatistics(apiKey, componentName, componentType, indexerID);
      const interval = setInterval(() => {
        getAllStatistics(apiKey, componentName, componentType, indexerID);
      }, 20 * 1000);

      return () => clearInterval(interval);
    }
  }, [apiKey, componentType, componentName]);

  useEffect(getCloneHistory, []);
  useEffect(setComponentNameAndType, [indexedEventsAndFunctions]);

  const selectedEventOrFunction = useMemo(() => {
    return (
      indexedEventsAndFunctions.find(
        ({ name, type }) => name === componentName && type === componentType
      ) || null
    );
  }, [indexedEventsAndFunctions, componentType, componentName]);

  const customDashboards = cloneHistory?.data
    ?.filter(
      (history) =>
        history?.schema_name === indexerDetails?.data?.additional_details?.db_name &&
        history?.status === "SUCCESS"
    )
    .map((object) => object.collection_name)
    .join(", ");

  const onCopy = () => {
    Mixpanel.track(MixpanelEvents.CopyContent, { contentType: "cURL", source: "cURL" });
    toast.success("cURL copied");
  };

  return (
    <>
      <StatisticsTabs
        value={StatisticsTabsType.EVENT_OR_FUNCTION}
        indexerId={indexerID}
        indexerDetails={indexerDetails}
      />
      <Stack direction={"row"} justifyContent={"space-between"} alignItems={"center"}>
        <Typography>
          {customDashboards?.length > 0 ? `Custom Dashboards: ${customDashboards}` : " "}
        </Typography>
        <Stack direction={"row"} justifyContent={"right"} alignItems={"center"} spacing={2}>
          <Autocomplete
            value={selectedEventOrFunction}
            renderInput={(params) => <TextField {...params} label="Select event or function" />}
            getOptionLabel={(option) => option.name}
            groupBy={(option) => option.type}
            options={indexedEventsAndFunctions}
            sx={{ minWidth: 300 }}
            onChange={(e, value) => {
              updateComponentNameAndType(value?.name, value?.type);
            }}
          />
        </Stack>
      </Stack>
      <Spacing spacing={2} />
      <Grid container spacing={4} justifyContent={"space-around"}>
        {statisticsState.map(({ data, isSuccess, isLoading, hasError }, index) => {
          if (isLoading || isSuccess) {
            const statistic = statistics[index];
            return (
              <Grid item sm={6}>
                <Card variant={"outlined"}>
                  <Stack direction={"row"} justifyContent={"space-between"}>
                    <Typography variant={"h6"}>
                      {statistic.name} ({componentName} {componentType})
                    </Typography>
                    <Tooltip
                      title={"Copy cURL to get data generating this graph"}
                      placement={"top"}
                    >
                      <Button
                        variant={"outlined"}
                        onClick={() => {
                          copyTextToClipboard(
                            getCurl(
                              "https://api.unmarshal.com" +
                                statistic.path(componentName, componentType, indexerID),
                              apiKey
                            ),
                            onCopy,
                            () => alert("Failed to copy")
                          );
                        }}
                      >
                        Copy cURL
                      </Button>
                    </Tooltip>
                  </Stack>
                  {(() => {
                    if (statistic.graphType === GraphTypes.List) {
                      return (
                        <Table
                          isLoading={isLoading && !isSuccess}
                          hasError={hasError}
                          data={data}
                          columns={statistic?.columns}
                        />
                      );
                    }
                    return (
                      <Graph
                        isLoading={isLoading && !isSuccess}
                        hasError={hasError}
                        data={data}
                        xAxisLabelFormatter={fromISOToLocalString}
                      />
                    );
                  })()}
                </Card>
              </Grid>
            );
          } else return <></>;
        })}
      </Grid>
    </>
  );
};

export default FunctionOrEventDashboard;

import React, { useEffect, useRef } from "react";
import { Navigate, useLocation } from "react-router-dom";
import { useAuth } from "@aws-amplify/ui-react/internal";
import useUser from "../user/useUser";

// Refresh session token every 4 minutes
export const RefreshToken = () => {
  const { refreshUserSession, getUserDetails, isAuthLoading, isLoggedIn } = useUser();
  const intervalRef = useRef<NodeJS.Timeout | null>(null);

  useEffect(() => {
    if (isLoggedIn && !isAuthLoading) {
      if (intervalRef.current === null) {
        refreshUserSession();
        intervalRef.current = setInterval(() => {
          refreshUserSession();
          getUserDetails();
        }, 4 * 60 * 1000);
      }

      return () => {
        if (intervalRef.current) {
          clearInterval(intervalRef.current);
          intervalRef.current = null;
        }
      };
    }
  }, [isAuthLoading, isLoggedIn, refreshUserSession, getUserDetails]);

  return <></>;
};

function RequireAuth({ children }: { children: JSX.Element }) {
  const auth = useAuth();
  const location = useLocation();
  const { getUserDetails } = useUser();

  useEffect(() => {
    if (!auth.isLoading) {
      getUserDetails();
    }
  }, [auth.user]);

  if (auth.isLoading) {
    return <div>Loading...</div>;
  }
  if (!auth.user) {
    return <Navigate to="/auth" state={{ from: location }} replace />;
  }
  return <>{children}</>;
}

export default RequireAuth;

import React from "react";
import { StatusClasses } from "../Indexer";
import { toNumber } from "lodash";
import { Typography } from "@mui/material";
import { IndexerDetails } from "../IndexerClient";

export const indexerStatusBackgrounds = {
  "& .Deployed": {
    background: "#05452E",
  },
  "& .Completed": {
    background: "#05452E",
  },
  "& .Failed": {
    background: "#571500",
  },
  "& .InProgress": {
    background: "rgba(255, 199, 0, 0.4)",
  },
  "& .Requested": {
    background: "rgba(255, 199, 0, 0.4)",
  },
  "& .Terminated": {
    background: "#808191",
  },
  "& .Paused": {
    background: "rgba(255, 199, 0, 0.4)",
  },
};

const IndexerStatus = ({ indexer }: { indexer: IndexerDetails }) => {
  const Status = () => {
    const indexerStatus = indexer?.deploy_status;
    if (indexerStatus === "Paused") {
      return <>{StatusClasses.get(indexerStatus)}</>;
    }
    return (
      <>
        {!indexer?.sync_status
          ? StatusClasses.get(indexerStatus)
          : Math.abs(
              toNumber(indexer?.sync_status[0]?.node_top_block) -
                toNumber(indexer?.sync_status[0]?.last_indexed_block)
            ) < 100 &&
            Math.abs(
              toNumber(indexer?.sync_status[1]?.node_top_block) -
                toNumber(indexer?.sync_status[1]?.last_indexed_block)
            ) < 100
          ? "Live"
          : "Syncing"}
      </>
    );
  };
  return (
    <Typography
      sx={{ float: { xs: "right", sm: "none" }, ...indexerStatusBackgrounds }}
      display={"inline"}
    >
      <span
        style={{
          fontWeight: 500,
          fontSize: "14px",
          lineHeight: "18px",
          color: "rgba(255,255,255,0.8)",
          borderRadius: "100px",
          padding: "6px 12px",
        }}
        className={StatusClasses.get(indexer?.deploy_status)}
      >
        <Status />
      </span>
    </Typography>
  );
};

export default IndexerStatus;

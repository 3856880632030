import React, { useEffect, useRef, useState } from "react";
import Button from "@mui/material/Button";
import { ExpandMore } from "@mui/icons-material";
import {
  ClickAwayListener,
  Divider,
  Grow,
  MenuItem,
  MenuList,
  Paper,
  Popper,
  Stack,
  Typography,
} from "@mui/material";
import useIndexerDetails from "./IndexerClient";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import Spacing from "../common/Spacing";
import Background from "../../assets/images/background.png";
import IndexerStatus from "./details/IndexerStatus";
import useIndexerDetailsByID from "./IndexerDetailsClient";

const SelectParser = ({ navigateTo }: { navigateTo: (id: string, status?: string) => string }) => {
  const { indexersByUserID, getAllIndexersByUserID } = useIndexerDetails();
  const [open, setOpen] = useState(false);
  const { indexerDetails } = useIndexerDetailsByID();
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const status = searchParams.get("status") || "Running";
  const anchorRef = useRef<HTMLButtonElement>(null);
  const { id } = useParams();
  const indexerID = id || "";
  const [selectedIndexer, updateSelectedIndexer] = useState<any>(
    indexersByUserID.data?.indexer_list.find(
      (indexer) => indexer.generated_indexer_id === indexerID
    )
  );

  const handleToggle = () => {
    setOpen((prevOpen) => !prevOpen);
  };

  useEffect(() => {
    if (indexersByUserID?.isSuccess)
      updateSelectedIndexer(
        indexersByUserID.data?.indexer_list.find(
          (indexer) => indexer.generated_indexer_id === indexerID
        )
      );
  }, [indexersByUserID.data?.indexer_list]);

  useEffect(() => {
    getAllIndexersByUserID();
  }, [indexerDetails.data?.sync_status]);

  const handleClose = (event: Event | React.SyntheticEvent) => {
    if (anchorRef.current && anchorRef.current.contains(event.target as HTMLElement)) {
      return;
    }
    setOpen(false);
  };

  return (
    <>
      <Spacing spacing={2} />
      <Button
        ref={anchorRef}
        id="composition-button"
        aria-controls={open ? "composition-menu" : undefined}
        aria-expanded={open ? "true" : undefined}
        aria-haspopup="true"
        variant={"outlined"}
        sx={{
          color: "#ffffff",
          backgroundImage: `url(${Background})`,
          backgroundRepeat: "no-repeat",
          backgroundSize: "cover",
          width: "max-content",
        }}
        onClick={handleToggle}
        endIcon={<ExpandMore fontSize={"large"} />}
      >
        <Stack direction={"row"} alignItems={"center"} spacing={1}>
          <Stack justifyContent={"flex-start"} alignItems={"flex-start"}>
            <Stack direction={"row"} alignItems={"center"} spacing={1}>
              <Typography variant={"h6"}>{selectedIndexer?.indexer_name || "..."}</Typography>
              <IndexerStatus indexer={selectedIndexer} />
            </Stack>
            <Typography fontSize={"14px"} color={"text.disabled"}>
              {selectedIndexer?.contract_address}
            </Typography>
          </Stack>
        </Stack>
      </Button>
      <Popper
        open={open}
        anchorEl={anchorRef.current}
        role={undefined}
        placement="bottom-start"
        transition
        disablePortal
        sx={{ zIndex: 2, width: "max-content", maxHeight: "300px", overflow: "scroll" }}
      >
        {({ TransitionProps, placement }) => (
          <Grow
            {...TransitionProps}
            style={{
              transformOrigin: placement === "bottom-start" ? "left top" : "left bottom",
            }}
          >
            <Paper>
              <ClickAwayListener onClickAway={(e) => handleClose(e)}>
                <MenuList
                  autoFocusItem={open}
                  id="composition-menu"
                  aria-labelledby="composition-button"
                >
                  {indexersByUserID.data?.indexer_list.map((indexer) => {
                    return (
                      <>
                        <MenuItem
                          onClick={(e) => {
                            updateSelectedIndexer(indexer);
                            navigate(navigateTo(indexer.generated_indexer_id, status));
                            handleClose(e);
                          }}
                          key={indexer.generated_indexer_id}
                        >
                          <Stack direction={"row"} alignItems={"center"} spacing={1}>
                            <Stack justifyContent={"flex-start"} alignItems={"flex-start"}>
                              <Stack direction={"row"} alignItems={"center"} spacing={1}>
                                <Typography variant={"h6"}>
                                  {indexer.indexer_name || "..."}
                                </Typography>
                                <IndexerStatus indexer={indexer} />
                              </Stack>
                              <Typography fontSize={"14px"} color={"text.disabled"}>
                                {indexer.contract_address}
                              </Typography>
                            </Stack>
                          </Stack>
                        </MenuItem>
                        <Divider />
                      </>
                    );
                  })}
                </MenuList>
              </ClickAwayListener>
            </Paper>
          </Grow>
        )}
      </Popper>
    </>
  );
};

export default SelectParser;

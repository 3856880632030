import useIndexerDetailsByID, { useUpdateIndexer } from "../IndexerDetailsClient";
import React from "react";
import { IndexerType } from "../IndexerClient";
import { IconButton, Stack, Tooltip } from "@mui/material";
import { StopCircleOutlined } from "@mui/icons-material";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogActions from "@mui/material/DialogActions";
import Button from "@mui/material/Button";
import PauseCircleIcon from "@mui/icons-material/PauseCircle";
import PlayCircleIcon from "@mui/icons-material/PlayCircle";
import LoadingButton from "@mui/lab/LoadingButton";
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";

export const StopIndexer = () => {
  const { indexerDetails } = useIndexerDetailsByID();
  const { terminateIndexer } = useUpdateIndexer();
  const [open, setOpen] = React.useState(false);
  const [isSubmitting, setSubmitting] = React.useState(false);

  const openDialog = () => {
    setOpen(true);
  };

  const handleDialogClose = () => {
    setOpen(false);
  };

  const onSubmit = () => {
    setSubmitting(true);
    terminateIndexer(indexerDetails.data?.generated_indexer_id, handleDialogClose);
  };

  return (
    <>
      <Tooltip title={"Terminate Parser"}>
        <IconButton onClick={openDialog}>
          <StopCircleOutlined color={"error"} />
        </IconButton>
      </Tooltip>
      <Dialog open={open} onClose={handleDialogClose}>
        <DialogTitle id="alert-dialog-title">Are you absolutely sure?</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            This action cannot be undone. This will permanently terminate the{" "}
            <b>{indexerDetails.data?.indexer_name}</b> indexer.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleDialogClose}>Close</Button>
          <LoadingButton loading={isSubmitting} color={"secondary"} onClick={onSubmit} autoFocus>
            Terminate
          </LoadingButton>
        </DialogActions>
      </Dialog>
    </>
  );
};

export const PauseIndexer = () => {
  const { indexerDetails } = useIndexerDetailsByID();
  const { pauseIndexer } = useUpdateIndexer();
  const [open, setOpen] = React.useState(false);
  const [isSubmitting, setSubmitting] = React.useState(false);

  const openDialog = () => {
    setOpen(true);
  };

  const handleDialogClose = () => {
    setOpen(false);
  };

  const onSubmit = () => {
    setSubmitting(true);
    pauseIndexer(indexerDetails.data?.generated_indexer_id, handleDialogClose);
  };

  return (
    <>
      <Tooltip title={"Pause Parser"}>
        <IconButton onClick={openDialog}>
          <PauseCircleIcon color={"warning"} />
        </IconButton>
      </Tooltip>
      <Dialog open={open} onClose={handleDialogClose}>
        <DialogTitle id="alert-dialog-title">Are you absolutely sure?</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            This action will pause the <b>{indexerDetails.data?.indexer_name}</b> indexer.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleDialogClose}>Close</Button>
          <LoadingButton loading={isSubmitting} color={"secondary"} onClick={onSubmit} autoFocus>
            Pause
          </LoadingButton>
        </DialogActions>
      </Dialog>
    </>
  );
};

export const ResumeIndexer = () => {
  const { indexerDetails } = useIndexerDetailsByID();
  const { resumeIndexer } = useUpdateIndexer();
  const [open, setOpen] = React.useState(false);
  const [isSubmitting, setSubmitting] = React.useState(false);

  const openDialog = () => {
    setOpen(true);
  };

  const handleDialogClose = () => {
    setOpen(false);
  };

  const onSubmit = () => {
    setSubmitting(true);
    resumeIndexer(indexerDetails.data?.generated_indexer_id, handleDialogClose);
  };

  return (
    <>
      <Tooltip title={"Resume Parser"}>
        <IconButton onClick={openDialog}>
          <PlayCircleIcon color={"primary"} />
        </IconButton>
      </Tooltip>
      <Dialog open={open} onClose={handleDialogClose}>
        <DialogTitle id="alert-dialog-title">Are you absolutely sure?</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            This action will resume the <b>{indexerDetails.data?.indexer_name}</b> indexer.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleDialogClose}>Close</Button>
          <LoadingButton loading={isSubmitting} color={"secondary"} onClick={onSubmit} autoFocus>
            Resume
          </LoadingButton>
        </DialogActions>
      </Dialog>
    </>
  );
};

export const ClearIndexerData = () => {
  const { indexerDetails } = useIndexerDetailsByID();
  const { clearIndexerData } = useUpdateIndexer();
  const [open, setOpen] = React.useState(false);
  const [isSubmitting, setSubmitting] = React.useState(false);

  const openDialog = () => {
    setOpen(true);
  };

  const handleDialogClose = () => {
    setOpen(false);
  };

  const onSubmit = () => {
    setSubmitting(true);
    clearIndexerData(indexerDetails.data?.generated_indexer_id, handleDialogClose);
  };

  return (
    <>
      <Tooltip title={"Clear Indexer Data"}>
        <IconButton onClick={openDialog}>
          <DeleteForeverIcon color={"secondary"} />
        </IconButton>
      </Tooltip>
      <Dialog open={open} onClose={handleDialogClose}>
        <DialogTitle id="alert-dialog-title">Are you absolutely sure?</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            This action will clear all data in <b>{indexerDetails.data?.indexer_name}</b> indexer.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleDialogClose}>Close</Button>
          <LoadingButton loading={isSubmitting} color={"secondary"} onClick={onSubmit} autoFocus>
            Clear Data
          </LoadingButton>
        </DialogActions>
      </Dialog>
    </>
  );
};

export const Settings = () => {
  const { indexerDetails } = useIndexerDetailsByID();
  if (
    indexerDetails.data.isDeployedAndMaintained ||
    indexerDetails.data.indexer_type === IndexerType.CustomCode
  ) {
    const deployStatus = indexerDetails?.data?.deploy_status;
    if (["BuildFailed", "Failed", "ProcessingFailed", "Terminated"].includes(deployStatus)) {
      return (
        <Stack direction={"row"} spacing={1}>
          <ClearIndexerData />
        </Stack>
      );
    }
    if (["Deployed", "CrashLooping", "Paused"].includes(deployStatus)) {
      return (
        <Stack direction={"row"} spacing={1}>
          {deployStatus === "Paused" ? <ResumeIndexer /> : <PauseIndexer />}
          <StopIndexer />
        </Stack>
      );
    }
  }
  return <></>;
};

import React from "react";
import { CardMedia, Container, Fade, Grid, Stack, Tooltip, Typography } from "@mui/material";
import Button from "@mui/material/Button";
import { ArrowOutward, ArrowRightAlt } from "@mui/icons-material";
import { Link } from "react-router-dom";
import { toTopAPI } from "../common/Routes";
import Box from "@mui/material/Box";
import algorand from "../../assets/logos/algorand.png";
import arbitrum from "../../assets/logos/arbitrum.png";
import avalanche from "../../assets/logos/avalanche.png";
import bsc from "../../assets/logos/bsc.png";
import celo from "../../assets/logos/celo.svg";
import cronos from "../../assets/logos/cronos.jpg";
import ethereum from "../../assets/logos/ethereum.png";
import fantom from "../../assets/logos/fantom.png";
import fuse from "../../assets/logos/fuse.svg";
import kadena from "../../assets/logos/kadena.png";
import klaytn from "../../assets/logos/klaytn.png";
import matic from "../../assets/logos/matic.png";
import solana from "../../assets/logos/solana.svg";
import velas from "../../assets/logos/velas.png";
import xinfin from "../../assets/logos/xinfin.png";
import zilliqa from "../../assets/logos/zilliqa.png";
import discord from "../../assets/images/socials/discord.svg";
import telegram from "../../assets/images/socials/telegram.svg";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import Spacing from "../common/Spacing";
import Mixpanel, { MixpanelEvents } from "../../MixpanelConfig";
import apiIcon from "../../assets/icons/apiGreen.svg";
import indexerIcon from "../../assets/icons/IndexerGreen.svg";
import notificationsIcon from "../../assets/icons/notificationsGreen.svg";
import sdkIcon from "../../assets/icons/sdkGreen.svg";
import MuiLink from "@mui/material/Link";

const Chain = ({
  chain,
  label,
  img,
  borderRadius = "0%",
}: {
  chain: string;
  label: string;
  img: any;
  borderRadius?: string;
}) => {
  return (
    <Box
      style={{
        clipPath: "polygon(25% 0%, 75% 0%, 100% 50%, 75% 100%, 25% 100%, 0% 50%)",
        background: "linear-gradient(140deg, #77CCB9 0%, #1E725E 50%, #064137 75%)",
        width: "calc(100%*2/3)",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <Box
        sx={{
          clipPath: "polygon(25% 0%, 75% 0%, 100% 50%, 75% 100%, 25% 100%, 0% 50%)",
          backgroundColor: "#0B0F15",
          width: "98%",
          height: "98%",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          padding: { xs: "5px", sm: "10px", lg: "25px" },
        }}
      >
        <Tooltip
          title={label}
          placement={"top"}
          TransitionComponent={Fade}
          TransitionProps={{ timeout: 600 }}
        >
          <img
            style={{
              width: "70%",
              display: "block",
              objectFit: "cover",
              borderRadius: borderRadius,
            }}
            src={img}
            alt={chain}
          />
        </Tooltip>
      </Box>
    </Box>
  );
};

const services = [
  {
    name: "Unmarshal Parser",
    image: indexerIcon,
    description: "The No-code Smart Contract Indexer",
    docs: "https://docs.unmarshal.io/reference/what-is-unmarshal-parser",
  },
  {
    name: "Blazing Fast APIs",
    image: apiIcon,
    description: "Query all things related to Tokens and NFTs",
    docs: "https://docs.unmarshal.io/reference/fungibleerc20tokenbalances",
  },
  {
    name: "Smart Notifications",
    image: notificationsIcon,
    description: "Instant notifications of On-chain events",
    docs: "https://docs.unmarshal.io/reference/subscribewebhook",
  },
  {
    name: "SDKs",
    image: sdkIcon,
    description: "Typescript and Go SDK for easy integration",
    docs: "https://docs.unmarshal.io/reference/golang-sdk",
  },
];
const Homepage = () => {
  const communityCardStyle = {
    height: "100%",
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-evenly",
    cursor: "pointer",
  };

  return (
    <Container maxWidth="xl" component={"main"}>
      <Stack
        direction="column"
        justifyContent="flex-start"
        sx={{
          padding: 5,
          margin: "auto",
          borderRadius: 5,
          background: "linear-gradient(140deg, #1E2A22 0%, #215F22 50%, #406052 70%);",
        }}
      >
        <Typography fontWeight={600} fontSize={"28px"} align={"left"} color={"#a2d5c5"}>
          One platform, infinite possibilities
        </Typography>
        <Typography fontWeight={500} fontSize={"20px"} color={"#5dcca9"}>
          Create a dApp with a complete backend data suite of advanced functionalities to bring your
          vision to life.
        </Typography>
        <br />
        <Button
          variant={"contained"}
          component={Link}
          to={toTopAPI()}
          sx={{ color: "#ffffff", backgroundColor: "#0f2e25", width: "max-content" }}
        >
          Get Started <ArrowRightAlt />
        </Button>
        <Spacing spacing={1} />
        <Typography fontWeight={600} fontSize={"16px"} align={"left"} color={"#d7d4f0"}>
          Yes, it’s free. Upgrade anytime.
        </Typography>
      </Stack>
      <Stack sx={{ marginTop: 5 }}>
        <Typography fontWeight={600} fontSize={"24px"} align={"center"} color={"#a4e1d5"}>
          — Supported Chains —
        </Typography>
        <Box
          sx={{
            width: "60%",
            margin: "1% auto",
          }}
        >
          <Box sx={{ display: "grid", gridTemplateColumns: "repeat(4, 1fr)" }}>
            <Chain chain={"ethereum"} label={"Ethereum"} img={ethereum} borderRadius={"50%"} />
            <Chain chain={"bsc"} label={"BNB Smart Chain"} img={bsc} />
            <Chain chain={"matic"} label={"Polygon"} img={matic} />
            <Chain chain={"kadena"} label={"Kadena"} img={kadena} />
          </Box>
          <Box
            sx={{
              display: "grid",
              gridTemplateColumns: "repeat(4, 1fr)",
              transform: "translateX(calc(100%/8))",
              margin: "calc(-6.8%) auto",
            }}
          >
            <Chain chain={"celo"} label={"Celo"} img={celo} />
            <Chain chain={"klaytn"} label={"Klaytn"} img={klaytn} />
            <Chain chain={"cronos"} label={"Cronos"} img={cronos} borderRadius={"50%"} />
            <Chain chain={"velas"} label={"Velas"} img={velas} />
          </Box>
          <Box sx={{ display: "grid", gridTemplateColumns: "repeat(4, 1fr)" }}>
            <Chain chain={"avalanche"} label={"Avalanche"} img={avalanche} />
            <Chain chain={"solana"} label={"Solana"} img={solana} />
            <Chain chain={"fantom"} label={"Fantom"} img={fantom} />
            <Chain chain={"fuse"} label={"Fuse"} img={fuse} />
          </Box>
          <Box
            sx={{
              display: "grid",
              gridTemplateColumns: "repeat(4, 1fr)",
              transform: "translateX(calc(100%/8))",
              margin: "calc(-6.8%) auto",
            }}
          >
            <Chain chain={"xinfin"} label={"XDC Network"} img={xinfin} />
            <Chain chain={"zilliqa"} label={"Zilliqa"} img={zilliqa} borderRadius={"50%"} />
            <Chain chain={"algorand"} label={"Algorand"} img={algorand} />
            <Chain chain={"arbitrum"} label={"Arbitrum"} img={arbitrum} />
          </Box>
        </Box>
      </Stack>
      <Stack sx={{ mt: 12, mb: 10 }}>
        <Typography
          fontWeight={600}
          fontSize={"24px"}
          align={"center"}
          sx={{ mb: "1%" }}
          color={"#a4e1d5"}
        >
          Services
        </Typography>
        <Grid container spacing={4}>
          {services.map((service) => (
            <Grid item key={service.name} xs={12} sm={6} lg={3}>
              <MuiLink href={service.docs} target={"_blank"}>
                <Card
                  sx={{
                    height: "100%",
                    display: "flex",
                    flexDirection: "column",
                    border: "2px solid #0C5044",
                    background: "rgba(18,24,26,0.9)",
                  }}
                  onClick={() => {
                    Mixpanel.track(MixpanelEvents.ExternalNavigation, {
                      to: `${service.name} Docs`,
                      source: "Home page",
                    });
                  }}
                >
                  <Spacing spacing={2} />
                  <Stack direction={"row"} justifyContent={"center"}>
                    <CardMedia
                      component="img"
                      image={service.image}
                      alt={service.name}
                      sx={{ width: 50 }}
                    />
                  </Stack>
                  <CardContent sx={{ flexGrow: 1 }}>
                    <Typography
                      gutterBottom
                      variant="h5"
                      fontWeight={600}
                      textAlign={"center"}
                      color={"#a4e1d5"}
                    >
                      {service.name}
                    </Typography>
                    <Typography textAlign={"center"} color={"#53A997"}>
                      {service.description}
                    </Typography>
                  </CardContent>
                  <Spacing spacing={1} />
                </Card>
              </MuiLink>
            </Grid>
          ))}
        </Grid>
        <Spacing spacing={5} />
        <Grid container spacing={4}>
          <Grid item xs={12} md={6}>
            <MuiLink href={"https://discord.com/invite/z2Zc88HY2P"} target={"_blank"}>
              <Card
                sx={{
                  ...communityCardStyle,
                  border: " 1px solid #b2bbfa",
                  background: "rgba(18,24,26,0.9)",
                }}
                onClick={() => {
                  Mixpanel.track(MixpanelEvents.ExternalNavigation, {
                    to: "Discord",
                    source: "Home page",
                  });
                }}
              >
                <Box sx={{ display: "flex", flexDirection: "column" }}>
                  <CardContent sx={{ flexGrow: 1 }}>
                    <Stack direction={"row"} alignItems={"center"} sx={{ mb: 2, color: "#ced5f8" }}>
                      <Typography variant="h5" component="h2">
                        Join our Discord
                      </Typography>
                      <ArrowOutward color={"inherit"} />
                    </Stack>
                    <Typography color={"#b2bbfa"}>
                      Exchange ideas with more 1700+ devs on our Discord
                    </Typography>
                  </CardContent>
                </Box>
                <CardMedia component="img" sx={{ width: "10%" }} image={discord} alt="Discord" />
              </Card>
            </MuiLink>
          </Grid>
          <Grid item xs={12} md={6}>
            <MuiLink href={"https://t.me/Unmarshal_Chat"} target={"_blank"}>
              <Card
                sx={{
                  ...communityCardStyle,
                  border: "1px solid #6CACC9",
                  background: "rgba(18,24,26,0.9)",
                }}
                onClick={() => {
                  Mixpanel.track(MixpanelEvents.ExternalNavigation, {
                    to: "Telegram",
                    source: "Home page",
                  });
                }}
              >
                <Box sx={{ display: "flex", flexDirection: "column" }}>
                  <CardContent sx={{ flexGrow: 1 }}>
                    <Stack direction={"row"} alignItems={"center"} sx={{ mb: 2, color: "#9dd0e8" }}>
                      <Typography variant="h5" component="h2">
                        Join our Telegram
                      </Typography>
                      <ArrowOutward color={"inherit"} />
                    </Stack>
                    <Typography color={"#6CACC9"}>
                      Ask anything about dApp building and $MARSH
                    </Typography>
                  </CardContent>
                </Box>
                <CardMedia component="img" sx={{ width: "10%" }} image={telegram} alt="Discord" />
              </Card>
            </MuiLink>
          </Grid>
        </Grid>
      </Stack>
    </Container>
  );
};

export default Homepage;

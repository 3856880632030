import useRestClient, { PostOptions } from "./RestClient";

const useAsyncRestClient = () => {
  const { post } = useRestClient();

  return {
    post: (uri: string, options: PostOptions) =>
      new Promise((resolve, reject) =>
        post(uri, {
          ...options,
          onSuccess: (response) => {
            resolve(response);
            options?.onSuccess && options?.onSuccess(response);
          },
          onError: (error) => {
            reject(error);
            options?.onError && options.onError(error);
          },
        })
      ),
  };
};

export default useAsyncRestClient;

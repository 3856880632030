import { useSearchParams, useMatch } from "react-router-dom";
import { ComponentType } from "../details/types";
import { first } from "lodash";
import useIndexerDetailsByID from "../IndexerDetailsClient";

export const useDashBoardNavigator = () => {
  const { indexerDetails } = useIndexerDetailsByID();
  const [searchParams, setSearchParams] = useSearchParams();
  const typeInString: string = searchParams.get("type") || "";
  const type: ComponentType = typeInString as ComponentType;
  const name = searchParams.get("name") || "";
  const match = useMatch("/parser/:id/details/function-or-event-dashboard");
  const isInEventOrFunctionDashBoardPage = !!match;

  const updateComponentNameAndType = (name: any | undefined, type: string | undefined) => {
    setSearchParams(
      [
        ["name", name || ""],
        ["type", type || ""],
      ],
      { replace: true }
    );
  };

  const isNameAndTypeSelected = !name || !type;
  const setComponentNameAndType = () => {
    if (isNameAndTypeSelected) {
      const firstEventOrFunction = first(indexerDetails.data.indexedEventsAndFunctions);
      if (firstEventOrFunction) {
        updateComponentNameAndType(firstEventOrFunction?.name, firstEventOrFunction?.type);
      }
    }
  };

  return {
    type,
    name,
    isInEventOrFunctionDashBoardPage,
    setComponentNameAndType,
    updateComponentNameAndType,
  };
};

import { PluginDetails, Plugins } from "../IndexerClient";
import React, { useEffect, useState } from "react";
import { Stack, TableCell, ToggleButton, ToggleButtonGroup, Typography } from "@mui/material";
import Table from "@mui/material/Table";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import TableBody from "@mui/material/TableBody";
import { chainDetailsMap } from "../../../config";
import Spacing from "../../common/Spacing";

const PluginsOverview = ({ plugins, chain }: { plugins: Plugins; chain: string }) => {
  const [tab, setTab] = useState("events");

  const [selectedPlugins, setSelectedPlugins] = useState<PluginDetails[]>([]);

  useEffect(() => {
    if (tab === "events") setSelectedPlugins(plugins?.event_plugins || []);
    else setSelectedPlugins(plugins?.method_plugins || []);
  }, [tab]);

  const additionalDetailsStyle = {
    borderRadius: "100px",
    color: "#ffffff",
  };

  const additionalDetailsKeyStyle = {
    borderRadius: "100px",
    padding: "2px 12px",
    width: "max-content",
    border: "1px solid #197273",
    color: "#197273",
  };

  const handleChange = (e: any) => {
    setTab(e?.target?.value);
  };
  return (
    <>
      <Spacing spacing={1} />
      <ToggleButtonGroup
        color="primary"
        value={tab}
        exclusive
        onChange={handleChange}
        aria-label="Platform"
        sx={{ mt: 1 }}
      >
        <ToggleButton value="events">Event Plugins</ToggleButton>
        <ToggleButton value="functions">Function Plugins</ToggleButton>
      </ToggleButtonGroup>
      {(tab === "events" ? plugins?.event_plugins || [] : plugins.method_plugins || []).length >
      0 ? (
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>
                <Typography fontWeight={600}>Event</Typography>
              </TableCell>
              <TableCell>
                <Typography fontWeight={600}>Field Name</Typography>
              </TableCell>
              <TableCell>
                <Typography fontWeight={600}>Field Type</Typography>
              </TableCell>
              <TableCell>
                <Typography fontWeight={600}>Plugin Type</Typography>
              </TableCell>
              <TableCell>
                <Typography fontWeight={600}>Additional Details</Typography>
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {selectedPlugins.map((plugin, index) => {
              return (
                <TableRow key={`${tab}/${index}`}>
                  <TableCell>
                    <Typography>{plugin?.component_name}</Typography>
                  </TableCell>
                  <TableCell>
                    <Typography>{plugin?.field_name}</Typography>
                  </TableCell>
                  <TableCell>
                    <Typography>{plugin?.field_type}</Typography>
                  </TableCell>
                  <TableCell>
                    <Typography>{plugin?.plugin_type}</Typography>
                  </TableCell>
                  <TableCell>
                    {plugin?.plugin_values === null &&
                    plugin?.plugin_type === "native_token_price" ? (
                      <Typography sx={additionalDetailsKeyStyle}>
                        <span>Wrapped Token - </span>
                        <span style={additionalDetailsStyle}>
                          {chainDetailsMap.get(chain)?.native_token}
                        </span>
                      </Typography>
                    ) : (
                      <>
                        {plugin?.plugin_values?.contract_address && (
                          <Typography sx={additionalDetailsKeyStyle}>
                            <span>Contract Address - </span>
                            <span style={additionalDetailsStyle}>
                              {plugin?.plugin_values?.contract_address}
                            </span>
                          </Typography>
                        )}
                        {plugin?.plugin_values?.field_name && (
                          <Typography sx={additionalDetailsKeyStyle}>
                            <span>Reference Field Name - </span>
                            <span style={additionalDetailsStyle}>
                              {plugin?.plugin_values?.field_name}
                            </span>
                          </Typography>
                        )}
                        {plugin?.plugin_values?.parent_token_column_name && (
                          <Stack direction={"column"} spacing={1}>
                            <Typography sx={additionalDetailsKeyStyle}>
                              <span>Parent Schema - </span>
                              <span style={additionalDetailsStyle}>
                                {plugin?.plugin_values?.parent_schema_name}
                              </span>
                            </Typography>
                            <Typography sx={additionalDetailsKeyStyle}>
                              <span>Parent Token Column - </span>
                              <span style={additionalDetailsStyle}>
                                {plugin?.plugin_values?.parent_token_column_name}
                              </span>
                            </Typography>
                          </Stack>
                        )}
                      </>
                    )}
                  </TableCell>
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      ) : (
        <Typography align={"center"}>No plugins added!</Typography>
      )}
    </>
  );
};

export default PluginsOverview;

import React, { PropsWithChildren, useEffect, useMemo } from "react";
import { CSSObject, styled, Theme, ThemeProvider } from "@mui/material/styles";
import Box from "@mui/material/Box";
import MuiDrawer from "@mui/material/Drawer";
import MuiAppBar, { AppBarProps as MuiAppBarProps } from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import {
  Button,
  Collapse,
  createTheme,
  Grid,
  Hidden,
  ListItemButton,
  Stack,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import { Link, useParams } from "react-router-dom";
import navBarItems, { LinkType, NavBarItem, SideNavItems, SubMenu } from "./NavBarItems";
import AppTheme from "../theme/Theme";
import ProfileMenu from "./ProfileMenu";
import Spacing from "../common/Spacing";
import Logo from "./Logo";
import Mixpanel, { MixpanelEvents } from "../../MixpanelConfig";
import List from "@mui/material/List";
import useIndexerDetails from "../indexer/IndexerClient";
import MuiLink from "@mui/material/Link";
import { Socials } from "./Socials";
import telegram from "../../assets/images/socials/telegram.svg";
import discord from "../../assets/images/socials/discord.svg";
import useWhitelistUsers from "../sakhi/SakhiWhiteListClient";
import { useAuth } from "@aws-amplify/ui-react/internal";
import useUser from "../user/useUser";

const drawerWidth = 240;

const openedMixin = (theme: Theme): CSSObject => ({
  width: drawerWidth,
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
  overflowX: "hidden",
  ".side-navbar-label": {
    display: "block",
    overflow: "hidden",
    height: "25px",
  },
});

const closedMixin = (theme: Theme): CSSObject => ({
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  overflowX: "hidden",
  width: `calc(${theme.spacing(10)} + 1px)`,
  [theme.breakpoints.up("sm")]: {
    width: `calc(${theme.spacing(10)} + 1px)`,
  },
  ".side-navbar-label": {
    display: "none",
  },
});

const DrawerHeader = styled("div")(({ theme }) => ({
  ...theme.mixins.toolbar,
}));

interface AppBarProps extends MuiAppBarProps {
  open?: boolean;
}

const AppBar = styled(MuiAppBar)<AppBarProps>(({ theme }) => ({
  zIndex: theme.zIndex.drawer + 1,
}));

const Drawer = styled(MuiDrawer, { shouldForwardProp: (prop) => prop !== "open" })(
  ({ theme, open }) => ({
    width: drawerWidth,
    flexShrink: 0,
    whiteSpace: "nowrap",
    boxSizing: "border-box",
    ...(open && {
      ...openedMixin(theme),
      "& .MuiDrawer-paper": openedMixin(theme),
    }),
    ...(!open && {
      ...closedMixin(theme),
      "& .MuiDrawer-paper": closedMixin(theme),
    }),
  })
);

const TabTheme = createTheme({
  ...AppTheme,
  components: {
    ...AppTheme.components,
    MuiList: {
      styleOverrides: {
        root: {
          "&.Mui-selected": {
            backgroundColor: "rgba(255, 255, 255, 0.06)",
            borderRadius: "8px 0px 0px 8px",
            backdropFilter: "blur(110px)",
          },
        },
      },
    },
  },
});

export interface DashboardProps {
  selectedTab: SideNavItems;
  topSpacing?: number;
  subTab?: SideNavItems;
}

interface TabLinkProps {
  item: NavBarItem | SubMenu;
  parentItem: NavBarItem | SubMenu;
  indexerID: string;
}

const TabLink = ({ children, item, indexerID, parentItem }: PropsWithChildren<TabLinkProps>) => {
  function MixpanelTracking() {
    if (parentItem.value === SideNavItems.Parser) {
      Mixpanel.track(item.mixpanelEvent, {
        object: "Parser",
        indexer: indexerID,
      });
      return;
    }
    Mixpanel.track(item.mixpanelEvent);
  }

  if (item.linkType === LinkType.INTERNAL) {
    if (typeof item.link === "function")
      return (
        <Link to={item.link(indexerID)} onClick={MixpanelTracking}>
          {children}
        </Link>
      );
    return (
      <Link to={item.link} onClick={MixpanelTracking}>
        {children}
      </Link>
    );
  }
  return (
    <MuiLink color={"white"} href={item.link()} target={"_blank"} onClick={MixpanelTracking}>
      {children}
    </MuiLink>
  );
};

function filterNavItems() {
  const filteredSubMenu = navBarItems[1].subMenu.filter(
    (subItem) =>
      subItem.value !== SideNavItems.SakhiQuestions &&
      subItem.value !== SideNavItems.SakhiDashboards
  );
  const newSecondItem = { ...navBarItems[1], subMenu: filteredSubMenu };
  return [...navBarItems.slice(0, 1), newSecondItem, ...navBarItems.slice(2)];
}

export default function Dashboard(props: React.PropsWithChildren<DashboardProps>) {
  const { children, selectedTab, topSpacing, subTab } = props;
  const { id } = useParams();
  const { indexersByUserID } = useIndexerDetails();
  const indexerID =
    id || indexersByUserID?.data?.indexer_list[0]?.generated_indexer_id || "default";
  const auth = useAuth();
  const userName = auth.user?.username || "";
  const { allowedUsers, getWhitelistedUsers } = useWhitelistUsers();
  const theme = useTheme();
  const md = useMediaQuery(theme.breakpoints.up("md"));
  const { isOnFreePlan } = useUser();
  const filteredNav = useMemo(() => {
    if (allowedUsers?.data?.value?.users?.includes(userName)) {
      return navBarItems;
    }
    return filterNavItems();
  }, [allowedUsers]);

  useEffect(() => {
    getWhitelistedUsers();
  }, [userName]);

  function mixpanelTracking(account: string) {
    Mixpanel.track(MixpanelEvents.ExternalNavigation, {
      to: account,
      source: "Dashboard",
    });
  }
  return useMemo(() => {
    function VerticalTabs() {
      return (
        <ThemeProvider theme={TabTheme}>
          <List component="nav" aria-labelledby="nested-list-subheader">
            {filteredNav.map((item) => {
              return (
                <React.Fragment key={item.label}>
                  <Link
                    key={item.value}
                    to={item.link(indexerID)}
                    onClick={() => {
                      Mixpanel.track(item.mixpanelEvent);
                    }}
                  >
                    <ListItemButton
                      selected={selectedTab === item.value}
                      sx={{
                        "&.Mui-selected": {
                          backgroundColor: "rgba(255, 255, 255, 0.06)",
                          borderRadius: "8px 0px 0px 8px",
                          backdropFilter: "blur(110px)",
                          width: { md: "95%" },
                          borderRight: "2px solid #0DB678",
                        },
                        marginLeft: { md: "10px" },
                        padding: "12px 0px 12px 14px",
                      }}
                    >
                      <Stack
                        sx={{ width: "100%", pl: 1 }}
                        spacing={2}
                        direction="row"
                        alignItems={"center"}
                      >
                        <img src={item.icon} alt={item.label} width={"25px"} />
                        <Typography align="left" className="side-navbar-label">
                          {item.label}
                        </Typography>
                      </Stack>
                    </ListItemButton>
                  </Link>
                  {item.subMenu.length > 0 && (
                    <Collapse in={item.value === selectedTab} timeout="auto" unmountOnExit>
                      <List component="div">
                        {item.subMenu.map((subItem) => {
                          return (
                            <TabLink
                              key={subItem.value}
                              parentItem={item}
                              item={subItem}
                              indexerID={indexerID}
                            >
                              <ListItemButton
                                key={subItem.value}
                                selected={subTab !== undefined && subItem.value === subTab}
                                sx={{
                                  "&.Mui-selected": {
                                    backgroundColor: "rgba(255, 255, 255, 0.03)",
                                    borderRadius: "0px 8px 8px 0px",
                                    borderLeft: "2px solid #0DB678",
                                    width: "85%",
                                    backdropFilter: "blur(110px)",
                                  },
                                  cursor: "pointer",
                                  ml: { xs: 1, md: 4 },
                                }}
                              >
                                <Stack
                                  sx={{ width: "100%", pl: 1 }}
                                  spacing={2}
                                  direction="row"
                                  alignItems={"center"}
                                >
                                  <img
                                    src={subItem.icon}
                                    alt={subItem.label}
                                    width={"20px"}
                                    height={"20px"}
                                  />
                                  <Typography align="left" className="side-navbar-label">
                                    {subItem.label}
                                  </Typography>
                                </Stack>
                              </ListItemButton>
                            </TabLink>
                          );
                        })}
                      </List>
                    </Collapse>
                  )}
                </React.Fragment>
              );
            })}
          </List>
        </ThemeProvider>
      );
    }

    return (
      <Box sx={{ display: "flex" }}>
        <AppBar position="fixed">
          <Toolbar>
            <Stack
              width={"100%"}
              direction={"row"}
              justifyContent={"space-between"}
              alignItems={"center"}
            >
              <Stack direction={"row"} spacing={1}>
                <Link to={"/"}>
                  <Logo />
                </Link>
                <Hidden mdDown>
                  {isOnFreePlan && (
                    <MuiLink
                      href={"https://calendly.com/shivakumar-2/15min"}
                      target={"_blank"}
                      style={{ display: "flex", alignItems: "center" }}
                    >
                      <Typography
                        style={{
                          color: "#FFFF00",
                          marginBottom: "3px",
                          fontWeight: "bold",
                          fontSize: "1em",
                          padding: "5px 10px",
                          borderRadius: "5px",
                          background: "linear-gradient(45deg, #FFA500, #FF6347)",
                          textShadow: "0 0 5px #FFA500, 0 0 15px #FF6347",
                          animation: "pulse 1.5s infinite alternate, shake 1.5s infinite",
                          boxShadow: "0px 0px 10px #FF4500",
                        }}
                      >
                        🎉 Unlock 100 Days of Unlimited Free Access – Schedule a Call Now! 🚀
                      </Typography>
                    </MuiLink>
                  )}
                </Hidden>
              </Stack>
              <Stack
                direction={"row"}
                justifyContent={"flex-end"}
                alignItems={"center"}
                spacing={1}
              >
                <Hidden mdDown>
                  <MuiLink href={"https://discord.com/invite/z2Zc88HY2P"} target={"_blank"}>
                    <Button
                      variant={"outlined"}
                      sx={{
                        "&:hover": {
                          borderColor: "#8C9EFF",
                          color: "#8C9EFF",
                        },
                      }}
                      onClick={() => mixpanelTracking("Discord")}
                    >
                      <Typography sx={{ mr: 1 }}>Discord</Typography>
                      <img src={discord} alt="Discord Logo" height={20} />
                    </Button>
                  </MuiLink>
                </Hidden>
                <Hidden mdDown>
                  <MuiLink href={"https://t.me/Unmarshal_Chat"} target={"_blank"}>
                    <Button
                      variant={"outlined"}
                      sx={{
                        mr: 1,
                        "&:hover": {
                          borderColor: "#0088cc",
                          color: "#0088cc",
                        },
                      }}
                      onClick={() => mixpanelTracking("Telegram")}
                    >
                      <Typography sx={{ mr: 1 }}>Telegram</Typography>
                      <img src={telegram} alt="Telegram Logo" height={20} />
                    </Button>
                  </MuiLink>
                </Hidden>
                <ProfileMenu />
              </Stack>
            </Stack>
          </Toolbar>
        </AppBar>
        <Drawer variant="permanent" open={md}>
          <DrawerHeader />
          <Grid container direction="column" justifyContent="space-between" sx={{ height: "100%" }}>
            <Grid item width="100%" sx={{ maxHeight: "80vh", overflow: "scroll" }}>
              <VerticalTabs />
            </Grid>
            <Grid item xs={1} width="100%">
              {/* <ExpandButton />*/}
              <Hidden mdDown>
                <Socials />
              </Hidden>
            </Grid>
          </Grid>
        </Drawer>
        <Box component="main" sx={{ flexGrow: 1 }}>
          <DrawerHeader />
          <Spacing spacing={topSpacing === undefined ? 2 : topSpacing} />
          {children}
        </Box>
      </Box>
    );
  }, [indexerID, selectedTab, subTab, children]);
}

const walletAPIs = [
  {
    name: "Token Balances",
    link: "https://docs.unmarshal.io/reference/fungibleerc20tokenbalances",
  },
  {
    name: "Transaction History of an address",
    link: "https://docs.unmarshal.io/reference/get-v3-chain-address-address-transactions",
  },
  {
    name: "Token Transaction History",
    link: "https://docs.unmarshal.io/reference/get-v2-chain-token-address-transactions",
  },
  {
    name: "Transaction Details",
    link: "https://docs.unmarshal.io/reference/get-v1-chain-transactions-transactionhash",
  },
  {
    name: "Profit and Loss",
    link: "https://docs.unmarshal.io/reference/get-v2-chain-address-address-userdata",
  },
  {
    name: "NFT Assets of an address",
    link: "https://docs.unmarshal.io/reference/nft-assets-by-address-with-offset-based-pagination",
  },
  {
    name: "NFT Transactions of an address",
    link: "https://docs.unmarshal.io/reference/nft-transactions-by-address-with-pagination",
  },
  {
    name: "List all token IDs of an NFT contract",
    link: "https://docs.unmarshal.io/reference/nft-tokenids-of-a-contract",
  },
  {
    name: "NFT Contract Transactions",
    link: "https://docs.unmarshal.io/reference/nft-contract-transactions",
  },
];

const notificationAPI = [
  {
    name: "Webhook Transactional Notifications",
    link: "https://docs.unmarshal.io/reference/subscribewebhook",
  },
  {
    name: "Firebase Transactional Notifications",
    link: "https://docs.unmarshal.io/reference/subscribefirebasenotification",
  },
  {
    name: "Unsubscribe Notifications",
    link: "https://docs.unmarshal.io/reference/unsubscribe-1",
  },
  {
    name: "Update Firebase Server Credentials",
    link: "https://docs.unmarshal.io/reference/firebaseupdateservercredentials",
  },
  {
    name: "Refresh FCM Token",
    link: "https://docs.unmarshal.io/reference/firebaserefreshtoken",
  },
  {
    name: "List Subscriptions",
    link: "https://docs.unmarshal.io/reference/getsubscriptions",
  },
];

const priceStoreAPI = [
  {
    name: "Get Price by Symbol",
    link: "https://docs.unmarshal.io/reference/get-v1-pricestore-symbol",
  },
  {
    name: "Get Price by Address",
    link: "https://docs.unmarshal.io/reference/get-v1-pricestore",
  },
  {
    name: "Get Price for Multiple Token Addresses",
    link: "https://docs.unmarshal.io/reference/get-v1-pricestore-chain-ethereum-tokens",
  },
  {
    name: "Top Gainers",
    link: "https://docs.unmarshal.io/reference/top-gainers",
  },
  {
    name: "Top Losers",
    link: "https://docs.unmarshal.io/reference/get-v1-pricestore-chain-losers",
  },
];

const tokenstoreAPI = [
  {
    name: "Token Contract Details",
    link: "https://docs.unmarshal.io/reference/details-for-token-contract",
  },
  {
    name: "Token Symbol Details",
    link: "https://docs.unmarshal.io/reference/details-for-token-symbol",
  },
  {
    name: "Token List",
    link: "https://docs.unmarshal.io/reference/list-of-all-token",
  },
];

export const topAPI = [
  {
    name: "Wallet and NFT APIs",
    color: "#0DB678",
    apis: walletAPIs,
  },
  {
    name: "Notification APIs",
    color: "#2FEBDB",
    apis: notificationAPI,
  },
  {
    name: "Price-store APIs",
    color: "#82EDC6",
    apis: priceStoreAPI,
  },
  {
    name: "Token-store APIs",
    color: "#2DBFE0",
    apis: tokenstoreAPI,
  },
];

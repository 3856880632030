import React, { useEffect, useState } from "react";
import { alpha, styled } from "@mui/material/styles";
import FormControl from "@mui/material/FormControl";
import useSakhi from "./SakhiClient";
import Stack from "@mui/material/Stack";
import List from "@mui/material/List";
import ListItemText from "@mui/material/ListItemText";
import { Autocomplete, ListItemButton, TextField, Typography } from "@mui/material";
import LoadingButton from "@mui/lab/LoadingButton";
import useUser from "../user/useUser";
import SuggestionDAO from "./SakhiSuggestion";
import { useTableNames } from "../indexer/dataExplorer/DataExplorerClient";
import useIndexerDetailsByID from "../indexer/IndexerDetailsClient";
import { useParams } from "react-router-dom";

const BootstrapInput = styled(TextField)(({ theme }) => ({
  "label + &": {
    marginTop: theme.spacing(3),
  },
  "& .MuiOutlinedInput-root": {
    borderRadius: 10,
    position: "relative",
    border: "1px solid #ced4da",
    fontSize: 16,
    padding: "10px 12px",
    transition: theme.transitions.create(["border-color", "background-color", "box-shadow"]),
    boxShadow: `${alpha(theme.palette.primary.main, 0.25)} 0 0 0 0.2rem`,
    borderColor: theme.palette.primary.main,
  },
}));

interface SearchBarProps {
  tables: Array<string>;
  question: string;
  setQuestion: Function;
}

const sampleQuestion = [
  "Give me daily active users in the last 7 days",
  "Can you give me a list of top traded tokens",
  "Give me a graph for volume traded in the last 30 days",
];

const SearchBar = ({ tables, setQuestion, question }: SearchBarProps) => {
  const { getUserPlan } = useUser();
  const { id } = useParams();
  const indexerID = id || "";
  const { tableNames } = useTableNames();
  const { indexerDetails } = useIndexerDetailsByID();
  const [suggestionDAO, updateSuggestionDAO] = useState<SuggestionDAO>();
  const [questionOptions, updateQuestionOptions] = useState<Array<string>>([]);
  const { askSakhi, sakhiResponse } = useSakhi();

  const updateSuggestions = (suggestionDAO: SuggestionDAO) => {
    suggestionDAO.getSuggestions().then((suggestions = []) => updateQuestionOptions(suggestions));
  };

  useEffect(() => {
    SuggestionDAO.new().then((suggestionDAO) => {
      updateSuggestionDAO(suggestionDAO);
      updateSuggestions(suggestionDAO);
    });
  }, []);

  const submitForm = (e: any) => {
    e.preventDefault();
    suggestionDAO?.addSuggestions(question).then(() => updateSuggestions(suggestionDAO as any));
    // if (getUserPlan() !== plans.get("ENTERPRISE")) {
    //   toast.warning("Please upgrade to Enterprise plan to use Sakhi");
    //   return;
    // }
    askSakhi(question, tables, indexerID);
  };

  return (
    <FormControl variant="standard">
      <form onSubmit={submitForm}>
        <Stack spacing={3} justifyContent={"center"} alignItems={"center"}>
          <Stack spacing={3} width={"75%"}>
            <Autocomplete
              freeSolo
              disableClearable
              value={question}
              onChange={(e, value: any) => setQuestion(value)}
              options={questionOptions}
              renderInput={(params) => (
                <BootstrapInput
                  {...params}
                  autoFocus
                  onChange={(e) => setQuestion(e.target.value)}
                  fullWidth
                  placeholder={"Ask Sakhi about your data (in Natural Language)"}
                  InputProps={{
                    ...params.InputProps,
                    type: "search",
                  }}
                />
              )}
            />
          </Stack>
          <LoadingButton
            type={"submit"}
            loading={sakhiResponse.isLoading}
            variant={"contained"}
            disabled={!question || tableNames.isLoading || indexerDetails.isLoading}
            fullWidth={false}
          >
            Ask Sakhi
          </LoadingButton>
        </Stack>
      </form>
      {!sakhiResponse.isSuccess && !sakhiResponse.isLoading && (
        <Stack>
          <Typography color={"primary"}>
            <u>Suggested Question: </u>
          </Typography>
          <List sx={{ width: "fit-content" }}>
            {sampleQuestion.map((question) => (
              <ListItemButton key={question} onClick={() => setQuestion(question)} sx={{ py: 0 }}>
                <ListItemText primary={question} />
              </ListItemButton>
            ))}
          </List>
        </Stack>
      )}
    </FormControl>
  );
};

export default SearchBar;

import React, { useState } from "react";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import useNotificationsClient from "./NotificationClient";
import { Controller, useForm } from "react-hook-form";
import Typography from "@mui/material/Typography";
import { Autocomplete, Stack } from "@mui/material";
import LoadingButton from "@mui/lab/LoadingButton";
import useIndexerDetailsByID from "../IndexerDetailsClient";
import { ComponentType } from "../details/types";
import { isEmpty } from "lodash";
import { toast } from "react-toastify";
import { useParams } from "react-router-dom";

const CreateSubscription = ({ handleClose }: { handleClose: any }) => {
  const { id } = useParams();
  const indexerID = id || "";
  const { indexerDetails } = useIndexerDetailsByID();
  const [isSubmitting, setSubmitting] = useState(false);
  const indexedEventsAndFunctions = indexerDetails.data?.indexedEventsAndFunctions || [];
  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
    control,
  } = useForm();
  const { createSubscription } = useNotificationsClient();

  const onSubmit = ({ from_block, webhook_url, methods, events, name }: any) => {
    if (isEmpty(methods) && isEmpty(events)) {
      toast.warn("Please select at least one event or function!");
      return;
    }
    setSubmitting(true);
    const data = {
      from_block: from_block ? Number(from_block) : 0,
      webhook_url,
      methods,
      events,
      name,
      schema: indexerDetails.data.additional_details?.db_name,
    };
    createSubscription(
      indexerID,
      data,
      () => setSubmitting(false),
      () => {
        reset();
        handleClose();
      }
    );
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <DialogContent>
        <Stack spacing={3}>
          <Stack spacing={1}>
            <Typography fontWeight="500">Name*</Typography>
            <TextField
              autoFocus
              {...register("name", { required: true })}
              placeholder="Enter subscription name"
              helperText={errors?.name?.messages}
              error={!!errors?.name}
            />
          </Stack>
          <Stack spacing={1}>
            <Typography fontWeight="500">Webhook URL*</Typography>
            <TextField
              {...register("webhook_url", { required: true })}
              placeholder="Enter your webhook url"
              helperText={errors?.webhook_url?.messages}
              error={!!errors?.webhook_url}
            />
          </Stack>
          <Stack spacing={1}>
            <Typography fontWeight="500">Notification Start Block</Typography>
            <TextField
              {...register("from_block")}
              type="number"
              placeholder="Enter block number"
              helperText={errors?.from_block?.messages}
              error={!!errors?.from_block}
            />
          </Stack>
          <Stack spacing={1}>
            <Typography fontWeight="500">Select events</Typography>
            <Controller
              control={control}
              name={"events"}
              render={({ field: { onChange, name, value, onBlur } }) => (
                <Autocomplete
                  onBlur={onBlur}
                  value={value}
                  multiple
                  onChange={(e, events) => onChange(events.map((e) => e.name))}
                  renderInput={(params) => (
                    <TextField {...params} name={name} label="Please select events" />
                  )}
                  getOptionLabel={(option) => option.name}
                  filterOptions={(options) =>
                    options.filter((option) => option.type === ComponentType.event)
                  }
                  options={indexedEventsAndFunctions}
                  sx={{ minWidth: 300 }}
                />
              )}
            />
          </Stack>
          <Stack spacing={1}>
            <Typography fontWeight="500">Select Functions</Typography>
            <Controller
              control={control}
              name={"methods"}
              render={({ field: { onChange, name, value, onBlur } }) => (
                <Autocomplete
                  onBlur={onBlur}
                  value={value}
                  multiple
                  onChange={(e, methods) => onChange(methods.map((e) => e.name))}
                  renderInput={(params) => (
                    <TextField {...params} name={name} label="Please select functions" />
                  )}
                  getOptionLabel={(option) => option.name}
                  filterOptions={(options) =>
                    options.filter((option) => option.type === ComponentType.function)
                  }
                  options={indexedEventsAndFunctions}
                  sx={{ minWidth: 300 }}
                />
              )}
            />
          </Stack>
        </Stack>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose}>Cancel</Button>
        <LoadingButton loading={isSubmitting} type="submit">
          Subscribe
        </LoadingButton>
      </DialogActions>
    </form>
  );
};

const NewNotifications = () => {
  const [open, setOpen] = React.useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <React.Fragment>
      <Button variant="outlined" onClick={handleClickOpen}>
        Create Subscription
      </Button>
      <Dialog open={open} onClose={handleClose} maxWidth={"md"} fullWidth>
        <DialogTitle>New Notification Subscription</DialogTitle>
        <CreateSubscription handleClose={handleClose} />
      </Dialog>
    </React.Fragment>
  );
};

export default NewNotifications;

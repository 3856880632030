export const API_WEIGHTAGES_DOCS_LINK = "https://docs.unmarshal.io/reference/api-weights";

export const CONTACT_US_MAIL_TO_LINK = "mailto:support@unmarshal.io";

export interface Token {
  address: string;
  symbol: string;
  decimals: number;
  buyLink?: string;
  buyDescription?: string;
}

interface ChainDetails {
  explorer: string;
  api_key: string;
  unmarshalChainName: string;
  logoName: string;
  label: string;
  native_token: string;
  native_token_symbol: string;
}

export const chainDetailsMap: Map<string, ChainDetails> = new Map<string, ChainDetails>([
  [
    "ethereum-mainnet",
    {
      explorer: "https://api.etherscan.io",
      api_key: "Z4PWDMNAIY66XVXW64H9MP48IKDJKU4UQ5",
      unmarshalChainName: "ethereum",
      logoName: "ethereum",
      label: "Ethereum-Mainnet",
      native_token: "0xc02aaa39b223fe8d0a0e5c4f27ead9083c756cc2",
      native_token_symbol: "ETH",
    },
  ],
  [
    "bsc-mainnet",
    {
      explorer: "https://api.bscscan.com",
      api_key: "H386W8F86EYX2GDMB4J54RQSZ6U3DFH1DC",
      unmarshalChainName: "bsc",
      logoName: "bsc",
      label: "BSC-Mainnet",
      native_token: "0xbb4cdb9cbd36b01bd1cbaebf2de08d9173bc095c",
      native_token_symbol: "BNB",
    },
  ],
  [
    "polygon-mainnet",
    {
      explorer: "https://api.polygonscan.com",
      api_key: "81CDGRMGUB2A14VDKQFEF4E7NYGUTPTQS8",
      unmarshalChainName: "matic",
      logoName: "matic",
      label: "Polygon",
      native_token: "0x0d500b1d8e8ef31e21c99d1db9a6444d3adf1270",
      native_token_symbol: "MATIC",
    },
  ],
  [
    "avalanche-mainnet",
    {
      explorer: "https://api.snowtrace.io",
      api_key: "MC4CRUZNYM2W342XNYXD9JH583XNJCZ7K2",
      unmarshalChainName: "avalanche",
      logoName: "avalanche",
      label: "Avalanche-Mainnet",
      native_token: "0xb31f66aa3c1e785363f0875a1b74e27b85fd66c7",
      native_token_symbol: "AVAX",
    },
  ],
  [
    "optimism-mainnet-v2",
    {
      explorer: "https://api-optimistic.etherscan.io",
      api_key: "B24TGFNK8GWM8FS584M2CSMS7471G9J4SV",
      unmarshalChainName: "optimism",
      logoName: "optimism",
      label: "Optimism-Mainnet-V2",
      native_token: "0x4200000000000000000000000000000000000006",
      native_token_symbol: "ETH",
    },
  ],
  [
    "fuse-mainnet",
    {
      explorer: "https://explorer.fuse.io",
      api_key: "",
      unmarshalChainName: "fuse",
      logoName: "fuse",
      label: "Fuse-Mainnet",
      native_token: "0x0be9e53fd7edac9f859882afdda116645287c629",
      native_token_symbol: "FUSE",
    },
  ],
  [
    "klaytn-mainnet",
    {
      explorer: "https://scope.klaytn.com",
      api_key: "",
      unmarshalChainName: "klaytn",
      logoName: "klaytn",
      label: "Klaytn-Mainnet",
      native_token: "0xe4f05a66ec68b54a58b17c22107b02e0232cc817",
      native_token_symbol: "KLAY",
    },
  ],
  [
    "arbitrum-mainnet",
    {
      explorer: "https://api.arbiscan.io",
      api_key: "M4UF6EZBK7FIA9T5SI8HI6NJCT81WQT933",
      unmarshalChainName: "arbitrum",
      logoName: "arbitrum",
      label: "Arbitrum-Mainnet",
      native_token: "0x82af49447d8a07e3bd95bd0d56f35241523fbab1",
      native_token_symbol: "WETH",
    },
  ],
  [
    "velas-mainnet",
    {
      explorer: "https://evmexplorer.velas.com",
      api_key: "",
      unmarshalChainName: "velas",
      logoName: "velas",
      label: "Velas-Mainnet",
      native_token: "0xc579D1f3CF86749E05CD06f7ADe17856c2CE3126",
      native_token_symbol: "VLX",
    },
  ],
  [
    "cronos-mainnet",
    {
      explorer: "https://api.cronoscan.com",
      api_key: "MVSV435YKPD5YWRCVNHYTDGBDN9AP9DWK3",
      unmarshalChainName: "cronos",
      logoName: "cronos",
      label: "Cronos-Mainnet",
      native_token: "0x5C7F8A570d578ED84E63fdFA7b1eE72dEae1AE23",
      native_token_symbol: "CRO",
    },
  ],
  [
    "moonbeam-mainnet",
    {
      explorer: "https://api-moonbeam.moonscan.io",
      api_key: "A41BT3ZC2VJYJF2C7GBVFIICXXBP2S57ZK",
      unmarshalChainName: "moonbeam",
      logoName: "moonbeam",
      label: "Moonbeam-Mainnet",
      native_token: "0xAcc15dC74880C9944775448304B263D191c6077F",
      native_token_symbol: "GLMR",
    },
  ],
  [
    "metis-mainnet",
    {
      explorer: "https://andromeda-explorer.metis.io",
      api_key: "",
      unmarshalChainName: "metis",
      logoName: "metis",
      label: "Metis-Mainnet",
      native_token: "0xdeaddeaddeaddeaddeaddeaddeaddeaddead0000",
      native_token_symbol: "METIS",
    },
  ],
  [
    "aurora-mainnet",
    {
      explorer: "https://explorer.mainnet.aurora.dev",
      api_key: "",
      unmarshalChainName: "aurora",
      logoName: "aurora",
      label: "Aurora-Mainnet",
      native_token: "0xC9BdeEd33CD01541e1eeD10f90519d2C06Fe3feB",
      native_token_symbol: "AURORA",
    },
  ],
  [
    "mantle-mainnet",
    {
      explorer: "https://explorer.mantle.xyz",
      api_key: "",
      unmarshalChainName: "mantle",
      logoName: "mantle",
      label: "Mantle-Mainnet",
      native_token: "0x78c1b0c915c4faa5fffa6cabf0219da63d7f4cb8",
      native_token_symbol: "WMNT",
    },
  ],
  [
    "ethereum-rinkeyby",
    {
      explorer: "https://api-rinkeby.etherscan.io",
      api_key: "Z4PWDMNAIY66XVXW64H9MP48IKDJKU4UQ5",
      unmarshalChainName: "rinkeby-testnet",
      logoName: "ethereum",
      label: "Rinkeby",
      native_token: "",
      native_token_symbol: "",
    },
  ],
  [
    "bsc-testnet",
    {
      explorer: "https://api-testnet.bscscan.com",
      api_key: "H386W8F86EYX2GDMB4J54RQSZ6U3DFH1DC",
      unmarshalChainName: "bsc-testnet",
      logoName: "bsc",
      label: "BSC-Testnet",
      native_token: "",
      native_token_symbol: "",
    },
  ],
  [
    "polygon-mumbai",
    {
      explorer: "https://api-testnet.polygonscan.com",
      api_key: "81CDGRMGUB2A14VDKQFEF4E7NYGUTPTQS8",
      unmarshalChainName: "matic-testnet",
      logoName: "matic",
      label: "Mumbai",
      native_token: "",
      native_token_symbol: "",
    },
  ],
  [
    "mode-mainnet",
    {
      explorer: "https://explorer.mode.network",
      api_key: "",
      unmarshalChainName: "mode",
      logoName: "mode",
      label: "Mode-Mainnet",
      native_token: "",
      native_token_symbol: "ETH",
    },
  ],
  [
    "manta-mainnet",
    {
      explorer: "https://pacific-explorer.manta.network",
      api_key: "",
      unmarshalChainName: "manta-mainnet",
      logoName: "mantle",
      label: "Manta-Mainnet",
      native_token: "",
      native_token_symbol: "MNT",
    },
  ],
  [
    "base-mainnet",
    {
      explorer: "https://api.basescan.org",
      api_key: "KSHU7V9373A8E768WMBNWE2AS18UTY48G4",
      unmarshalChainName: "base-mainnet",
      logoName: "base",
      label: "Base-Mainnet",
      native_token: "0x4200000000000000000000000000000000000006",
      native_token_symbol: "WETH",
    },
  ],
  [
    "redbelly-testnet",
    {
      explorer: "https://explorer.testnet.redbelly.network",
      api_key: "",
      unmarshalChainName: "redbelly-testnet",
      logoName: "redbelly",
      label: "Redbelly-Testnet",
      native_token: "0x4200000000000000000000000000000000000006",
      native_token_symbol: "RBNT",
    },
  ],
]);

export const SubgraphSupportedChains: Map<string, ChainDetails> = new Map<string, ChainDetails>([
  [
    "base-mainnet",
    {
      explorer: "https://api.basescan.org",
      api_key: "KSHU7V9373A8E768WMBNWE2AS18UTY48G4",
      unmarshalChainName: "base-mainnet",
      logoName: "base",
      label: "Base-Mainnet",
      native_token: "0x4200000000000000000000000000000000000006",
      native_token_symbol: "WETH",
    },
  ],
  [
    "mantle-mainnet",
    {
      explorer: "https://explorer.mantle.xyz",
      api_key: "",
      unmarshalChainName: "mantle",
      logoName: "mantle",
      label: "Mantle-Mainnet",
      native_token: "0x78c1b0c915c4faa5fffa6cabf0219da63d7f4cb8",
      native_token_symbol: "WMNT",
    },
  ],
]);

const indexerSupportedChains = new Map<string, string>([
  ["Base-Mainnet", "base-mainnet"],
  ["Ethereum-Mainnet", "ethereum-mainnet"],
  ["BSC-Mainnet", "bsc-mainnet"],
  ["Polygon", "polygon-mainnet"],
  ["Avalanche-Mainnet", "avalanche-mainnet"],
  ["Optimism-Mainnet-V2", "optimism-mainnet-v2"],
  ["Fuse-Mainnet", "fuse-mainnet"],
  ["Klaytn-Mainnet", "klaytn-mainnet"],
  ["Arbitrum-Mainnet", "arbitrum-mainnet"],
  ["Rinkeyby", "ethereum-rinkeyby"],
  ["BSC-Testnet", "bsc-testnet"],
  ["Mumbai", "polygon-mumbai"],
  ["Velas-Mainnet", "velas-mainnet"],
  ["Cronos-Mainnet", "cronos-mainnet"],
  ["Moonbeam-Mainnet", "moonbeam-mainnet"],
  ["Metis-Mainnet", "metis-mainnet"],
  ["Aurora-Mainnet", "aurora-mainnet"],
  ["Manta-Mainnet", "manta-mainnet"],
]);

export const indexerConfig = {
  indexerGenerationEndPoint: "/cis/v1/indexer/generate",
  parentChildParserGenerationEndpoint: "/cis/v1/indexer/generate-parent-child",
  indexerListByUserEndpoint: "/cis/v1/indexers",
  indexerSuggestedDBNameEndpoint: "/cis/v1/schema/suggestion",
  existingSchemaNamesEndpoint: "/cis/v1/indexer/schemas",
  fetchConstantsEndpoint: "/cis/v1/constants",
  subscribeNotifications: (indexerId: string) => `/cis/v1/indexer/${indexerId}/subscribe`,
  getSubscriptions: (indexerId: string) => `/cis/v1/indexer/${indexerId}/subscriptions`,
  deleteSubscription: (subId: string) => `/cis/v1/indexer/subscription/${subId}`,
  indexerSupportedChains,
  chainsLabels: [
    "Base-Mainnet",
    "Ethereum-Mainnet",
    "BSC-Mainnet",
    "Polygon",
    "Avalanche-Mainnet",
    "Optimism-Mainnet-V2",
    "Fuse-Mainnet",
    "Klaytn-Mainnet",
    "Arbitrum-Mainnet",
    "Rinkeyby",
    "BSC-Testnet",
    "Mumbai",
    "Velas-Mainnet",
    "Cronos-Mainnet",
    "Moonbeam-Mainnet",
    "Metis-Mainnet",
    "Aurora-Mainnet",
  ],
  chains: Array.from(chainDetailsMap, ([key, details]) => {
    return { label: details.label, value: key };
  }),
  subgraphSupportedChains: Array.from(SubgraphSupportedChains, ([key, details]) => {
    return { label: details.label, value: key };
  }),
};

export const sakhiConfig = {
  dashboardListEndpoint: "/cis/v1/sakhi/dashboards",
};

export const abiDetailsConfig = {
  abiDetails: "/cis/v1/abi/details",
};

export function getUnmarshalChainName(chain: string) {
  const chainDetails = chainDetailsMap.get(chain);
  return chainDetails?.unmarshalChainName || "";
}

export function getChainLogoName(chain: string) {
  let chainDetails = chainDetailsMap.get(chain);
  if (!chainDetails) {
    chainDetails = SubgraphSupportedChains.get(chain);
  }
  return chainDetails?.logoName || "";
}

export function isTestnet(chain: string) {
  return chain === "ethereum-rinkeyby" || chain === "bsc-testnet" || chain === "polygon-mumbai";
}

export const VideoCallCampaign = "VIDEO_CALL";

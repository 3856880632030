interface Suggestion {
  question: string;
  time: number;
}

export default class SuggestionDAO {
  private static readonly dbName = "SuggestionDB";
  private static readonly dbVersion = 1;
  private static readonly objectStoreName = "suggestions";
  private readonly db: IDBDatabase;

  private constructor(db: IDBDatabase) {
    this.db = db;
  }

  static async new(): Promise<SuggestionDAO> {
    const request = window.indexedDB.open(this.dbName, this.dbVersion);
    return new Promise((resolve, reject) => {
      request.onsuccess = (event) => {
        const db = (event.target as IDBRequest).result;
        resolve(new SuggestionDAO(db));
      };
      request.onerror = (event) => {
        reject((event.target as IDBRequest).error);
      };
      request.onupgradeneeded = (event) => {
        const db = (event.target as IDBRequest).result;
        db.createObjectStore(this.objectStoreName, { keyPath: "question" });
      };
    });
  }

  private async deleteSuggestion(question: string): Promise<void> {
    return new Promise((resolve, reject) => {
      const transaction = this.db.transaction([SuggestionDAO.objectStoreName], "readwrite");
      const objectStore = transaction.objectStore(SuggestionDAO.objectStoreName);
      objectStore.delete(question);
      transaction.onerror = (event) => {
        console.log("error: ", (event.target as IDBRequest).error);
        reject((event.target as IDBRequest).error);
      };
      transaction.oncomplete = () => {
        resolve();
      };
    });
  }

  private async appendSuggestions(question: string): Promise<void> {
    const transaction = this.db.transaction([SuggestionDAO.objectStoreName], "readwrite");
    const objectStore = transaction.objectStore(SuggestionDAO.objectStoreName);

    return new Promise((resolve, reject) => {
      objectStore.add({ question, time: new Date().getTime() });
      transaction.onerror = (event) => {
        reject((event.target as IDBRequest).error);
      };
      transaction.oncomplete = () => {
        resolve();
      };
    });
  }

  public async addSuggestions(question: string): Promise<void> {
    await this.deleteSuggestion(question).finally(() => this.appendSuggestions(question));
  }

  public async getSuggestions(): Promise<Array<string>> {
    const transaction = this.db.transaction([SuggestionDAO.objectStoreName], "readonly");
    const objectStore = transaction.objectStore(SuggestionDAO.objectStoreName);
    const suggestions: Suggestion[] = [];

    return new Promise((resolve, reject) => {
      objectStore.openCursor().onsuccess = (event) => {
        const cursor = (event.target as IDBRequest).result;
        if (cursor) {
          suggestions.push(cursor.value);
          cursor.continue();
        } else {
          resolve(suggestions.sort((a, b) => b.time - a.time).map((item) => item.question));
        }
      };
      transaction.onerror = (event) => {
        reject((event.target as IDBRequest).error);
      };
    });
  }
}

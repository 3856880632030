import React, { useState } from "react";
import {
  Card,
  CardContent,
  Container,
  FormControlLabel,
  IconButton,
  Radio,
  RadioGroup,
  Slider,
  TextField,
  Typography,
} from "@mui/material";
import Mixpanel, { MixpanelEvents } from "../../MixpanelConfig";
import CloseIcon from "@mui/icons-material/Close";
import Spacing from "../common/Spacing";
import { useNavigate } from "react-router-dom";
import { styled } from "@mui/material/styles";
import Button from "@mui/material/Button";
import { useForm } from "react-hook-form";
import { toast } from "react-toastify";

const MuiSlider = styled(Slider)({
  color: "#0DB678",
  width: "70%",
  height: 8,
  "& .MuiSlider-track": {
    border: "none",
  },
  "& .MuiSlider-thumb": {
    height: 24,
    width: 24,
    backgroundColor: "#fff",
    border: "2px solid currentColor",
    "&:focus, &:hover, &.Mui-active, &.Mui-focusVisible": {
      boxShadow: "inherit",
    },
    "&:before": {
      display: "none",
    },
  },
});

const Feedback = () => {
  const navigate = useNavigate();

  const methods = useForm<any>();
  const {
    handleSubmit,
    register,
    setValue,
    formState: { errors },
  } = methods;

  const [recommend, setRecommend] = useState<number>(1);
  const [ease, setEase] = useState<number>(1);
  const [learnFrom, setLearnFrom] = useState("");
  const [accomplishGoals, setAccomplishGoals] = useState("");

  const onSubmit = ({ learnFromOthers, accomplishGoalsReason }: any) => {
    Mixpanel.track(MixpanelEvents.Feedback, {
      HowLikelyAreYouToRecommendUnmarshalToAFriendOrColleague: recommend,
      WhereDidYouGetToKnowAboutUnmarshalFrom: learnFrom,
      OtherSources: learnFromOthers,
      DoesUnmarshalHelpYouAccomplishYourGoals: accomplishGoals,
      ReasonForNotBeingAbleToAccomplishGoal: accomplishGoalsReason,
      EaseOfUsingUnmarshalProducts: ease,
    });
    toast.success("Thank you for your feedback!");
    navigate("/");
  };
  const marks = [
    { value: 1, label: 1 },
    { value: 2, label: 2 },
    { value: 3, label: 3 },
    { value: 4, label: 4 },
    { value: 5, label: 5 },
    { value: 6, label: 6 },
    { value: 7, label: 7 },
    { value: 8, label: 8 },
    { value: 9, label: 9 },
    { value: 10, label: 10 },
  ];
  return (
    <Container maxWidth={"md"} sx={{ mt: 5 }}>
      <Card sx={{ p: 2 }}>
        <CardContent>
          <IconButton
            sx={{ float: "right" }}
            onClick={() => {
              Mixpanel.track(MixpanelEvents.CloseFeedbackForm);
              navigate("/");
            }}
          >
            <CloseIcon />
          </IconButton>
          <Typography sx={{ mb: 2 }} fontSize={"24px"} fontWeight={700}>
            Feedback Form
          </Typography>
          <Spacing spacing={2} />
          <form onSubmit={handleSubmit(onSubmit)}>
            <Typography fontSize="18px" fontWeight={600}>
              How likely are you to recommend Unmarshal to a friend or colleague? (1 – 10){" "}
              <span style={{ color: "red" }}>*</span>
            </Typography>
            <MuiSlider
              min={1}
              max={10}
              step={1}
              value={recommend}
              valueLabelDisplay="off"
              marks={marks}
              onChange={(event: Event) =>
                setRecommend(Number((event?.target as HTMLInputElement)?.value))
              }
            />
            <Spacing spacing={1} />
            <Typography fontSize="18px" fontWeight={600}>
              Where did you get to know about Unmarshal from?{" "}
              <span style={{ color: "red" }}>*</span>
            </Typography>
            <RadioGroup
              name="learnFrom"
              value={learnFrom}
              onChange={(event, value) => {
                setValue("learnFrom", value);
                setLearnFrom(value);
              }}
            >
              <FormControlLabel
                value="socials"
                control={<Radio required />}
                label="Social platforms"
              />
              <FormControlLabel
                value="friendOrColleague"
                control={<Radio required />}
                label="Friend / Colleague"
              />
              <FormControlLabel
                value="discovered"
                control={<Radio required />}
                label="Discovered on my own"
              />
              <FormControlLabel value="others" control={<Radio />} label="Others" />
            </RadioGroup>
            <Spacing spacing={1} />
            {learnFrom === "others" && (
              <>
                <Typography>
                  Please specify <span style={{ color: "red" }}>*</span>
                </Typography>
                <TextField
                  sx={{ width: "70%" }}
                  autoFocus
                  {...register("learnFromOthers", { required: learnFrom === "others" })}
                  error={!!errors?.learnFromOthers}
                  helperText={errors?.learnFromOthers?.messages}
                />
              </>
            )}
            <Spacing spacing={1} />
            <Typography fontSize="18px" fontWeight={600}>
              Does using Unmarshal products help you accomplish your goals?{" "}
              <span style={{ color: "red" }}>*</span>
            </Typography>
            <RadioGroup
              name="accomplishGoals"
              value={accomplishGoals}
              onChange={(event, value) => {
                setValue("accomplishGoals", value);
                setAccomplishGoals(value);
              }}
            >
              <FormControlLabel value="yes" control={<Radio required />} label="Yes" />
              <FormControlLabel value="no" control={<Radio required />} label="No" />
            </RadioGroup>
            <Spacing spacing={1} />
            {accomplishGoals === "no" && (
              <>
                <Typography>
                  Please specify your reason <span style={{ color: "red" }}>*</span>
                </Typography>
                <TextField
                  sx={{ width: "70%" }}
                  autoFocus
                  {...register("accomplishGoalsReason", { required: accomplishGoals === "no" })}
                  error={!!errors?.accomplishGoalsReason}
                  helperText={errors?.accomplishGoalsReason?.messages}
                />
              </>
            )}
            <Spacing spacing={1} />
            <Typography fontSize="18px" fontWeight={600}>
              How easy is the product to use? (1-10) <span style={{ color: "red" }}>*</span>
            </Typography>
            <MuiSlider
              aria-label="Restricted values"
              value={ease}
              min={1}
              max={10}
              step={1}
              valueLabelDisplay="off"
              marks={marks}
              onChange={(event: Event) =>
                setEase(Number((event?.target as HTMLInputElement)?.value))
              }
            />
            <Spacing spacing={1} />
            <Button type={"submit"} variant={"contained"}>
              Submit
            </Button>
          </form>
        </CardContent>
      </Card>
    </Container>
  );
};

export default Feedback;

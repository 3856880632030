import React from "react";
import { Grid, Hidden, Stack, Tooltip, Typography, useMediaQuery, useTheme } from "@mui/material";
import { Download, KeyboardDoubleArrowRight } from "@mui/icons-material";
import Copy from "../../common/clipboard/copy";
import { LinearProgressWithLabel } from "./IndexerDetails";
import { APIData } from "../../common/rest-client/types";
import IndexerStatus from "./IndexerStatus";
import useIndexerDetails, { SyncStatusDetails } from "../IndexerClient";
import Mixpanel, { MixpanelEvents } from "../../../MixpanelConfig";
import PluginsOverview from "./PluginsOverview";
import { ExtendedIndexerDetails } from "../IndexerDetailsClient";
import Spacing from "../../common/Spacing";
import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { toParserOverview } from "../../common/Routes";
import { Settings } from "./Settings";

const SyncStatus = ({
  sync_status,
  isChild,
}: {
  sync_status: SyncStatusDetails;
  isChild: boolean;
}) => {
  return (
    <>
      <Stack direction={"row"} justifyContent={"space-evenly"} alignItems={"start"} spacing={1}>
        <Typography
          fontWeight={600}
          sx={{
            color: "#197273",
            borderRadius: "100px",
            padding: "4px 8px",
            border: "1px solid #197273",
            height: "max-content",
            width: "100px",
            textAlign: "center",
          }}
        >
          {sync_status?.sync_type}
        </Typography>
        <Stack direction={"column"} width={"100%"}>
          <LinearProgressWithLabel sync_status={sync_status} isChild={isChild} />
          <Hidden smUp>
            <Typography>Start Block {sync_status?.start_block || 0}</Typography>
            <Typography>Last indexed block {sync_status?.last_indexed_block || "--"}</Typography>
            <Typography fontWeight={600} display={"inline"}>
              Node top block{" "}
            </Typography>
            <Typography display={"inline"}>{sync_status?.node_top_block || "--"}</Typography>
          </Hidden>
          <Hidden smDown>
            <Grid container>
              <Grid item xs={4} display={"flex"} justifyContent={"left"}>
                <Stack direction={"column"} alignItems={"start"}>
                  <Typography color={"#197273"} fontWeight={600}>
                    {sync_status?.start_block || 0}
                  </Typography>
                  <Typography color={"#808191"}>Start block</Typography>
                </Stack>
              </Grid>
              <Grid item xs={4} display={"flex"} justifyContent={"center"}>
                <Stack direction={"column"} alignItems={"center"}>
                  <Typography color={"#197273"} fontWeight={600}>
                    {sync_status?.last_indexed_block || "--"}
                  </Typography>
                  <Typography color={"#808191"}>Last indexed block</Typography>
                </Stack>
              </Grid>
              <Grid item xs={4} display={"flex"} justifyContent={"right"}>
                <Stack direction={"column"} alignItems={"end"}>
                  <Typography color={"#197273"} fontWeight={600}>
                    {sync_status?.node_top_block || "--"}
                  </Typography>{" "}
                  <Typography color={"#808191"}>Node top block</Typography>
                </Stack>
              </Grid>
            </Grid>
          </Hidden>
        </Stack>
      </Stack>
    </>
  );
};

const IndexerOverview = ({
  indexerDetails,
}: {
  indexerDetails: APIData<ExtendedIndexerDetails>;
}) => {
  const [parentIndexer, updateParentIndexerName] = useState("");
  const { indexersByUserID } = useIndexerDetails();
  const isChild = indexerDetails?.data?.parent_auto_generator_id !== "";

  useEffect(() => {
    if (indexersByUserID?.data?.indexer_list && isChild) {
      const parent = indexersByUserID?.data?.indexer_list.find((indexer) => {
        return indexer.generated_indexer_id === indexerDetails?.data?.parent_auto_generator_id;
      });
      updateParentIndexerName(parent?.indexer_name || "");
    }
  }, [indexersByUserID]);
  const theme = useTheme();
  const md = useMediaQuery(theme.breakpoints.down("md"));
  return (
    <>
      <Stack direction={"row"} justifyContent={"space-between"} spacing={1} alignItems={"center"}>
        <Typography alignItems={"center"}>
          <span style={{ color: "#197273", fontWeight: "600", fontSize: "24px" }}>
            {indexerDetails?.data?.indexer_name || ""}
          </span>
          {indexerDetails?.data?.contract_address &&
            indexerDetails?.data?.parent_auto_generator_id === "" && (
              <>
                &nbsp; -{" "}
                <Copy
                  content={indexerDetails?.data?.contract_address}
                  contentType={"Contract address"}
                  source={"Parser details"}
                >
                  {indexerDetails?.data?.contract_address}
                </Copy>
              </>
            )}
        </Typography>
        <Settings />
      </Stack>
      <Typography sx={{ mb: 1 }}>
        {indexerDetails?.data?.additional_details?.description}
      </Typography>{" "}
      <Stack direction={md ? "column" : "row"} justifyContent={"space-evenly"} spacing={1}>
        <Stack
          direction={"column"}
          width={"40%"}
          sx={{
            padding: 4,
            border: "1px solid rgba(255, 255, 255, 0.2)",
            borderRadius: "16px",
          }}
        >
          <Typography fontSize={"20px"} fontWeight={700} sx={{ mb: 1 }}>
            Details
          </Typography>
          <Stack direction={"row"} justifyContent={"space-between"} alignItems={"center"}>
            <Typography fontWeight={600} sx={{ mb: 1 }}>
              Chain &nbsp;
            </Typography>
            <Typography style={{ fontWeight: 600, color: "#197273" }}>
              {indexerDetails?.data?.chain}
            </Typography>
          </Stack>
          {indexerDetails?.data?.additional_details?.db_name && (
            <Stack direction={"row"} justifyContent={"space-between"} alignItems={"center"}>
              <Typography fontWeight={600} sx={{ mb: 1 }}>
                Schema &nbsp;
              </Typography>
              <Typography style={{ fontWeight: 600, color: "#197273" }}>
                {indexerDetails?.data?.additional_details?.db_name}
              </Typography>
            </Stack>
          )}
          <Stack direction={"row"} justifyContent={"space-between"} alignItems={"center"}>
            <Typography fontWeight={600} sx={{ mb: 1 }}>
              ID &nbsp;
            </Typography>
            <Typography style={{ fontWeight: 600, color: "#197273", textAlign: "right" }}>
              <Copy
                content={indexerDetails?.data?.generated_indexer_id}
                contentType={"Parser Generated ID"}
                source={"Parser details"}
              >
                {indexerDetails?.data?.generated_indexer_id}
              </Copy>
            </Typography>
          </Stack>
          {isChild && (
            <Stack
              direction={"row"}
              justifyContent={"space-between"}
              alignItems={"center"}
              spacing={1}
              sx={{ mb: 1 }}
            >
              <Typography fontWeight={600}>Parent</Typography>
              <Link
                to={toParserOverview(indexerDetails?.data?.parent_auto_generator_id)}
                style={{
                  textDecoration: "underline",
                  textDecorationColor: "#197273",
                  color: "#197273",
                }}
              >
                <Stack direction={"row"} alignItems={"center"}>
                  <Typography fontWeight={600}>{parentIndexer}</Typography>
                  <KeyboardDoubleArrowRight />
                </Stack>
              </Link>
            </Stack>
          )}
          {indexerDetails?.data?.download_link && (
            <a
              style={{ color: "#197273" }}
              onClick={() =>
                Mixpanel.track(MixpanelEvents.DownloadSourceCode, {
                  parser: indexerDetails?.data?.indexer_name,
                })
              }
              href={indexerDetails?.data?.download_link}
            >
              <Stack direction={"row"} alignItems={"center"} color={"#197273"}>
                <Tooltip title={indexerDetails?.data?.download_link || ""} placement={"top-start"}>
                  <Typography sx={{ mb: 1 }} fontWeight={600}>
                    Download source code
                  </Typography>
                </Tooltip>
                <Download color={"inherit"} />
              </Stack>
            </a>
          )}
        </Stack>
        <Stack
          direction={"column"}
          width={"60%"}
          sx={{
            padding: 4,
            border: "1px solid rgba(255, 255, 255, 0.2)",
            borderRadius: "16px",
          }}
        >
          <Stack direction={"row"} spacing={1} alignItems={"center"}>
            <Typography fontSize={"20px"} fontWeight={700}>
              Status
            </Typography>
            <IndexerStatus indexer={indexerDetails.data} />
          </Stack>
          <Spacing spacing={1} />
          {indexerDetails?.data?.contract_address &&
          ["Deployed", "CrashLooping", "Paused"].includes(
            indexerDetails?.data?.deploy_status || ""
          ) ? (
            <>
              {indexerDetails?.data?.sync_status &&
                indexerDetails?.data?.sync_status?.map((sync_status) => (
                  <SyncStatus
                    key={sync_status.sync_type}
                    sync_status={sync_status}
                    isChild={isChild}
                  />
                ))}
            </>
          ) : (
            <Typography align={"center"}>Sync Details Unavailable!</Typography>
          )}
        </Stack>
      </Stack>
      {indexerDetails?.data?.additional_details?.events?.length > 0 && (
        <>
          <Typography fontWeight={600} sx={{ mt: 1 }}>
            Events
          </Typography>
          <div
            style={{
              display: "flex",
              width: "70vw",
              overflow: "scroll",
            }}
          >
            {indexerDetails?.data?.additional_details?.events?.map((event) => {
              return (
                <Tooltip describeChild title={event.signature} key={event.signature}>
                  <Typography
                    display={"inline"}
                    key={event.signature}
                    sx={{
                      ml: 1,
                      background: "#197273",
                      borderRadius: "100px",
                      padding: "4px 12px",
                      mt: 1,
                    }}
                  >
                    {event.name}
                  </Typography>
                </Tooltip>
              );
            })}
          </div>
        </>
      )}
      {indexerDetails?.data?.additional_details?.functions?.length > 0 && (
        <>
          <Typography fontWeight={600} sx={{ mt: 1 }}>
            Functions
          </Typography>
          <div
            style={{
              display: "flex",
              width: "70vw",
              overflow: "scroll",
            }}
          >
            {indexerDetails?.data?.additional_details?.functions?.map((func) => {
              return (
                <Tooltip describeChild title={func.signature} key={func.signature}>
                  <Typography
                    display={"inline"}
                    sx={{
                      ml: 1,
                      mt: 1,
                      background: "#197273",
                      borderRadius: "100px",
                      padding: "4px 12px",
                    }}
                  >
                    {func.name}
                  </Typography>
                </Tooltip>
              );
            })}
          </div>
        </>
      )}
      <PluginsOverview
        plugins={indexerDetails?.data?.additional_details?.plugins}
        chain={indexerDetails?.data?.chain}
      />
    </>
  );
};

export default IndexerOverview;

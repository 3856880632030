import React, { useEffect } from "react";
import Stepper from "@mui/material/Stepper";
import Step from "@mui/material/Step";
import StepLabel from "@mui/material/StepLabel";
import Container from "@mui/material/Container";
import Spacing from "../../common/Spacing";
import ChainAndContractForm from "./ChainAndContractForm";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import { useSteps, useStepController } from "./StepsController";
import AbiForm from "./AbiForm";
import EventsAndFunctionForm from "./EventsAndFunctionForm";
import ParserMetadataForm from "./ParserMetadataForm";
import TokenPluginForm from "./TokenPluginForm";
import CloseIcon from "@mui/icons-material/Close";
import { useNavigate, useSearchParams } from "react-router-dom";
import { toIndexer } from "../../common/Routes";
import { IconButton } from "@mui/material";
import Mixpanel, { MixpanelEvents } from "../../../MixpanelConfig";
import useNewIndexerFormValues from "./FormStateHandler";
import ChildParserForm from "./ChildParserForm";
import { GeneratorType } from "./types";
import HasSubgraphForm from "./CheckUserHasSubgraph";
import EventsForm from "./EventsForm";
import UploadSubgraph from "./UploadSubgraph";

const Steps = ({ step }: { step: number }) => {
  const { indexerFormValue } = useNewIndexerFormValues();
  if (indexerFormValue.generatorType.value === GeneratorType.UnmarshalParser) {
    switch (step) {
      case 0:
        return <ChainAndContractForm />;
      case 1:
        return <AbiForm />;
      case 2:
        return <EventsAndFunctionForm />;
      case 3:
        return <TokenPluginForm />;
      case 4:
        return <ChildParserForm />;
      case 5:
        return <ParserMetadataForm />;
      default:
        return <>Default</>;
    }
  } else if (indexerFormValue.generatorType.value === GeneratorType.Subgraph) {
    switch (step) {
      case 0:
        return <ChainAndContractForm />;
      case 1:
        return <HasSubgraphForm />;
      default:
        if (indexerFormValue.hasSubgraphCode) {
          switch (step) {
            case 2:
              return <UploadSubgraph />;
            case 3:
              return <ParserMetadataForm />;
            default:
              return <>Default</>;
          }
        } else {
          switch (step) {
            case 2:
              return <AbiForm />;
            case 3:
              return <EventsForm />;
            case 4:
              return <ParserMetadataForm />;
            default:
              return <>Default</>;
          }
        }
    }
  }
  return <></>;
};

export default function ParserForm() {
  const { activeStep, resetSteps } = useStepController();
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const { resetForm } = useNewIndexerFormValues();
  const status = searchParams.get("status") || "Running";
  const steps = useSteps();

  useEffect(() => resetSteps, []);

  return (
    <Container maxWidth={"md"} sx={{ mt: 10 }}>
      <Card>
        <CardContent>
          <IconButton
            sx={{ float: "right" }}
            onClick={() => {
              resetForm();
              Mixpanel.track(MixpanelEvents.CloseParserForm, {
                stage: steps[activeStep],
              });
              navigate(toIndexer(status));
            }}
          >
            <CloseIcon />
          </IconButton>
          <Spacing spacing={3} />
          <Stepper activeStep={activeStep} alternativeLabel>
            {steps.map((label, index) => {
              return (
                <Step key={label} completed={activeStep > index}>
                  <StepLabel>{label}</StepLabel>
                </Step>
              );
            })}
          </Stepper>
          <Spacing spacing={2} />
          <Card variant={"outlined"}>
            <CardContent>
              <Steps step={activeStep} />
            </CardContent>
          </Card>
          <Spacing spacing={2} />
        </CardContent>
      </Card>
    </Container>
  );
}

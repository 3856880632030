import React, { useState } from "react";
import { Controller, SubmitHandler, useForm } from "react-hook-form";
import { Autocomplete, Box, Divider, ListItem, Stack, TextField } from "@mui/material";
import { EventsAndFunctionsFormValidation } from "./Validations";
import StepsController from "./StepsController";
import { EventsAndFunctionsFormValues } from "./types";
import useNewIndexerFormValues from "./FormStateHandler";
import { default as MuiSkeleton } from "@mui/material/Skeleton/Skeleton";
import useABIDetailsClient, { CommonAbi } from "./ABIDetailsClient";
import Checkbox from "@mui/material/Checkbox";
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import CheckBoxIcon from "@mui/icons-material/CheckBox";
import Button from "@mui/material/Button";
import Paper from "@mui/material/Paper";
import Typography from "@mui/material/Typography";

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;

interface MuiMultiSelectProps {
  options: Array<CommonAbi>;
  onBlur: any;
  value: Array<CommonAbi>;
  onChange: Function;
  name: string;
  helperText: string;
  label: string;
  error: boolean;
}

export const MuiMultiSelect = ({
  options,
  onBlur,
  value,
  onChange,
  name,
  helperText,
  error,
  label,
}: MuiMultiSelectProps) => {
  const [isSelectedAll, setSelectedAll] = useState(false);

  const onSelectAll = () => {
    setSelectedAll((isSelectedAll) => {
      if (isSelectedAll) {
        onChange([]);
        return false;
      }
      onChange(options);
      return true;
    });
  };
  return (
    <Autocomplete
      multiple
      fullWidth
      options={options}
      getOptionLabel={(option) => option?.value}
      disableCloseOnSelect
      value={value}
      onBlur={onBlur}
      isOptionEqualToValue={(option, value) => option.value === value.value}
      onChange={(e, value) => {
        setSelectedAll(false);
        onChange(value);
      }}
      renderInput={(params) => (
        <TextField {...params} name={name} helperText={helperText} error={error} label={label} />
      )}
      PaperComponent={(param) => (
        <Paper {...param}>
          <ListItem
            component={Button}
            onMouseDown={(e: { preventDefault: () => void }) => e.preventDefault()}
            onClick={onSelectAll}
          >
            <Checkbox icon={icon} checkedIcon={checkedIcon} checked={isSelectedAll} />
            &nbsp; Select All
          </ListItem>
          <Divider />
          {param.children as React.ReactNode}
        </Paper>
      )}
      renderOption={(props, option, { selected }) => (
        <li {...props}>
          <Checkbox
            icon={icon}
            checkedIcon={checkedIcon}
            style={{ marginRight: 8 }}
            checked={selected}
          />
          {option.value}
          <Box paddingX={1}>➡</Box>
          {option?.signature}
        </li>
      )}
    />
  );
};

const EventsAndFunctionForm = () => {
  const {
    updateEventsAndFunctionsForm,
    indexerFormValue: { events, functions },
  } = useNewIndexerFormValues();
  const methods = useForm<EventsAndFunctionsFormValues>({
    defaultValues: { events, functions },
    resolver: EventsAndFunctionsFormValidation,
  });
  const { handleSubmit } = methods;

  const onSubmit: SubmitHandler<EventsAndFunctionsFormValues> = updateEventsAndFunctionsForm;
  const { abiDetails } = useABIDetailsClient();

  if (abiDetails.isLoading) {
    return (
      <Stack spacing={1}>
        <MuiSkeleton variant={"rectangular"} width={200} />
        <MuiSkeleton variant={"rectangular"} height={100} />
      </Stack>
    );
  }

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Stack spacing={3}>
        <Stack spacing={1}>
          <Typography fontSize={"18px"} fontWeight={600}>
            Events
          </Typography>
          <Controller
            control={methods.control}
            name={"events"}
            render={({ field: { onChange, name, value, onBlur }, fieldState }) => (
              <MuiMultiSelect
                label={"Please select events"}
                value={value}
                options={abiDetails?.data?.events || []}
                onBlur={onBlur}
                onChange={onChange}
                name={name}
                helperText={
                  fieldState?.error?.message || (fieldState?.error as any)?.value?.message
                }
                error={!!fieldState?.error}
              />
            )}
          />
        </Stack>
        <Stack spacing={1}>
          <Typography fontSize={"18px"} fontWeight={600}>
            Functions
          </Typography>
          <Controller
            control={methods.control}
            name={"functions"}
            render={({ field: { onChange, name, value, onBlur }, fieldState }) => (
              <MuiMultiSelect
                label={"Please select functions"}
                value={value}
                options={abiDetails?.data?.functions || []}
                onBlur={onBlur}
                onChange={onChange}
                name={name}
                helperText={
                  fieldState?.error?.message || (fieldState?.error as any)?.value?.message
                }
                error={!!fieldState?.error}
              />
            )}
          />
        </Stack>
        <StepsController />
      </Stack>
    </form>
  );
};

export default EventsAndFunctionForm;

import { RecoilState, useRecoilState } from "recoil";

const useDialogState = (atom: RecoilState<boolean>) => {
  const [isDialogOpen, updateDialogState] = useRecoilState(atom);
  const toggleDialog = () => updateDialogState((value) => !value);
  const closeDialog = () => updateDialogState(false);
  const openDialog = () => updateDialogState(true);

  return {
    isDialogOpen,
    toggleDialog,
    closeDialog,
    openDialog,
  };
};

export default useDialogState;

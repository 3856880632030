import { atom, useRecoilState, useResetRecoilState } from "recoil";
import DefaultAPIDataValues, { APIData } from "../../common/rest-client/types";
import { abiDetailsConfig } from "../../../config";
import usePostRequest from "../../common/rest-client/post";

export interface AbiDetailsResponse {
  functions: AbiDetailsResponseFunctions[];
  events: AbiDetailsResponseEvents[];
}

export interface Input {
  name: string;
  type: string;
}

export interface CommonAbi {
  name: string;
  signature: string;
  value: string;
  inputs: Input[];
  id: string;
  type: string;
}

export interface AbiDetailsResponseFunctions extends CommonAbi {
  stateMutability: string;
  constant: boolean;
  payable: boolean;
}

export interface AbiDetailsResponseEvents extends CommonAbi {
  anonymous: boolean;
}

const ABIDetailsClientAtom = atom<APIData<AbiDetailsResponse>>({
  key: "ABI_DETAILS_FETCHER",
  default: DefaultAPIDataValues,
});

const ChildParserABIDetailsClientAtom = atom<APIData<AbiDetailsResponse>>({
  key: "CHILD_PARSER_ABI_DETAILS_FETCHER",
  default: DefaultAPIDataValues,
});

const useABIDetailsClient = () => {
  const [abiDetails, updateABIDetails] = useRecoilState(ABIDetailsClientAtom);
  const reset = useResetRecoilState(ABIDetailsClientAtom);
  const post = usePostRequest();

  const getFunctionAndEventData = (abiString: string) => {
    post<AbiDetailsResponse>(updateABIDetails, abiDetailsConfig.abiDetails, {
      data: { abi: abiString },
    });
  };

  const [childParserABIDetails, updateChildParserABIDetails] = useRecoilState(
    ChildParserABIDetailsClientAtom
  );

  const getChildParserFunctionsAndEvents = (abiString: string) => {
    post(updateChildParserABIDetails, abiDetailsConfig.abiDetails, { data: { abi: abiString } });
  };
  return {
    getFunctionAndEventData,
    abiDetails,
    getChildParserFunctionsAndEvents,
    childParserABIDetails,
    reset,
  };
};

export default useABIDetailsClient;

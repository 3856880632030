import React from "react";
import { Navigate, Route, Routes } from "react-router-dom";
import { useAuth } from "@aws-amplify/ui-react/internal";
import Landing from "./landing/Landing";
import { default as Referrals } from "./referrals/Landing";
import { default as AuthContainer } from "./auth/AuthRouter";
import RequireAuth from "./auth/RequireAuth";
import Dashboard from "./dashboard/Dashboard";
import UpgradePlan from "./payments/UpgradePlan";
import CurrentPlan from "./payments/CurrentPlan";
import { SideNavItems } from "./dashboard/NavBarItems";
import Faq from "./faq/Faq";
import UsageStatistics from "./statistics/UsageStatistics";
import DailyStatistics from "./statistics/DailyStatistics";
import ParserRouter, { IndexersFetcher } from "./indexer/ParserRouter";
import Academy from "./academy/Academy";
import Homepage from "./home/Homepage";
import API from "./api/API";
import SDK from "./sdk/SDK";
import PaymentHistory from "./payments/PaymentHistory";
import Documentations from "./api/Documentations";
import Dashboards from "./sakhi/Dashboards";
import DashboardOverview from "./sakhi/DashboardOverview";
import Feedback from "./feedback/Feedback";
import useWhitelistUsers from "./sakhi/SakhiWhiteListClient";
import NewPricing from "./dashboard/NewPricing";

function AppRouter() {
  const auth = useAuth();
  const userName = auth.user?.username || "";
  const { allowedUsers } = useWhitelistUsers();
  return (
    <Routes>
      <Route
        path="/"
        element={
          auth.user ? (
            <RequireAuth>
              <Dashboard selectedTab={SideNavItems.API}>
                <Homepage />
              </Dashboard>
            </RequireAuth>
          ) : (
            <Landing />
          )
        }
      />
      <Route
        path="/plans/:planId/tenures/:tenureId/upgrade"
        element={
          <RequireAuth>
            <Dashboard selectedTab={SideNavItems.Account} subTab={SideNavItems.MyPlan}>
              <UpgradePlan />
            </Dashboard>
          </RequireAuth>
        }
      />
      <Route
        path="/top-api"
        element={
          <RequireAuth>
            <Dashboard selectedTab={SideNavItems.API} subTab={SideNavItems.TopAPI}>
              <API />
            </Dashboard>
          </RequireAuth>
        }
      />
      <Route
        path="/documentation"
        element={
          <RequireAuth>
            <Dashboard selectedTab={SideNavItems.API} subTab={SideNavItems.ApiDocumentation}>
              <Documentations />
            </Dashboard>
          </RequireAuth>
        }
      />
      <Route
        path="/sdk/:language"
        element={
          <RequireAuth>
            <Dashboard selectedTab={SideNavItems.API} subTab={SideNavItems.SDK}>
              <SDK />
            </Dashboard>
          </RequireAuth>
        }
      />
      <Route
        path="/plan"
        element={
          <RequireAuth>
            <Dashboard selectedTab={SideNavItems.Account} subTab={SideNavItems.MyPlan}>
              <CurrentPlan />
            </Dashboard>
          </RequireAuth>
        }
      />
      <Route
        path="/statistics"
        element={
          <RequireAuth>
            <Dashboard selectedTab={SideNavItems.Account} subTab={SideNavItems.UsageStatistics}>
              <UsageStatistics />
            </Dashboard>
          </RequireAuth>
        }
      />
      <Route
        path="/statistics/:task"
        element={
          <RequireAuth>
            <Dashboard selectedTab={SideNavItems.Account} subTab={SideNavItems.UsageStatistics}>
              <DailyStatistics />
            </Dashboard>
          </RequireAuth>
        }
      />
      <Route
        path="/faqs"
        element={
          <RequireAuth>
            <Dashboard selectedTab={SideNavItems.FAQs} topSpacing={0}>
              <Faq />
            </Dashboard>
          </RequireAuth>
        }
      />
      <Route
        path="/parser/*"
        element={
          <RequireAuth>
            <IndexersFetcher>
              <ParserRouter />
            </IndexersFetcher>
          </RequireAuth>
        }
      />
      <Route
        path="/referrals"
        element={
          <RequireAuth>
            <Dashboard selectedTab={SideNavItems.Referrals} topSpacing={0}>
              <Referrals />
            </Dashboard>
          </RequireAuth>
        }
      />
      <Route
        path="/academy"
        element={
          <RequireAuth>
            <Dashboard selectedTab={SideNavItems.Academy} topSpacing={0}>
              <Academy />
            </Dashboard>
          </RequireAuth>
        }
      />
      <Route
        path="/payment-history"
        element={
          <RequireAuth>
            <Dashboard selectedTab={SideNavItems.PaymentHistory}>
              <PaymentHistory />
            </Dashboard>
          </RequireAuth>
        }
      />
      <Route
        path="/parser/dashboards"
        element={
          (allowedUsers?.data?.value?.enable_whitelist &&
            allowedUsers?.data?.value?.users?.includes(userName)) ||
          !allowedUsers?.data?.value?.enable_whitelist ? (
            <RequireAuth>
              <Dashboard selectedTab={SideNavItems.Parser} subTab={SideNavItems.SakhiDashboards}>
                <Dashboards />
              </Dashboard>
            </RequireAuth>
          ) : (
            <Navigate to={"/parser"} />
          )
        }
      />
      <Route
        path="/parser/dashboards/:dashboardID/overview"
        element={
          (allowedUsers?.data?.value?.enable_whitelist &&
            allowedUsers?.data?.value?.users?.includes(userName)) ||
          !allowedUsers?.data?.value?.enable_whitelist ? (
            <RequireAuth>
              <Dashboard selectedTab={SideNavItems.Parser} subTab={SideNavItems.SakhiDashboards}>
                <DashboardOverview />
              </Dashboard>
            </RequireAuth>
          ) : (
            <Navigate to={"/parser"} />
          )
        }
      />
      <Route
        path="/feedback"
        element={
          <RequireAuth>
            <Dashboard selectedTab={SideNavItems.Feedback}>
              <Feedback />
            </Dashboard>
          </RequireAuth>
        }
      />
      <Route
        path="/new-pricing"
        element={
          <RequireAuth>
            <Dashboard selectedTab={SideNavItems.None}>
              <NewPricing />
            </Dashboard>
          </RequireAuth>
        }
      />
      <Route
        path="/auth/*"
        element={
          <Landing>
            <AuthContainer />
          </Landing>
        }
      />
      <Route path="/pricing" element={<Navigate to="/#pricing" />} />
      <Route path="/indexer/*" element={<Navigate to="/parser" />} />
    </Routes>
  );
}

export default AppRouter;

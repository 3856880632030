import React, { PropsWithChildren } from "react";
import { CssBaseline, ThemeProvider } from "@mui/material";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { RecoilRoot } from "recoil";
import { BrowserRouter } from "react-router-dom";
import { Web3ReactProvider } from "@web3-react/core";
import { Web3Provider } from "@ethersproject/providers";
import AuthProvider from "../auth/AuthProvider";
import ErrorBoundary from "../common/ErrorBoundary";
import Theme from "../theme/Theme";
import { POLLING_INTERVAL } from "../Wallet/Connectors";
import { RefreshToken } from "../auth/RequireAuth";

function getLibrary(provider: any): Web3Provider {
  const library = new Web3Provider(provider);
  library.pollingInterval = POLLING_INTERVAL;
  return library;
}

function Providers({ children }: PropsWithChildren<any>) {
  return (
    <ThemeProvider theme={Theme}>
      <AuthProvider />
      <CssBaseline />
      <ErrorBoundary>
        <Web3ReactProvider getLibrary={getLibrary}>
          <BrowserRouter>
            <RecoilRoot>
              <RefreshToken />
              <ToastContainer />
              {children}
            </RecoilRoot>
          </BrowserRouter>
        </Web3ReactProvider>
      </ErrorBoundary>
    </ThemeProvider>
  );
}

export default Providers;

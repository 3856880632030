import React from "react";
import { Controller, SubmitHandler, useForm } from "react-hook-form";
import { Autocomplete, Stack, TextField } from "@mui/material";
import StepsController from "./StepsController";
import useNewIndexerFormValues from "./FormStateHandler";

interface HasSubgraph {
  hasSubGraph: "Yes" | "No";
}
const HasSubgraphForm = () => {
  const { updateHasSubgraph } = useNewIndexerFormValues();
  const methods = useForm<HasSubgraph>({
    defaultValues: {
      hasSubGraph: "No",
    },
  });
  const { handleSubmit } = methods;
  const onSubmit: SubmitHandler<HasSubgraph> = (value) =>
    updateHasSubgraph(value.hasSubGraph === "Yes");

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Stack spacing={3}>
        <Controller
          control={methods.control}
          render={({ field: { onChange, name, value, onBlur }, fieldState }) => (
            <Autocomplete
              onBlur={onBlur}
              value={value}
              options={["Yes", "No"]}
              onChange={(e, value) => onChange(value)}
              renderInput={(params) => (
                <TextField
                  {...params}
                  name={name}
                  label="Do you have subgraph code?"
                  helperText={
                    fieldState?.error?.message || (fieldState?.error as any)?.value?.message
                  }
                  error={!!fieldState?.error}
                />
              )}
            />
          )}
          name={"hasSubGraph"}
        />
        <StepsController />
      </Stack>
    </form>
  );
};

export default HasSubgraphForm;

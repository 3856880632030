import { atom, useRecoilState, useResetRecoilState } from "recoil";
import Box from "@mui/material/Box";
import React from "react";
import useNewIndexerFormValues from "./FormStateHandler";
import LoadingButton from "@mui/lab/LoadingButton";
import Button from "@mui/material/Button";
import { GeneratorType } from "./types";

export const unmarshalSteps = [
  "Select chain and contract Address",
  "Enter Contract ABI",
  "Select events and functions",
  "Add Plugins",
  "Add child parser",
  "Name your parser",
];

export const theGraphGeneratorSteps = [
  "Select chain and contract Address",
  "Subgraph code",
  "Enter Contract ABI",
  "Select events",
  "Name your parser",
];

export const theGraphUploadCodeSteps = [
  "Select chain and contract Address",
  "Subgraph code",
  "Upload Subgraph",
  "Name your parser",
];

export const useSteps = (): string[] => {
  const {
    indexerFormValue: { hasSubgraphCode, generatorType },
  } = useNewIndexerFormValues();
  if (generatorType.value === GeneratorType.Subgraph) {
    if (hasSubgraphCode) {
      return theGraphUploadCodeSteps;
    }
    return theGraphGeneratorSteps;
  } else {
    return unmarshalSteps;
  }
};

const stepAtom = atom<number>({
  key: "NEW_INDEXER_STEP_STATE",
  default: 0,
});

export const useStepController = () => {
  const [activeStep, setActiveStep] = useRecoilState(stepAtom);
  const resetSteps = useResetRecoilState(stepAtom);

  const handleNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };
  return { handleBack, handleNext, activeStep, resetSteps };
};

const StepsController = () => {
  const { activeStep, handleBack } = useStepController();
  const {
    indexerFormValue: { isSubmitting },
  } = useNewIndexerFormValues();
  const steps = useSteps();

  return (
    <React.Fragment>
      <Box sx={{ display: "flex", flexDirection: "row" }}>
        <Button
          color="inherit"
          variant={"outlined"}
          disabled={activeStep === 0}
          onClick={handleBack}
          sx={{ mr: 1 }}
        >
          Back
        </Button>
        <Box sx={{ flex: "1 1 auto" }} />
        <LoadingButton type={"submit"} variant={"outlined"} loading={isSubmitting}>
          {activeStep === steps.length - 1 ? "Create Parser" : "Continue"}
        </LoadingButton>
      </Box>
    </React.Fragment>
  );
};

export default StepsController;

import React, { useEffect, useState } from "react";
import Typography from "@mui/material/Typography";
import TextField from "@mui/material/TextField";
import Spacing from "../../common/Spacing";
import { Alert, FormControlLabel, Radio, RadioGroup, Stack, Tooltip } from "@mui/material";
import { Controller } from "react-hook-form";
import { chainDetailsMap } from "../../../config";
import { useAsyncRestClient } from "./AbiForm";
import useNewIndexerFormValues, { useContractDetails } from "./FormStateHandler";
import { toNumber } from "lodash";
import { toast } from "react-toastify";
import { default as MuiSkeleton } from "@mui/material/Skeleton/Skeleton";
import { atom, useRecoilState } from "recoil";
import { useIsChildParser } from "./add-child/ChildParserFormStateHandler";

export interface StartBlockProps {
  control: any;
  contractStartBlock: number;
  setValue: Function;
}

export const useOldBlockNumbers = () => {
  const [isFetchingDetails, setFetchingState] = useState(false);
  const { get } = useAsyncRestClient();
  const getOldBlockNumberFromExplorer = (chain: string, timestamp: number) => {
    const chainDetails = chainDetailsMap.get(chain);
    const url = `${chainDetails?.explorer}/api`;
    return get(url, {
      params: {
        module: "block",
        action: "getblocknobytime",
        timestamp: timestamp,
        closest: "before",
        apikey: chainDetails?.api_key,
      },
    }).then(({ result, message }: any) => {
      if (message !== "OK") {
        throw new Error("Status not ok");
      }
      if (result.blockNumber) {
        return result.blockNumber;
      }
      return result;
    });
  };

  const getOldBlockNumber = (
    chain: string,
    timestamp: number,
    onFetchOldBlockNumber: (startBlock: number) => void
  ) => {
    setFetchingState(true);
    Promise.all([getOldBlockNumberFromExplorer(chain, timestamp)])
      .then(([oldBlockNumber]: any) => {
        onFetchOldBlockNumber(toNumber(oldBlockNumber));
      })
      .catch(() => toast.warn("Failed to fetch old block numbers"))
      .finally(() => setFetchingState(false));
  };

  return { getOldBlockNumber, isFetchingDetails };
};

export enum startBlockTypes {
  contractStartBlock = "contractStartBlock",
  customStartBlock = "customStartBlock",
  last1Month = "last1Month",
  last3Months = "last3Months",
}

export const startBlockRadioConfig: Map<string, any> = new Map<string, any>([
  [
    startBlockTypes.contractStartBlock,
    {
      label: "Contract Start Block",
      toolTip: "Start indexing from the block when the contract was created",
    },
  ],
  [
    startBlockTypes.customStartBlock,
    {
      label: "Custom",
      toolTip: "Start indexing from block of your choice",
    },
  ],
  [
    startBlockTypes.last1Month,
    {
      label: "Last 1 Month",
      toolTip: "Start indexing from last 1 month",
      months: 1,
    },
  ],
  [
    startBlockTypes.last3Months,
    {
      label: "Last 3 Months",
      toolTip: "Start indexing from last 3 months",
      months: 3,
    },
  ],
]);

const parentStartBlockTypeAtom = atom<string>({
  key: "PARENT_START_BLOCK_TYPE",
  default: startBlockTypes.contractStartBlock,
});

const useParentStartBlockType = () => {
  const [startBlockOption, setStartBlockOption] = useRecoilState(parentStartBlockTypeAtom);
  return { startBlockOption, setStartBlockOption };
};

const StartBlock = ({ setValue, contractStartBlock, control }: StartBlockProps) => {
  const { isFailedToFetchStartBlock } = useContractDetails();
  const [isCustomStartBlock, setCustomStartBlock] = useState(isFailedToFetchStartBlock);
  const { startBlockOption, setStartBlockOption } = useParentStartBlockType();
  const { isChildParser } = useIsChildParser();

  const date = new Date();

  const { getOldBlockNumber, isFetchingDetails } = useOldBlockNumbers();

  const {
    indexerFormValue: { chain },
  } = useNewIndexerFormValues();

  useEffect(() => {
    switch (startBlockOption) {
      case startBlockTypes.contractStartBlock:
        setValue("startBlock", contractStartBlock);
        break;
      case startBlockTypes.customStartBlock:
        break;
      default:
        const oldDate = date;
        if (startBlockRadioConfig.get(startBlockOption).months) {
          oldDate.setMonth(oldDate.getMonth() - startBlockRadioConfig.get(startBlockOption).months);
          getOldBlockNumber(
            chain?.value as string,
            Math.floor(oldDate.getTime() / 1000),
            (startBlock) => setValue("startBlock", startBlock)
          );
        }
        break;
    }
  }, [startBlockOption]);

  return (
    <Stack>
      <Typography fontSize={"18px"} fontWeight={600}>
        Start Block
      </Typography>
      {isFailedToFetchStartBlock && (
        <Alert severity="warning">
          Couldn't fetch start block for this contract. Please enter manually
        </Alert>
      )}
      <Spacing spacing={1} />
      <RadioGroup
        row
        value={startBlockOption}
        onChange={(e) => {
          setCustomStartBlock(e?.target?.value === startBlockTypes.customStartBlock);
          setStartBlockOption(e?.target?.value);
        }}
      >
        {Array.from(startBlockRadioConfig.keys()).map((startBlockType) => {
          const startBlockConfig = startBlockRadioConfig.get(startBlockType);
          return (
            <Tooltip title={startBlockConfig.toolTip} key={startBlockType}>
              <FormControlLabel
                value={startBlockType}
                control={
                  <Radio
                    required
                    disabled={
                      (startBlockType !== startBlockTypes.contractStartBlock && isChildParser) ||
                      (isFailedToFetchStartBlock &&
                        startBlockType !== startBlockTypes.customStartBlock)
                    }
                  />
                }
                label={startBlockConfig.label}
              />
            </Tooltip>
          );
        })}
      </RadioGroup>
      {isFetchingDetails ? (
        <MuiSkeleton variant={"rectangular"} height={40} />
      ) : (
        <Controller
          control={control}
          name={"startBlock"}
          render={({ field: { onChange, name, value, onBlur }, fieldState }) => (
            <TextField
              value={value}
              type={"number"}
              placeholder={"Please enter start block"}
              name={name}
              disabled={!isCustomStartBlock}
              fullWidth
              onBlur={onBlur}
              onChange={onChange}
              helperText={fieldState?.error?.message}
              error={!!fieldState?.error}
            />
          )}
        />
      )}
    </Stack>
  );
};

export default StartBlock;

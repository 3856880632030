import { CommonAbi, Input } from "./ABIDetailsClient";
import { IndexerType } from "../IndexerClient";

export interface TokenPluginDetails {
  type: string;
  name: string;
  field_name: string;
  field_type: string;
  inputs: {
    contract_address: string;
  };
}

export interface NativeTokenPlugin {
  type: string;
  name: string;
  field_name: string;
}

export interface TokenNamePluginInput {
  field_name: string;
}

export interface NativeTokenPlugin {
  type: string;
  name: string;
  field_name: string;
}

interface ChildParserTokenNamePluginInput {
  parent_component_type: string;
  parent_component_name: string;
  parent_filter_column_name: string;
  parent_token_column_name: string;
}

export interface TokenFieldPlugin {
  type: string;
  name: string;
  field_name: string;
  field_type: string;
  inputs: TokenNamePluginInput;
}

export interface ChildParserTokenFieldPlugin {
  type: string;
  name: string;
  field_name: string;
  field_type: string;
  inputs: ChildParserTokenNamePluginInput;
}

export interface Plugin {
  plugin_type: string;
  data: Array<
    TokenPluginDetails | TokenFieldPlugin | ChildParserTokenFieldPlugin | NativeTokenPlugin
  >;
}

export interface RunConfig {
  chain: string;
  start_block: number;
  contract_address: string;
  db_name: string;
}

export interface ContractAndChainFormValues {
  chain: {
    label: string;
    value: string;
  } | null;
  contractAddress: string;
  generatorType: GeneratorTypeOption;
}

interface GeneratorTypeOption {
  label: string;
  value: GeneratorType;
}

export enum GeneratorType {
  UnmarshalParser = IndexerType.DeployedAndMaintained,
  Subgraph = IndexerType.Subgraph,
}

export const GeneratorTypeOptions: Array<GeneratorTypeOption> = [
  {
    label: "Unmarshal Parser",
    value: GeneratorType.UnmarshalParser,
  },
  {
    label: "Subgraph: The Graph",
    value: GeneratorType.Subgraph,
  },
];

export interface AbiFormValues {
  abi: string;
  isProxy: boolean;
  proxyImplementationContract: string;
}

export interface ChildAbiFormValues {
  contractAddress: string;
  abi: string;
}

export interface EventsAndFunctionsFormValues {
  events: Array<CommonAbi>;
  functions: Array<CommonAbi>;
}

export interface EventsFormValues {
  events: Array<CommonAbi>;
}

export interface ParserMetadataFormValues {
  startBlock: number | null;
  name: string;
  schemaName: string;
}

interface TokenPluginInputs {
  input: Input | null;
  isTokenAddressIsInInputField: boolean;
  addressOrInputField: Input | string | null;
}

export type TokenPlugin = {
  entity: CommonAbi | null;
  selectedInputs: Array<TokenPluginInputs>;
  isTrackNativeTokenDeposit: boolean;
};

export interface TokenPluginFormValues {
  plugins: Array<TokenPlugin>;
}

export interface NewIndexerFormValues
  extends ContractAndChainFormValues,
    AbiFormValues,
    EventsAndFunctionsFormValues,
    TokenPluginFormValues,
    ParserMetadataFormValues {
  contractStartBlock: number | null;
  hasSubgraphCode: boolean;
  isSubmitting: boolean;
}

export interface ParentContractInfoFormValues {
  eventName: CommonAbi | null;
  selectedInput: TokenPluginInputs | null;
}

export interface NewChildParserFormValues
  extends ChildAbiFormValues,
    EventsAndFunctionsFormValues,
    TokenPluginFormValues,
    ParserMetadataFormValues,
    ParentContractInfoFormValues {
  contractStartBlock: number | null;
}

export interface NewIndexerRequest {
  abi: string;
  events: string[];
  methods: string[];
  plugins: Plugin[];
  display_name: string;
  type: GeneratorType;
  run_config: RunConfig;
}

interface NewChildParserRequest extends NewIndexerRequest {
  contract_type: string;
  parent_contract_info: {
    schema_name: string;
    event_name: string;
    field_name: string;
    field_type: string;
    contract_address: string;
  };
}

export interface NewParentChildParserRequest {
  parent: NewIndexerRequest;
  child: NewChildParserRequest;
}

import React, { useEffect, useMemo, useState } from "react";
import { Stack } from "@mui/material";
import { useTableNames } from "../indexer/dataExplorer/DataExplorerClient";
import useIndexerDetailsByID from "../indexer/IndexerDetailsClient";
import { useParams } from "react-router-dom";
import SakhiResponse from "./SakhiResponse";
import SearchBar from "./SearchBar";
import AddToDashboard from "./AddToDashboard";

const priorityTables = [
  "burn_events",
  "mint_events",
  "swap_events",
  "sync_events",
  "aggregated_daily_pair_volumes",
  "aggregated_daily_token_volumes",
  "aggregated_daily_dex_volumes",
  "aggregated_daily_pair_liquidities",
  "aggregated_daily_token_liquidities",
  "aggregated_daily_dex_liquidities",
];

function Sakhi() {
  const { tableNames, getTableNames } = useTableNames();
  const { id } = useParams();
  const indexerID = id || "";
  const { indexerDetails, getIndexerById } = useIndexerDetailsByID();
  const [question, setQuestion] = useState("");

  const schemaName = indexerDetails?.data?.additional_details?.db_name;

  const tables = useMemo(() => {
    const priorityTablesInParser = priorityTables
      .filter((name) => (tableNames.data || []).includes(name))
      .map((name) => `${schemaName}.${name}`);
    const additionalTables = (tableNames.data || [])
      .filter((name) => !priorityTables.includes(name) && name !== "last_synced_blocks")
      .map((name) => `${schemaName}.${name}`);
    return [...priorityTablesInParser, ...additionalTables].slice(0, 10);
  }, [tableNames, schemaName]);

  useEffect(() => {
    getTableNames();
    getIndexerById(indexerID);
  }, [indexerID]);

  return (
    <Stack spacing={3}>
      <SearchBar tables={tables} setQuestion={setQuestion} question={question} />
      <SakhiResponse />
      <AddToDashboard question={question} />
    </Stack>
  );
}

export default Sakhi;

import React, { useEffect } from "react";
import { useParams } from "react-router-dom";
import { useTableDataTypes } from "./DataExplorerClient";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import { Typography } from "@mui/material";
import APIDataLoader from "../../common/apiDataLoader/APIDataLoader";
import Skeleton from "@mui/material/Skeleton";

const headerStyle = {
  fontWeight: 600,
  fontSize: "20px",
  color: "#0DB678",
};

const LoadingIndicator = () => (
  <>
    {Array.from(Array(10).keys()).map((key) => (
      <TableRow key={key}>
        <TableCell component="h6" scope="row">
          <Skeleton animation="wave" height={30} />
        </TableCell>
        <TableCell component="h6" scope="row">
          <Skeleton animation="wave" height={30} />
        </TableCell>
      </TableRow>
    ))}
  </>
);

const TableDataTypes = () => {
  const { id, tableName } = useParams();
  const indexerID = id || "";
  const { getDataTypes, dataTypes } = useTableDataTypes(indexerID, tableName || "");
  useEffect(getDataTypes, [indexerID, tableName]);
  return (
    <TableContainer>
      <Table sx={{ minWidth: 650 }}>
        <TableHead
          sx={{
            position: "sticky",
            top: 0,
            zIndex: 1,
            background: "rgb(45,54,58)",
          }}
        >
          <TableRow>
            <TableCell>
              <Typography sx={headerStyle}>Column Name</Typography>
            </TableCell>
            <TableCell>
              <Typography sx={headerStyle}>Data Type</Typography>
            </TableCell>
          </TableRow>
        </TableHead>
        <APIDataLoader {...dataTypes} loadingIndicator={<LoadingIndicator />}>
          <TableBody>
            {(dataTypes.data || []).map((row) => (
              <TableRow key={row.column_name}>
                <TableCell component="h6" scope="row">
                  {row.column_name}
                </TableCell>
                <TableCell component="h6" scope="row">
                  {row.data_type}
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </APIDataLoader>
      </Table>
    </TableContainer>
  );
};

export default TableDataTypes;

import React, { useMemo } from "react";
import Dashboard, { DashboardProps } from "../dashboard/Dashboard";
import { Container } from "@mui/material";
import SelectParser from "./SelectParser";
import RefreshParserDetails from "./RefreshParserDetails";
import Spacing from "../common/Spacing";
import useIndexerDetails from "./IndexerClient";
import ParserTeaser from "../common/ParserTeaser";
import { isEmpty } from "lodash";
import { LoadingIndicator } from "../common/apiDataLoader/APIDataLoader";
import { getNavbarItemLink } from "../dashboard/NavBarItems";
import { useParams } from "react-router-dom";

interface ContentProps {
  link: (data: any) => string;
}

const Content = (props: React.PropsWithChildren<ContentProps>) => {
  const { indexersByUserID } = useIndexerDetails();
  const { id } = useParams();
  const indexerID = id || "";

  if (indexersByUserID.error) {
    return <ParserTeaser />;
  }

  if (
    indexersByUserID.isSuccess &&
    indexerID !== "default" &&
    !isEmpty(indexersByUserID.data?.indexer_list)
  )
    return (
      <Container maxWidth={"xl"}>
        <SelectParser navigateTo={props.link} />
        <Spacing spacing={2} />
        {props.children}
      </Container>
    );

  return <LoadingIndicator />;
};

const ParserDashboard = (props: React.PropsWithChildren<DashboardProps>) => {
  const link = useMemo(() => {
    return getNavbarItemLink(props.selectedTab, props?.subTab);
  }, [props]);

  return (
    <Dashboard {...props}>
      <RefreshParserDetails navigateTo={link}>
        <Content link={link}>{props.children}</Content>
      </RefreshParserDetails>
    </Dashboard>
  );
};

export default ParserDashboard;

import React from "react";
import { Controller, SubmitHandler, useForm } from "react-hook-form";
import { Stack } from "@mui/material";
import { EventsFormValidation } from "./Validations";
import StepsController from "./StepsController";
import { EventsAndFunctionsFormValues, EventsFormValues } from "./types";
import useNewIndexerFormValues from "./FormStateHandler";
import { default as MuiSkeleton } from "@mui/material/Skeleton/Skeleton";
import useABIDetailsClient from "./ABIDetailsClient";
import Typography from "@mui/material/Typography";
import { MuiMultiSelect } from "./EventsAndFunctionForm";

const EventsForm = () => {
  const {
    updateEventsForm,
    indexerFormValue: { events },
  } = useNewIndexerFormValues();
  const methods = useForm<EventsAndFunctionsFormValues>({
    defaultValues: { events },
    resolver: EventsFormValidation,
  });
  const { handleSubmit } = methods;

  const onSubmit: SubmitHandler<EventsFormValues> = (values) => updateEventsForm(values.events);

  const { abiDetails } = useABIDetailsClient();

  if (abiDetails.isLoading) {
    return (
      <Stack spacing={1}>
        <MuiSkeleton variant={"rectangular"} width={200} />
      </Stack>
    );
  }

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Stack spacing={3}>
        <Stack spacing={1}>
          <Typography fontSize={"18px"} fontWeight={600}>
            Events
          </Typography>
          <Controller
            control={methods.control}
            name={"events"}
            render={({ field: { onChange, name, value, onBlur }, fieldState }) => (
              <MuiMultiSelect
                label={"Please select events"}
                value={value}
                options={abiDetails?.data?.events || []}
                onBlur={onBlur}
                onChange={onChange}
                name={name}
                helperText={
                  fieldState?.error?.message || (fieldState?.error as any)?.value?.message
                }
                error={!!fieldState?.error}
              />
            )}
          />
        </Stack>
        <StepsController />
      </Stack>
    </form>
  );
};

export default EventsForm;

import React, { useEffect, useState } from "react";
import useNewIndexerFormValues from "../FormStateHandler";
import { FormControlLabel, Radio, RadioGroup, Stack, Tooltip } from "@mui/material";
import Typography from "@mui/material/Typography";
import Spacing from "../../../common/Spacing";
import { default as MuiSkeleton } from "@mui/material/Skeleton/Skeleton";
import { Controller } from "react-hook-form";
import TextField from "@mui/material/TextField";
import {
  StartBlockProps,
  startBlockRadioConfig,
  startBlockTypes,
  useOldBlockNumbers,
} from "../StartBlock";
import { atom, useRecoilState } from "recoil";

const childStartBlockTypeAtom = atom<string>({
  key: "CHILD_START_BLOCK_TYPE",
  default: startBlockTypes.contractStartBlock,
});

const useChildStartBlockType = () => {
  const [startBlockOption, setStartBlockOption] = useRecoilState(childStartBlockTypeAtom);
  return { startBlockOption, setStartBlockOption };
};

const ChildParserStartBlock = ({ setValue, contractStartBlock, control }: StartBlockProps) => {
  const { startBlockOption, setStartBlockOption } = useChildStartBlockType();
  const [isCustomStartBlock, setCustomStartBlock] = useState(
    startBlockOption === startBlockTypes.customStartBlock
  );
  const date = new Date();

  const { getOldBlockNumber, isFetchingDetails } = useOldBlockNumbers();

  const {
    indexerFormValue: { chain },
  } = useNewIndexerFormValues();

  useEffect(() => {
    switch (startBlockOption) {
      case startBlockTypes.contractStartBlock:
        setValue("startBlock", contractStartBlock);
        break;
      case startBlockTypes.customStartBlock:
        break;
      default:
        const oldDate = date;
        if (startBlockRadioConfig.get(startBlockOption).months) {
          oldDate.setMonth(oldDate.getMonth() - startBlockRadioConfig.get(startBlockOption).months);
          getOldBlockNumber(
            chain?.value as string,
            Math.floor(oldDate.getTime() / 1000),
            (startBlock) => setValue("startBlock", startBlock)
          );
        }
        break;
    }
  }, [startBlockOption]);

  return (
    <Stack>
      <Typography fontSize={"18px"} fontWeight={600}>
        Start Block
      </Typography>
      <Spacing spacing={1} />
      <RadioGroup
        row
        value={startBlockOption}
        onChange={(e) => {
          setCustomStartBlock(e?.target?.value === startBlockTypes.customStartBlock);
          setStartBlockOption(e?.target?.value);
        }}
      >
        {Array.from(startBlockRadioConfig.keys()).map((startBlockType) => {
          const startBlockConfig = startBlockRadioConfig.get(startBlockType);
          return (
            <Tooltip title={startBlockConfig.toolTip} key={startBlockType}>
              <FormControlLabel
                value={startBlockType}
                control={<Radio required />}
                label={startBlockConfig.label}
              />
            </Tooltip>
          );
        })}
      </RadioGroup>
      {isFetchingDetails ? (
        <MuiSkeleton variant={"rectangular"} height={40} />
      ) : (
        <Controller
          control={control}
          name={"startBlock"}
          render={({ field: { onChange, name, value, onBlur }, fieldState }) => (
            <TextField
              value={value}
              type={"number"}
              placeholder={"Please enter start block"}
              name={name}
              disabled={!isCustomStartBlock}
              fullWidth
              onBlur={onBlur}
              onChange={onChange}
              helperText={fieldState?.error?.message}
              error={!!fieldState?.error}
            />
          )}
        />
      )}
    </Stack>
  );
};

export default ChildParserStartBlock;

import React, { useMemo } from "react";
import APIDataLoader from "../common/apiDataLoader/APIDataLoader";
import { DataGrid, GridColDef } from "@mui/x-data-grid";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Paper from "@mui/material/Paper";
import Popper from "@mui/material/Popper";
import useSakhi, { JsonData } from "./SakhiClient";
import { Stack } from "@mui/material";
import thinkingSVG from "../../assets/undraw/thinking.svg";
import Button from "@mui/material/Button";
import { useAddToDashboardDialog } from "./AddToDashboard";
import { ceil, first, get, isEmpty, max, some } from "lodash";
import HighchartsReact from "highcharts-react-official";
import Highcharts from "highcharts";
import Card from "@mui/material/Card";
import LoadingIndicator, { ErrorIndicator } from "../indexer/analytics/LoadingIndicator";
import { fromDateToFormat, isValidDate } from "../common/date";
import ErrorBoundary from "../common/ErrorBoundary";
import Spacing from "../common/Spacing";
import { wrapMiddle } from "../common/string/format";
import MuiLink from "@mui/material/Link";
import Copy, { copyTextToClipboard } from "../common/clipboard/copy";
import { styled } from "@mui/material/styles";
import { Alert } from "@mui/lab";
import { toast } from "react-toastify";
import CopyAll from "@mui/icons-material/CopyAll";
import Mixpanel, { MixpanelEvents } from "../../MixpanelConfig";

const StyledDataGrid = styled(DataGrid)(() => ({
  "& .MuiDataGrid-columnHeaders": {
    background: "rgb(45,54,58)",
  },
  "& .MuiDataGrid-columnHeader": {
    fontWeight: 600,
    fontSize: "20px",
    color: "#0DB678",
  },
}));

interface GridCellExpandProps {
  value: string;
  width: number;
}

function isOverflown(element: Element): boolean {
  return element.scrollHeight > element.clientHeight || element.scrollWidth > element.clientWidth;
}

const GridCellExpand = React.memo(function GridCellExpand(props: GridCellExpandProps) {
  const { width, value } = props;
  const wrapper = React.useRef<HTMLDivElement | null>(null);
  const cellDiv = React.useRef(null);
  const cellValue = React.useRef(null);
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const [showFullCell, setShowFullCell] = React.useState(false);
  const [showPopper, setShowPopper] = React.useState(false);

  const handleMouseEnter = () => {
    const isCurrentlyOverflown = isOverflown(cellValue.current!);
    setShowPopper(isCurrentlyOverflown);
    setAnchorEl(cellDiv.current);
    setShowFullCell(true);
  };

  const handleMouseLeave = () => {
    setShowFullCell(false);
  };

  React.useEffect(() => {
    if (!showFullCell) {
      return undefined;
    }

    function handleKeyDown(nativeEvent: KeyboardEvent) {
      // IE11, Edge (prior to using Bink?) use 'Esc'
      if (nativeEvent.key === "Escape" || nativeEvent.key === "Esc") {
        setShowFullCell(false);
      }
    }

    document.addEventListener("keydown", handleKeyDown);

    return () => {
      document.removeEventListener("keydown", handleKeyDown);
    };
  }, [setShowFullCell, showFullCell]);

  return (
    <Box
      ref={wrapper}
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
      sx={{
        alignItems: "center",
        lineHeight: "24px",
        width: "100%",
        height: "100%",
        position: "relative",
        display: "flex",
      }}
    >
      <Box
        ref={cellDiv}
        sx={{
          height: "100%",
          width,
          display: "block",
          position: "absolute",
          top: 0,
        }}
      />
      <Box
        ref={cellValue}
        sx={{ whiteSpace: "nowrap", overflow: "hidden", textOverflow: "ellipsis" }}
      >
        {value}
      </Box>
      {showPopper && (
        <Popper
          open={showFullCell && anchorEl !== null}
          anchorEl={anchorEl}
          style={{ marginLeft: -17 }}
        >
          <Paper elevation={1} style={{ minHeight: wrapper.current!.offsetHeight - 3 }}>
            <Typography variant="body2" style={{ padding: 8 }}>
              {value}
            </Typography>
          </Paper>
        </Popper>
      )}
    </Box>
  );
});

interface LineGraphProps {
  isLoading: boolean;
  hasError: boolean;
  data: JsonData;
  xAxisLabelFormatter: (value: any, format: any) => any;
}

const labelColor = "rgba(255,255,255,0.8)";

const LineGraph = ({ data, isLoading, hasError, xAxisLabelFormatter }: LineGraphProps) => {
  return useMemo(() => {
    if (isLoading) return <LoadingIndicator />;
    if (hasError || isEmpty(data)) return <ErrorIndicator />;

    const xAxisData = data.data || [];
    const xAxis = data.meta.fields.slice(1);
    const series = (xAxis || []).map((value: string) => {
      return {
        color: "#0DB678",
        showInLegend: true,
        name: value,
        data: xAxisData.map((rowValue: any) => {
          return Number(rowValue[value]);
        }),
      };
    });
    const options = {
      chart: {
        type: "spline",
        backgroundColor: "rgba(27, 32, 35, 0.9)",
        height: 300,
      },
      title: {
        text: "",
      },
      yAxis: {
        gridLineColor: "rgba(255, 255, 255, 0.03)",
        labels: {
          style: {
            color: labelColor,
            fontSize: 14,
          },
          x: 0,
        },
        title: null,
      },
      xAxis: {
        lineColor: "rgba(255, 255, 255, 0.06)",
        categories: xAxisData.map((value: any) => {
          const x = value[data.meta.fields[0]];
          return xAxisLabelFormatter(x, "dd-MM-yyyy");
        }),
        accessibility: {
          description: "",
        },
        labels: {
          step: max([ceil(xAxisData.length / 10), 1]),
          style: {
            color: labelColor,
            fontSize: 14,
          },
          x: 30,
        },
      },
      series: series,
    };

    console.log("options: ", options);
    return <HighchartsReact highcharts={Highcharts} options={options} />;
  }, [data, isLoading, hasError]);
};

interface SakhiResponseProps {}

const SakhiResponse = ({}: SakhiResponseProps) => {
  const { sakhiResponse } = useSakhi();
  const columns: GridColDef[] = useMemo(() => {
    const fields = sakhiResponse.data?.json_data?.meta?.fields || [];
    const flex = fields.length > 5 ? undefined : 1;
    const minWidth = fields.length > 5 ? 200 : 200;
    return fields.map((column: string) => ({
      field: column,
      headerName: column,
      flex,
      minWidth,
      renderCell: (params: any) => {
        if (column === "tx_hash") {
          return (
            <Copy content={params.value} contentType={"transaction hash"} source={"sakhi response"}>
              <MuiLink
                href={`https://xscan.io/transactions/${params.value}`}
                target={"_blank"}
                style={{ textDecoration: "underline", color: "#ffffff" }}
              >
                {wrapMiddle(params.value)}
              </MuiLink>
            </Copy>
          );
        }
        const regexp = new RegExp(/^0x[a-zA-Z0-9]{40,64}$/);
        if (params.value.match(regexp)) {
          return (
            <Copy content={params.value} contentType={"address"} source={"sakhi response"}>
              {wrapMiddle(params.value)}
            </Copy>
          );
        }
        return <GridCellExpand value={params.value || ""} width={params.colDef.computedWidth} />;
      },
    }));
  }, [sakhiResponse.data?.json_data?.meta?.fields]);
  const { openDialog } = useAddToDashboardDialog();

  const onCopy = () => {
    Mixpanel.track(MixpanelEvents.CopyContent, { contentType: "query", source: "sakhi" });
    toast.success("Copied generated query");
  };

  if (sakhiResponse.isLoading) {
    return (
      <Stack justifyContent={"center"} alignItems={"center"}>
        <div className="loading-wave-container">
          <img src={thinkingSVG} alt="Thinking" height={100} width={100} />
        </div>
      </Stack>
    );
  }

  if (sakhiResponse.hasError) {
    return (
      <Card variant={"outlined"} sx={{ maxWidth: "80vw", overflow: "scroll" }}>
        <Typography color={"secondary"} align={"center"}>
          OOPS, This question took too long to respond. Please try again after some time
        </Typography>
      </Card>
    );
  }

  function canRenderLineGraph() {
    if (!some(sakhiResponse.data?.charts_jsons, ["type", "line"])) return false;
    const xAxis: string = first(sakhiResponse.data.json_data?.meta?.fields) || "";
    const dateField = get(first(sakhiResponse.data.json_data?.data), xAxis);
    return isValidDate(dateField);
  }

  if (sakhiResponse.isSuccess) {
    return (
      <APIDataLoader {...sakhiResponse}>
        {canRenderLineGraph() && (
          <>
            <ErrorBoundary ErrorMessage={"Failed to render line graph"}>
              <Stack direction={"row"} justifyContent={"space-between"} sx={{ width: "80vw" }}>
                <Typography variant={"h4"} fontWeight={"bold"}>
                  Results
                </Typography>
                <Button variant={"outlined"} onClick={() => openDialog("line")}>
                  Add To Dashboard
                </Button>
              </Stack>
              <Card variant={"outlined"} sx={{ width: "80vw" }}>
                <LineGraph
                  isLoading={sakhiResponse.isLoading}
                  hasError={sakhiResponse.hasError}
                  data={sakhiResponse.data?.json_data as any}
                  xAxisLabelFormatter={fromDateToFormat}
                />
              </Card>
            </ErrorBoundary>
            <Spacing spacing={5} />
          </>
        )}
        {sakhiResponse?.data?.json_data?.errors?.error !== "" ? (
          <Card variant={"outlined"} sx={{ maxWidth: "80vw", overflow: "scroll" }}>
            <Typography color={"secondary"} align={"center"}>
              {sakhiResponse?.data?.query_string}
            </Typography>
            <Alert
              severity={"warning"}
              sx={{ backgroundColor: "rgba(27, 32, 35, 1.0)", justifyContent: "center" }}
            >
              Looks like the resultant query is incorrect, please provide more specific keywords to
              get accurate results.
            </Alert>
          </Card>
        ) : (
          <ErrorBoundary ErrorMessage={"Failed to render line graph"}>
            <Stack direction={"row"} justifyContent={"space-between"} sx={{ width: "80vw" }}>
              <Typography variant={"h4"} fontWeight={"bold"}>
                Results
              </Typography>
              <Button variant={"outlined"} onClick={() => openDialog("table")}>
                Add To Dashboard
              </Button>
            </Stack>
            <Card variant={"outlined"} sx={{ width: "80vw" }}>
              <Stack direction={"row"} alignItems={"center"} justifyContent={"space-between"}>
                <Typography color={"secondary"} maxWidth={"80%"}>
                  {sakhiResponse?.data?.query_string}
                </Typography>
                <Button
                  variant={"outlined"}
                  onClick={() => {
                    copyTextToClipboard(sakhiResponse?.data?.query_string || "", onCopy, () =>
                      toast.error("Failed to copy")
                    );
                  }}
                  endIcon={<CopyAll />}
                >
                  Copy Query
                </Button>
              </Stack>
            </Card>
            <Card variant={"outlined"} sx={{ width: "80vw" }}>
              <StyledDataGrid
                rows={sakhiResponse.data?.json_data?.data || []}
                getRowId={(row) => row?.custom_row_id as any}
                columns={columns}
                isRowSelectable={() => false}
                disableExtendRowFullWidth={false}
                autoHeight
                pageSize={5}
              />
            </Card>
          </ErrorBoundary>
        )}
        <Spacing spacing={10} />
      </APIDataLoader>
    );
  }
  return <></>;
};

export default SakhiResponse;

import React from "react";
import { Controller, SubmitHandler, useForm } from "react-hook-form";
import { Stack } from "@mui/material";
import { EventsAndFunctionsFormValidation } from "../Validations";
import StepsController from "./ChildParserStepsController";
import { EventsAndFunctionsFormValues } from "../types";
import useNewChildParserFormValues from "./ChildParserFormStateHandler";
import { default as MuiSkeleton } from "@mui/material/Skeleton/Skeleton";
import useABIDetailsClient from "../ABIDetailsClient";

import Typography from "@mui/material/Typography";
import { MuiMultiSelect } from "../EventsAndFunctionForm";

const ChildParserEventsAndFunctionForm = () => {
  const {
    updateEventsAndFunctionsForm,
    childParserFormValue: { events, functions },
  } = useNewChildParserFormValues();
  const methods = useForm<EventsAndFunctionsFormValues>({
    defaultValues: { events, functions },
    resolver: EventsAndFunctionsFormValidation,
  });
  const { handleSubmit } = methods;

  const onSubmit: SubmitHandler<EventsAndFunctionsFormValues> = updateEventsAndFunctionsForm;
  const { childParserABIDetails } = useABIDetailsClient();

  if (childParserABIDetails.isLoading) {
    return (
      <Stack spacing={1}>
        <MuiSkeleton variant={"rectangular"} width={200} />
        <MuiSkeleton variant={"rectangular"} height={100} />
      </Stack>
    );
  }

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Stack spacing={3}>
        <Stack spacing={1}>
          <Typography fontSize={"18px"} fontWeight={600}>
            Events
          </Typography>
          <Controller
            control={methods.control}
            name={"events"}
            render={({ field: { onChange, name, value, onBlur }, fieldState }) => (
              <MuiMultiSelect
                label={"Please select events"}
                value={value}
                options={childParserABIDetails?.data?.events || []}
                onBlur={onBlur}
                onChange={onChange}
                name={name}
                helperText={
                  fieldState?.error?.message || (fieldState?.error as any)?.value?.message
                }
                error={!!fieldState?.error}
              />
            )}
          />
        </Stack>
        {/* <Stack spacing={1}>*/}
        {/*  <Typography fontSize={"18px"} fontWeight={600}>*/}
        {/*    Functions*/}
        {/*  </Typography>*/}
        {/*  <Controller*/}
        {/*    control={methods.control}*/}
        {/*    name={"functions"}*/}
        {/*    render={({ field: { onChange, name, value, onBlur }, fieldState }) => (*/}
        {/*      <MuiMultiSelect*/}
        {/*        label={"Please select functions"}*/}
        {/*        value={value}*/}
        {/*        options={childParserABIDetails?.data?.functions || []}*/}
        {/*        onBlur={onBlur}*/}
        {/*        onChange={onChange}*/}
        {/*        name={name}*/}
        {/*        helperText={*/}
        {/*          fieldState?.error?.message || (fieldState?.error as any)?.value?.message*/}
        {/*        }*/}
        {/*        error={!!fieldState?.error}*/}
        {/*      />*/}
        {/*    )}*/}
        {/*  />*/}
        {/* </Stack>*/}
        <StepsController />
      </Stack>
    </form>
  );
};

export default ChildParserEventsAndFunctionForm;

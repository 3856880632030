import React, { useEffect, useState } from "react";
import { Container, Grid, Hidden, Menu, MenuItem, Stack, Tooltip } from "@mui/material";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import useIndexerDetails, { IndexerDetails } from "./IndexerClient";
import { LinearProgressWithLabel } from "./details/IndexerDetails";
import { toast } from "react-toastify";
import useMetabaseDetails from "./metabase/MetabaseClient";
import APIDataLoader from "../common/apiDataLoader/APIDataLoader";
import { Download, ExpandMore } from "@mui/icons-material";
import Spacing from "../common/Spacing";
import { Link, Route, Routes, useNavigate, useSearchParams } from "react-router-dom";
import { toIndexer, toNewIndexer, toParserOverview } from "../common/Routes";
import IndexerStatus from "./details/IndexerStatus";
import Mixpanel, { MixpanelEvents } from "../../MixpanelConfig";
import useGraphQLDetails from "./graphQL/GraphQLClient";
import ParserForm from "./create/NewParserForm";
import { atom, useRecoilState } from "recoil";
import notFoundImg from "../../assets/images/NotFound.png";
import notFoundHappy from "../../assets/images/NotFoundHappy.png";
import MuiLink from "@mui/material/Link";

export const StatusClasses: Map<string, string> = new Map<string, string>([
  ["Requested", "Requested"],
  ["Completed", "Completed"],
  ["UploadedSource", "Completed"],
  ["TriggeredDeploy", "InProgress"],
  ["Pending", "InProgress"],
  ["TriggeredMiddleware", "InProgress"],
  ["BuildRunning", "InProgress"],
  ["Deployed", "Deployed"],
  ["Deploying", "InProgress"],
  ["Failed", "Failed"],
  ["Paused", "Paused"],
  ["ProcessingFailed", "Failed"],
  ["BuildFailed", "Failed"],
  ["Terminated", "Terminated"],
  ["CrashLooping", "Deployed"],
]);

export const EndStatus = [
  "Deployed",
  "UploadedSource",
  "Failed",
  "BuildFailed",
  "ProcessingFailed",
  "Completed",
  "Terminated",
  "CrashLooping",
  "Paused",
];

const RunningStatus = ["Requested", "InProgress", "Deployed"];
const FilterByParserStatus: Map<string, string> = new Map<string, string>([
  ["Running", "Running"],
  ["All", "All"],
  ["Failed", "Failed"],
  ["Terminated", "Terminated"],
  ["Paused", "Paused"],
]);

const parentChildAtom = atom<Map<string, IndexerDetails>>({
  key: "Parent child pairs",
  default: new Map(),
});

const useParentChildPairs = () => {
  const [parentChildPairs, updateParentChildPairs] = useRecoilState(parentChildAtom);

  return { parentChildPairs, updateParentChildPairs };
};

const IndexerList = () => {
  const { indexersByStatus, getIndexersByStatus, reset, indexersByUserID } = useIndexerDetails();
  const navigate = useNavigate();
  const { refreshMetabase } = useMetabaseDetails();
  const { getGraphQLDetails } = useGraphQLDetails();
  const [searchParams] = useSearchParams();
  const status = searchParams.get("status") || "Running";
  const [numberOfIndexers, setNumberOfIndexers] = useState<number>(
    indexersByStatus?.data?.indexer_list?.length || 0
  );

  const { parentChildPairs, updateParentChildPairs } = useParentChildPairs();
  const IndexerDetails = ({
    isChild,
    isParent,
    indexer,
  }: {
    isChild: boolean;
    isParent: boolean;
    indexer: IndexerDetails | undefined;
  }) => {
    if (indexer !== undefined)
      return (
        <Stack
          direction={"row"}
          justifyContent={"center"}
          width={"100%"}
          sx={{
            transition: "transform 0.3s, border 0.3s",
            "&:hover": {
              borderColor: "#34D399",
              transform: "translateY(-2px)",
            },
            border: "1px solid rgba(255, 255, 255, 0.22)",
            background: "rgba(255, 255, 255, 0.06)",
            borderRadius: "12px",
            padding: { xs: "8px 16px 8px 20px", md: "16px 32px 16px 40px" },
            mb: isChild ? 0 : 1,
          }}
          onClick={() => {
            Mixpanel.track(MixpanelEvents.ViewDetails, {
              object: "Parser",
              indexer: indexer?.indexer_name,
            });
          }}
          key={indexer?.generated_indexer_id}
        >
          <Link
            to={toParserOverview(indexer?.generated_indexer_id)}
            style={{
              width: "100%",
              textDecoration: "none",
              color: "white",
              justifyContent: "center",
            }}
          >
            <Grid width={"100%"} container alignItems={"center"}>
              <Grid item xs={6} sm={3} md={4} lg={3} sx={{ mb: { xs: 1, sm: 0 } }}>
                <Tooltip title={indexer?.indexer_name} placement={"top"}>
                  <Typography
                    fontWeight={"600"}
                    variant={"h6"}
                    sx={{
                      maxWidth: "80%",
                      textOverflow: "ellipsis",
                      overflow: "hidden",
                      whiteSpace: "nowrap",
                    }}
                  >
                    {indexer?.indexer_name}
                  </Typography>
                </Tooltip>
              </Grid>
              <Grid item xs={6} sm={2} md={3} lg={2}>
                <Hidden mdDown>
                  <Typography fontWeight={"500"} sx={{ opacity: 0.75 }} display={"inline"}>
                    Status: &nbsp;&nbsp;
                  </Typography>
                </Hidden>
                <IndexerStatus indexer={indexer} />
              </Grid>
              <Grid item xs={6} sm={4} md={3} lg={5}>
                {(indexer?.deploy_status === "Deployed" ||
                  indexer?.deploy_status === "CrashLooping") && (
                  <>
                    {indexer?.sync_status &&
                      indexer?.sync_status?.map((syncStatus, index) => (
                        <React.Fragment key={syncStatus?.sync_type}>
                          <Stack
                            direction={"row"}
                            justifyContent={"space-evenly"}
                            spacing={1}
                            sx={{
                              mb: index === indexer?.sync_status?.length - 1 ? 0 : 1,
                            }}
                          >
                            <span
                              style={{
                                color: "#197273",
                                borderRadius: "100px",
                                padding: "4px 8px",
                                border: "1px solid #197273",
                                width: "100px",
                                textAlign: "center",
                              }}
                            >
                              {syncStatus?.sync_type}
                            </span>
                            <LinearProgressWithLabel
                              key={syncStatus?.sync_type}
                              sync_status={syncStatus}
                              isChild={isChild}
                            />
                          </Stack>
                        </React.Fragment>
                      ))}
                  </>
                )}
                {indexer?.deploy_status === "UploadedSource" && (
                  <a style={{ color: "#197273" }} href={indexer?.download_link} download>
                    <Stack direction={"row"} alignItems={"center"} color={"#197273"}>
                      <Tooltip title={indexer?.download_link || ""} placement={"top"}>
                        <Typography>Download Source code</Typography>
                      </Tooltip>
                      <Download color={"inherit"} />
                    </Stack>
                  </a>
                )}
              </Grid>
              <Grid item xs={6} sm={3} md={2}>
                {isChild && <Typography textAlign={"center"}>Child</Typography>}
                {isParent && <Typography textAlign={"center"}>Factory</Typography>}
              </Grid>
            </Grid>
          </Link>
        </Stack>
      );
    return <></>;
  };
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  useEffect(() => {
    getIndexersByStatus(status);
    const interval = setInterval(() => {
      getIndexersByStatus(status);
      // updateParentChildPairs(new Map());
    }, 10000);
    return () => {
      reset();
      clearInterval(interval);
    };
  }, [status]);

  useEffect(() => {
    if (
      numberOfIndexers < (indexersByStatus?.data?.indexer_list?.length || 0) &&
      areAllIndexersInEndState()
    ) {
      refreshMetabase();
      getGraphQLDetails();
      setNumberOfIndexers(indexersByStatus?.data?.indexer_list?.length);
    }
    if (indexersByStatus?.data && indexersByStatus?.data?.indexer_list?.length > 0) {
      for (let i = 0; i < indexersByStatus?.data?.indexer_list?.length; i++) {
        if (
          indexersByStatus?.data?.indexer_list[i]?.parent_auto_generator_id !== "" &&
          indexersByStatus?.data?.indexer_list[i]?.deploy_status !== "Failed"
        ) {
          updateParentChildPairs(
            parentChildPairs?.set(
              indexersByStatus?.data?.indexer_list[i]?.parent_auto_generator_id,
              indexersByStatus?.data?.indexer_list[i]
            )
          );
        }
      }
    }
  }, [indexersByStatus?.data]);

  function areAllIndexersInEndState() {
    for (let i = 0; i < indexersByStatus?.data?.indexer_list?.length; i++) {
      const status = indexersByStatus?.data?.indexer_list[i]?.deploy_status;
      if (EndStatus.indexOf(status) < 0) {
        return false;
      }
    }
    return true;
  }

  const handleClick = (event: any) => {
    setAnchorEl(event.currentTarget);
  };

  function handleMenuClick(status: string) {
    setAnchorEl(null);
    Mixpanel.track(MixpanelEvents.FilterParser, { status: status });
    navigate(toIndexer(status));
  }

  function handleClose() {
    setAnchorEl(null);
  }

  return (
    <React.Fragment>
      {/* TODO: add background filter*/}
      <Container maxWidth={"xl"}>
        <Stack
          direction={"column"}
          justifyContent={"flex-start"}
          alignItems={"center"}
          maxWidth={"lg"}
          sx={{ mx: "auto" }}
        >
          <Stack
            direction={"row"}
            width={"100%"}
            justifyContent={"space-between"}
            alignItems={"center"}
          >
            <Stack direction={"column"} justifyContent={"flex-start"} sx={{ my: 2 }}>
              <Typography variant={"h3"} fontWeight={600}>
                Parsers
              </Typography>
            </Stack>
            <Stack direction={"row"}>
              <Button
                variant="contained"
                sx={{
                  mr: 1,
                  height: "50px",
                  borderRadius: "4px",
                  "&:hover": { transform: "scale(1.1)" },
                }}
                onClick={() => {
                  Mixpanel.track(MixpanelEvents.NewParser, { source: "Header" });
                  if (!areAllIndexersInEndState()) {
                    toast.warn("There are pending processes! Please try after a while");
                  } else {
                    navigate(toNewIndexer());
                  }
                }}
              >
                <Typography variant={"h6"} fontWeight={600}>
                  Create New
                </Typography>
              </Button>
            </Stack>
          </Stack>
          <Stack direction={"row"} width={"100%"}>
            <Typography
              variant={"h6"}
              onClick={handleClick}
              display={"flex"}
              alignItems={"center"}
              sx={{ color: "#0DB678", cursor: "pointer" }}
            >
              {status}
              <ExpandMore color={"primary"} />
            </Typography>
          </Stack>
          <Menu open={open} onClose={handleClose} anchorEl={anchorEl}>
            {Array.from(FilterByParserStatus.keys()).map((filter) => (
              <MenuItem key={filter} disableRipple onClick={() => handleMenuClick(filter)}>
                {FilterByParserStatus.get(filter)}
              </MenuItem>
            ))}
          </Menu>
          {indexersByUserID?.error ? (
            <>
              <img src={notFoundImg} alt={"Not Found"} height={400} />
              <Typography alignItems={"center"} variant={"h6"} fontWeight={600}>
                You haven’t created any Parser yet :(
              </Typography>
            </>
          ) : (
            <APIDataLoader
              message={
                <>
                  <img
                    src={status === "All" || status === "Running" ? notFoundImg : notFoundHappy}
                    alt={"Not Found"}
                    height={400}
                  />
                  <Typography alignItems={"center"} variant={"h6"} fontWeight={600}>
                    {`You don't have any ${status === "All" ? "" : `${status} `}parsers`}
                  </Typography>
                  <MuiLink
                    href={"https://docs.unmarshal.io/reference/what-is-unmarshal-parser"}
                    target={"_blank"}
                  >
                    <Button
                      variant="outlined"
                      sx={{
                        mr: 1,
                        height: "44px",
                        borderRadius: "4px",
                        mt: 1,
                      }}
                      onClick={() => {
                        Mixpanel.track(MixpanelEvents.ParserDocumentation, { source: "Parsers" });
                      }}
                    >
                      <Typography variant={"h6"} fontWeight={600}>
                        Read Docs
                      </Typography>
                    </Button>
                  </MuiLink>
                </>
              }
              hasError={indexersByStatus.hasError}
              isLoading={indexersByStatus.isLoading && !indexersByStatus.hasError}
              isSuccess={indexersByStatus.isSuccess}
            >
              {indexersByStatus?.data?.indexer_list.length != 0 &&
                indexersByStatus?.data?.indexer_list?.map((indexer) => {
                  const isChild = indexer.parent_auto_generator_id !== "";
                  const isParent = parentChildPairs?.get(indexer.generated_indexer_id) != undefined;
                  if (isChild) {
                    const parent = indexersByStatus?.data?.indexer_list.find(
                      (indexerObject) =>
                        indexerObject.generated_indexer_id === indexer.parent_auto_generator_id
                    );
                    const childStatus = StatusClasses.get(indexer.deploy_status) || "";
                    const parentStatus = StatusClasses.get(parent?.deploy_status || "") || "";
                    if (
                      childStatus === parentStatus ||
                      (RunningStatus.indexOf(childStatus) >= 0 &&
                        RunningStatus.indexOf(parentStatus) >= 0)
                    )
                      return <></>;
                    else
                      return (
                        <>
                          <IndexerDetails isChild={isChild} isParent={isParent} indexer={indexer} />
                          <Spacing spacing={1} />
                        </>
                      );
                  }
                  if (
                    isParent &&
                    (StatusClasses.get(indexer.deploy_status) ===
                      StatusClasses.get(
                        parentChildPairs.get(indexer.generated_indexer_id)?.deploy_status || ""
                      ) ||
                      (RunningStatus.indexOf(StatusClasses.get(indexer.deploy_status) || "") >= 0 &&
                        RunningStatus.indexOf(
                          StatusClasses.get(
                            parentChildPairs.get(indexer.generated_indexer_id)?.deploy_status || ""
                          ) || ""
                        ) >= 0))
                  ) {
                    return (
                      <Stack
                        direction={"column"}
                        width={"100%"}
                        sx={{
                          border: "1px solid rgba(225,225,225,0.5)",
                          borderRadius: "14px",
                          padding: 2,
                          mb: 1,
                        }}
                      >
                        <IndexerDetails isChild={isChild} indexer={indexer} isParent={isParent} />
                        <IndexerDetails
                          isChild={true}
                          indexer={parentChildPairs?.get(indexer.generated_indexer_id)}
                          isParent={false}
                        />
                      </Stack>
                    );
                  } else {
                    return (
                      <IndexerDetails isChild={isChild} indexer={indexer} isParent={isParent} />
                    );
                  }
                })}
            </APIDataLoader>
          )}
          <Spacing spacing={2} />
        </Stack>
      </Container>
    </React.Fragment>
  );
};

const Indexer = () => {
  return (
    <Routes>
      <Route path="/" element={<IndexerList />} />
      <Route path="/new" element={<ParserForm />} />
    </Routes>
  );
};

export default Indexer;

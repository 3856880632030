import React, { useEffect } from "react";
import { Tooltip, Typography } from "@mui/material";
import useIndexerDetailsByID from "../IndexerDetailsClient";
import useContractTransactions, { Transaction } from "./ContractTransactionsClient";
import APIDataLoader, { LoadingIndicator } from "../../common/apiDataLoader/APIDataLoader";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import Table from "@mui/material/Table";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import TableCell from "@mui/material/TableCell";
import TableBody from "@mui/material/TableBody";
import { formatNumberFractionDigits, wrapMiddle } from "../../common/string/format";
import Copy from "../../common/clipboard/copy";
import fromUnixTime from "date-fns/fromUnixTime";
import { format } from "date-fns";
import { chainDetailsMap, getUnmarshalChainName } from "../../../config";
import Mixpanel, { MixpanelEvents } from "../../../MixpanelConfig";
import InfiniteScroll from "react-infinite-scroller";

function TransactionTable({ transactions, chain }: { transactions: Transaction[]; chain: string }) {
  const headerStyle = {
    fontWeight: 600,
    fontSize: "20px",
    color: "#0DB678",
  };

  return (
    <Table>
      <TableHead
        sx={{
          position: "sticky",
          top: 0,
          zIndex: 1,
          background: "rgb(45,54,58)",
        }}
      >
        <TableRow>
          <TableCell>
            <Typography sx={headerStyle}>Tx Hash</Typography>
          </TableCell>
          <TableCell>
            <Typography sx={headerStyle}>Method</Typography>
          </TableCell>
          <TableCell>
            <Typography sx={headerStyle}>From</Typography>
          </TableCell>
          <TableCell>
            <Typography sx={headerStyle}>To</Typography>
          </TableCell>
          <TableCell>
            <Typography sx={headerStyle}>Time</Typography>
          </TableCell>
          <TableCell>
            <Typography sx={headerStyle}>Value</Typography>
          </TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        {transactions.map((transaction) => (
          <TableRow key={transaction?.id}>
            <TableCell>
              <Typography>
                <Copy
                  content={transaction?.id}
                  contentType={"Transaction Hash"}
                  source={"Transactions"}
                >
                  <a
                    href={`https://xscan.io/transactions/${
                      transaction?.id
                    }?chain=${getUnmarshalChainName(chain)}`}
                    target={"_blank"}
                    rel="noreferrer"
                    onClick={() =>
                      Mixpanel.track(MixpanelEvents.ExternalNavigation, {
                        to: "Xscan transaction details",
                      })
                    }
                  >
                    {wrapMiddle(transaction?.id, 6, 4)}
                  </a>
                </Copy>
              </Typography>
            </TableCell>
            <TableCell>
              <Tooltip title={transaction?.type} placement={"top"}>
                <Typography width={"max-content"}>
                  {wrapMiddle(transaction?.type, 15, 0)}
                </Typography>
              </Tooltip>
            </TableCell>
            <TableCell>
              <Typography>
                <Copy content={transaction?.from} contentType={"From"} source={"Transactions"}>
                  <a
                    href={`https://xscan.io/address/${
                      transaction?.from
                    }/assets?chain=${getUnmarshalChainName(chain)}`}
                    target={"_blank"}
                    rel="noreferrer"
                    onClick={() =>
                      Mixpanel.track(MixpanelEvents.ExternalNavigation, {
                        to: "Xscan token balance",
                      })
                    }
                  >
                    {wrapMiddle(transaction?.from, 6, 4)}
                  </a>
                </Copy>
              </Typography>
            </TableCell>
            <TableCell>
              <Typography>
                <Copy content={transaction?.to} contentType={"To"} source={"Transactions"}>
                  <a
                    href={`https://xscan.io/address/${
                      transaction?.to
                    }/assets?chain=${getUnmarshalChainName(chain)}`}
                    target={"_blank"}
                    rel="noreferrer"
                    onClick={() =>
                      Mixpanel.track(MixpanelEvents.ExternalNavigation, {
                        to: "Xscan token balance",
                      })
                    }
                  >
                    {wrapMiddle(transaction?.to, 6, 4)}
                  </a>
                </Copy>
              </Typography>
            </TableCell>
            <TableCell>
              <Typography>
                {format(fromUnixTime(transaction?.date), "MMM-dd-yy hh:mm:ss a")}
              </Typography>
            </TableCell>
            <TableCell>
              <Typography>{`${formatNumberFractionDigits(
                Number(transaction?.value),
                4,
                transaction?.native_token_decimals
              )} ${chainDetailsMap.get(chain)?.native_token_symbol}`}</Typography>
            </TableCell>
          </TableRow>
        ))}
      </TableBody>
    </Table>
  );
}

const ContractTransactions = () => {
  const { indexerDetails } = useIndexerDetailsByID();
  const { contractTransactions, getContractTransactions, loadMore } = useContractTransactions();
  const transactions = contractTransactions?.data?.transactions || [];

  useEffect(() => {
    if (
      indexerDetails.data?.contract_address !== undefined &&
      indexerDetails.data?.chain !== undefined
    )
      getContractTransactions(indexerDetails.data?.contract_address, indexerDetails.data?.chain);
  }, [indexerDetails.data?.contract_address, indexerDetails.data?.chain]);

  return (
    <APIDataLoader {...contractTransactions}>
      <Card sx={{ maxHeight: "75vh", overflow: "scroll" }} id={"scroll-paper"}>
        <CardContent>
          <InfiniteScroll
            pageStart={0}
            useWindow={false}
            getScrollParent={() => document.getElementById("scroll-paper")}
            loadMore={() =>
              loadMore(
                getUnmarshalChainName(indexerDetails?.data?.chain),
                indexerDetails?.data?.contract_address
              )
            }
            hasMore={transactions?.length < contractTransactions?.data?.total_txs}
            loader={<LoadingIndicator />}
          >
            <TransactionTable
              transactions={contractTransactions?.data?.transactions || []}
              chain={indexerDetails?.data?.chain}
            />
          </InfiniteScroll>
          {transactions.length === 0 && (
            <Typography align="center">
              <br />
              No Transactions found!
              <br />
            </Typography>
          )}
        </CardContent>
      </Card>
    </APIDataLoader>
  );
};

export default ContractTransactions;

import React, { useEffect, useState } from "react";
import { Controller, SubmitHandler, useForm } from "react-hook-form";
import { Autocomplete, Stack, TextField, Typography } from "@mui/material";
import { getChainLogoName, indexerConfig } from "../../../config";
import { ChainAndContractFormValidation } from "./Validations";
import StepsController from "./StepsController";
import { ContractAndChainFormValues, GeneratorType, GeneratorTypeOptions } from "./types";
import useNewIndexerFormValues from "./FormStateHandler";
import useParserClient from "./ParserClient";
import { default as MuiSkeleton } from "@mui/material/Skeleton/Skeleton";

const ChainAndContractForm = () => {
  const { updateChainAndContractForm, indexerFormValue } = useNewIndexerFormValues();
  const methods = useForm<ContractAndChainFormValues>({
    defaultValues: {
      chain: indexerFormValue.chain,
      contractAddress: indexerFormValue.contractAddress,
      generatorType: indexerFormValue.generatorType,
    },
    resolver: ChainAndContractFormValidation,
  });
  const { getSupportedChains } = useParserClient();
  const [supportedChains, setSupportedChains] = useState<Array<string>>([]);
  const { handleSubmit, watch, setValue } = methods;
  const onSubmit: SubmitHandler<ContractAndChainFormValues> = (value) =>
    updateChainAndContractForm(value);

  useEffect(() => {
    getSupportedChains((value) => {
      setSupportedChains(value);
    });
  }, []);

  const generatorType = watch("generatorType")?.value;

  useEffect(() => {
    setValue("chain", null);
  }, [generatorType]);

  if (!supportedChains.length) {
    return (
      <Stack spacing={1}>
        <MuiSkeleton variant={"rectangular"} width={200} />
        <MuiSkeleton variant={"rectangular"} height={100} />
      </Stack>
    );
  }

  console.log(generatorType, "generatorType");

  function ChainOption() {
    let options = indexerConfig.chains;
    if (generatorType === GeneratorType.Subgraph) {
      options = indexerConfig.subgraphSupportedChains;
    }
    return (
      <Controller
        control={methods.control}
        render={({ field: { onChange, name, value, onBlur }, fieldState }) => {
          return (
            <Autocomplete
              onBlur={onBlur}
              value={value}
              getOptionLabel={(option) => option.label}
              isOptionEqualToValue={(option, value) => option.value === value.value}
              options={options}
              renderOption={(props, option) =>
                supportedChains.includes(option.value) && (
                  <Typography {...props}>
                    <img
                      src={`https://assets.unmarshal.io/tokens/${getChainLogoName(
                        option.value
                      )}.png`}
                      alt={""}
                      width={20}
                    />
                    &nbsp;&nbsp;
                    {option.label}
                  </Typography>
                )
              }
              onChange={(e, value) => onChange(value)}
              renderInput={(params) => (
                <TextField
                  {...params}
                  name={name}
                  label="Select Chain*"
                  helperText={
                    fieldState?.error?.message || (fieldState?.error as any)?.value?.message
                  }
                  error={!!fieldState?.error}
                />
              )}
            />
          );
        }}
        name={"chain"}
      />
    );
  }

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Stack spacing={3}>
        {/* <Controller*/}
        {/*  control={methods.control}*/}
        {/*  render={({ field: { onChange, name, value, onBlur }, fieldState }) => (*/}
        {/*    <Autocomplete*/}
        {/*      onBlur={onBlur}*/}
        {/*      value={value}*/}
        {/*      isOptionEqualToValue={(option, value) => option.value === value.value}*/}
        {/*      options={GeneratorTypeOptions}*/}
        {/*      getOptionLabel={(option) => option.label}*/}
        {/*      onChange={(e, value) => {*/}
        {/*        onChange(value);*/}
        {/*      }}*/}
        {/*      renderInput={(params) => (*/}
        {/*        <TextField*/}
        {/*          {...params}*/}
        {/*          name={name}*/}
        {/*          label="Select generator type"*/}
        {/*          helperText={*/}
        {/*            fieldState?.error?.message || (fieldState?.error as any)?.value?.message*/}
        {/*          }*/}
        {/*          error={!!fieldState?.error}*/}
        {/*        />*/}
        {/*      )}*/}
        {/*    />*/}
        {/*  )}*/}
        {/*  name={"generatorType"}*/}
        {/* />*/}
        <ChainOption />
        <Controller
          control={methods.control}
          render={({ field: { onChange, name, value, onBlur }, fieldState }) => (
            <TextField
              fullWidth
              name={name}
              value={value}
              label="Enter Contract Address*"
              onBlur={onBlur}
              onChange={onChange}
              helperText={fieldState?.error?.message}
              error={!!fieldState?.error}
            />
          )}
          name={"contractAddress"}
        />
        <StepsController />
      </Stack>
    </form>
  );
};

export default ChainAndContractForm;

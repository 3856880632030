import { atom, useRecoilState } from "recoil";
import DefaultAPIDataValues, { APIData } from "../common/rest-client/types";
import { useUnmarshalApiPostRequest } from "../common/rest-client/unmarshalClient";
import useUser from "../user/useUser";
import { parse } from "papaparse";
import useAsyncRestClient from "../common/rest-client/AsyncRestClient";
import useGetRequest from "../common/rest-client/get";
import { QueryResponse } from "../indexer/dataExplorer/DataExplorerClient";

export function metabaseResponseToDataGridData(responseData: QueryResponse) {
  const data = {
    cols: responseData?.data?.cols || [],
    rows: responseData?.data?.rows || [],
  };
  return data.rows.map((row, index) => {
    const rowObject: any = {};
    row.forEach((value, index) => {
      rowObject[data.cols[index].display_name] = String(value);
    });
    return { ...rowObject, custom_row_id: index };
  });
}

export function metabaseResponseError(responseData: QueryResponse) {
  return {
    error: responseData.error || "",
    error_type: responseData.error_type || "",
  };
}

export interface JsonData {
  data: any[];
  errors?: any;
  meta: Sakhi.Meta;
}

declare module Sakhi {
  export interface Dataset {
    data: number[];
    label: string;
    pointRadius: number;
  }

  export interface Data {
    labels: string[];
    datasets: Dataset[];
  }

  export interface Title {
    display: boolean;
    text: string;
  }

  export interface Plugins {
    title: Title;
  }

  export interface Title2 {
    display: boolean;
    text: string;
  }

  export interface Options {
    plugins: Plugins;
    title: Title2;
  }

  export interface ChartsJson {
    type: string;
    data: Data;
    options: Options;
  }

  export interface Datum {
    day: string;
    pairs_created: string;
  }

  export interface Meta {
    delimiter?: string;
    linebreak?: string;
    aborted?: boolean;
    truncated?: boolean;
    cursor?: number;
    fields: string[];
  }

  export interface Response {
    query_id: string;
    query_string: string;
    charts_jsons: ChartsJson[];
    explanation: string;
    csv_file: string;
    json_data: JsonData;
    warning?: any;
  }

  export interface AddToDashboard {
    dashboard_name: string;
    graph_type: string;
    query: string;
    question: string;
    metadata?: any;
  }

  export interface Dashboard {
    dashboard_id: number;
    name: string;
    created_at: Date;
  }
}

const tableNamesAtom = atom<APIData<Partial<Sakhi.Response>>>({
  key: "INDEXER_TABLE_NAMES",
  default: DefaultAPIDataValues,
});

const dashboardsAtom = atom<APIData<Array<Sakhi.Dashboard>>>({
  key: "DASHBOARDS_ATOM",
  default: DefaultAPIDataValues,
});

const useSakhi = () => {
  const [sakhiResponse, sakhiResponseUpdater] = useRecoilState(tableNamesAtom);
  const [dashboards, setDashboards] = useRecoilState(dashboardsAtom);
  const { post: postToCis } = useAsyncRestClient();
  const get = useGetRequest();
  const post = useUnmarshalApiPostRequest();
  const { apiKey } = useUser();

  const getDashboards = () => get(setDashboards, "/cis/v1/sakhi/dashboards");

  const addToDashboard = (data: Sakhi.AddToDashboard) =>
    postToCis("/cis/v1/sakhi/question", { data });

  const askSakhi = (question: string, tables: Array<String>, indexerId: string) => {
    post(
      apiKey,
      sakhiResponseUpdater,
      "/v1/sakhi",
      {
        auto_run: true,
        question,
        tables,
        indexer_id: indexerId,
      },
      {
        parse({ cache, response_type, response, query_string, graph_types }: any): Sakhi.Response {
          if (cache || response_type === "metabase") {
            const newVar = {
              csv_file: "",
              charts_jsons: (graph_types || []).map(
                (type: string) =>
                  ({
                    type,
                    data: null,
                    options: null,
                  } as any)
              ),
              json_data: {
                data: metabaseResponseToDataGridData(response),
                errors: metabaseResponseError(response),
                meta: { fields: (response?.data?.cols || []).map(({ name }: any) => name) },
              },
              explanation: "",
              query_string: query_string,
              query_id: "",
            };
            return newVar;
          }

          const { csv_file, ...rest } = response;
          const jsonData = parse(csv_file.substring(0, csv_file.length - 2), { header: true });
          jsonData.data = (jsonData.data || []).map((data: any, index) => ({
            custom_row_id: index,
            ...data,
          }));
          return {
            ...rest,
            json_data: jsonData,
          };
        },
      }
    );
  };

  return { askSakhi, sakhiResponse, addToDashboard, getDashboards, dashboards };
};

export default useSakhi;

import React, { useEffect } from "react";
import { Navigate, Route, Routes } from "react-router-dom";
import IndexerDetails from "./details/IndexerDetails";
import Dashboard from "../dashboard/Dashboard";
import { SideNavItems } from "../dashboard/NavBarItems";
import Credentials from "./analytics/Credentials";
import useIndexerDetails from "./IndexerClient";
import Statistics from "./analytics/Statistics";
import Indexer from "./Indexer";
import GraphqlPlayground from "./graphQL/GraphQLPlayground";
import ContractTransactions from "./transactions/ContractTransactions";
import DataExplorer from "./dataExplorer/DataExplorer";
import ParserDashboard from "./Dashboard";
import Spacing from "../common/Spacing";
import { Stack, Typography } from "@mui/material";
import comingSoon from "../../assets/images/COMING SOON.png";
import Sakhi from "../sakhi/Sakhi";
import CustomQuery from "./dataExplorer/CustomQuery";
import { useAuth } from "@aws-amplify/ui-react/internal";
import useWhitelistUsers from "../sakhi/SakhiWhiteListClient";
import Notifications from "./notifications/Notifications";

const MonitorComingSoon = () => {
  return (
    <>
      <Spacing spacing={2} />
      <Stack height={"70vh"} justifyContent={"center"} alignItems={"center"}>
        <Typography sx={{ fontWeight: 700, fontSize: "44px", color: "#0DB678" }}>
          Monitor
        </Typography>
        <Typography
          sx={{ fontWeight: 600, fontSize: "24px", color: "rgba(244, 194, 122, 1)" }}
        ></Typography>
        <Spacing spacing={1} />
        <img width={"18%"} src={comingSoon} alt={"Coming soon ..."} />
      </Stack>
    </>
  );
};

export const IndexersFetcher = ({ children }: React.PropsWithChildren<any>) => {
  const { getAllIndexersByUserID } = useIndexerDetails();
  useEffect(getAllIndexersByUserID, []);
  return <>{children}</>;
};

const ParserRouter = () => {
  const auth = useAuth();
  const userName = auth.user?.username || "";
  const { allowedUsers } = useWhitelistUsers();
  return (
    <Routes>
      <Route
        path="/*"
        element={
          <Dashboard selectedTab={SideNavItems.Parser} topSpacing={0}>
            <Indexer />
          </Dashboard>
        }
      />
      <Route
        path="/:id/credentials"
        element={
          <ParserDashboard selectedTab={SideNavItems.Parser} subTab={SideNavItems.Analytics}>
            <Credentials />
          </ParserDashboard>
        }
      />
      <Route
        path="/:id/details"
        element={
          <ParserDashboard selectedTab={SideNavItems.Parser} subTab={SideNavItems.Details}>
            <IndexerDetails />
          </ParserDashboard>
        }
      />
      <Route
        path="/:id/transactions"
        element={
          <ParserDashboard selectedTab={SideNavItems.Parser} subTab={SideNavItems.Transactions}>
            <ContractTransactions />
          </ParserDashboard>
        }
      />
      <Route
        path="/:id/data-explorer/*"
        element={
          <ParserDashboard selectedTab={SideNavItems.Parser} subTab={SideNavItems.DataExplorer}>
            <DataExplorer />
          </ParserDashboard>
        }
      />
      <Route
        path="/:id/execute"
        element={
          <ParserDashboard selectedTab={SideNavItems.Parser} subTab={SideNavItems.DynamicAPI}>
            <CustomQuery />
          </ParserDashboard>
        }
      />
      <Route
        path="/:id/statistics/*"
        element={
          <ParserDashboard selectedTab={SideNavItems.Parser} subTab={SideNavItems.Analytics}>
            <Statistics />
          </ParserDashboard>
        }
      />
      <Route
        path="/:id/sakhi"
        element={
          (allowedUsers?.data?.value?.enable_whitelist &&
            allowedUsers?.data?.value?.users?.includes(userName)) ||
          !allowedUsers?.data?.value?.enable_whitelist ? (
            <ParserDashboard selectedTab={SideNavItems.Parser} subTab={SideNavItems.SakhiQuestions}>
              <Sakhi />
            </ParserDashboard>
          ) : (
            <Navigate to={"/parser"} />
          )
        }
      />
      <Route
        path="/:id/graphql"
        element={
          <ParserDashboard selectedTab={SideNavItems.Parser} subTab={SideNavItems.GraphQL}>
            <GraphqlPlayground />
          </ParserDashboard>
        }
      />
      <Route
        path="/:id/notifications"
        element={
          <ParserDashboard selectedTab={SideNavItems.Parser} subTab={SideNavItems.Notifications}>
            <Notifications />
          </ParserDashboard>
        }
      />
      <Route
        path="/:id/monitor"
        element={
          <ParserDashboard selectedTab={SideNavItems.Parser} subTab={SideNavItems.Monitor}>
            <MonitorComingSoon />
          </ParserDashboard>
        }
      />
    </Routes>
  );
};

export default ParserRouter;

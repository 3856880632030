import React from "react";
import { toUpper } from "lodash";
import { IconButton, Link } from "@mui/material";
import { API_WEIGHTAGES_DOCS_LINK } from "../../config";
import InfoIcon from "@mui/icons-material/Info";

export interface Plan {
  name: string;
  description: string;
  apis: Array<string>;
  includedFeatures: Array<any>;
  excludedFeatures?: Array<any>;
  customizedIntegration: Array<string>;
}

const CreditsLink = (
  <Link href={API_WEIGHTAGES_DOCS_LINK} target={"_blank"}>
    Credits
  </Link>
);

const CreditsInfoLink = () => (
  <Link href={API_WEIGHTAGES_DOCS_LINK} target={"_blank"}>
    <IconButton>
      <InfoIcon />
    </IconButton>
  </Link>
);

const getPlanCredits = (credits: string) => (
  <>
    {credits} {CreditsLink} per month <CreditsInfoLink />
  </>
);

export const getPlanDetailsByName = (name: string) => plans.get(toUpper(name)) as Plan;

const plans: Map<string, Plan> = new Map([
  [
    "FREE",
    {
      name: "Free Trial",
      description:
        "For people who are exploring Unmarshal API's and " +
        "looking to be a part of this great ecosystem.",
      apis: ["All API's"],
      includedFeatures: [getPlanCredits("500k"), "Allowed 500req/min"],
      excludedFeatures: [
        "Email support",
        "Dedicated Telegram/Slack channel support with < 10 minutes turn around time",
        "Customised APIs to fit your niche usecase",
        "Dedicated servers and integration engineer",
        "Access to sakhi",
      ],
      customizedIntegration: [],
    },
  ],
  [
    "STARTUP-OLD",
    {
      name: "Startup",
      description: "For startups/apps who have validated their idea.",
      apis: [
        "All APIs part of the free-plan",
        "Token Transactions with quote & quote rate",
        "NFT assets & Transaction history",
      ],
      includedFeatures: ["Email support", "300K requests per month"],
      customizedIntegration: [],
    },
  ],
  [
    "STARTUP",
    {
      name: "Startup",
      description:
        "For companies/apps who have validated products and started hitting the growth curve.",
      apis: ["All API's"],
      includedFeatures: [getPlanCredits("5M"), "Allowed 500req/min", "Email support"],
      excludedFeatures: [
        "Dedicated Telegram/Slack channel support with < 10 minutes turn around time",
        "Customised APIs to fit your niche usecase",
        "Dedicated servers and integration engineer",
        "Access to sakhi",
      ],
      customizedIntegration: [],
    },
  ],
  [
    "ENTERPRISE",
    {
      name: "Enterprise",
      description: "Exclusive and Unlimited access to all the API's with customisations.",
      apis: ["All API's"],
      includedFeatures: [
        getPlanCredits("Unlimited"),
        "Allowed Unlimited req/min",
        "Email support",
        "Dedicated Telegram/Slack channel support with < 10 minutes turn around time",
        "Customised APIs to fit your niche usecase",
        "Dedicated servers and integration engineer",
        "Access to sakhi",
      ],
      customizedIntegration: [],
    },
  ],
  [
    "EXPLORE",
    {
      name: "Explore",
      description:
        "For people who are exploring Unmarshal API's and " +
        "looking to be a part of this great ecosystem. Get first month for free!",
      apis: ["All API's"],
      includedFeatures: [getPlanCredits("1M"), "Allowed 50req/min"],
      excludedFeatures: [
        "Email support",
        "Dedicated Telegram/Slack channel support with < 10 minutes turn around time",
        "Customised APIs to fit your niche usecase",
        "Dedicated servers and integration engineer",
        "Access to sakhi",
      ],
      customizedIntegration: [],
    },
  ],
]);

export default plans;

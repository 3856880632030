import { createTheme } from "@mui/material";
import Theme from "../theme/Theme";

export const PricingTheme = createTheme({
  ...Theme,
  palette: {
    ...Theme.palette,
    primary: {
      main: "#FFFFFF",
      contrastText: "#0B0F15",
    },
    secondary: {
      main: "#0DB678",
      contrastText: "#0B0F15",
    },
  },
});

import api from "../../assets/icons/api.svg";
import home from "../../assets/icons/home.svg";
import plus from "../../assets/icons/Plus.svg";
import support from "../../assets/icons/Support.svg";
import folder from "../../assets/icons/Folder.svg";
import chat from "../../assets/icons/Chat.svg";
import statistics from "../../assets/icons/Statistics.svg";
import academy from "../../assets/icons/academy.svg";
import currencyDollar from "../../assets/icons/CurrencyDollar.svg";
import indexer from "../../assets/icons/Indexer.svg";
import analytics from "../../assets/icons/analytics.svg";
import transactions from "../../assets/icons/transactions.svg";
import sakhi from "../../assets/icons/sakhi.svg";
import dashboards from "../../assets/icons/dashboards.svg";
import details from "../../assets/icons/details.svg";
import metabaseData from "../../assets/icons/metabase-data.svg";
import dynamicAPI from "../../assets/icons/dynamicAPI.svg";
import monitor from "../../assets/icons/monitor.svg";
import notifications from "../../assets/icons/notifications.svg";
import graphQL from "../../assets/icons/graphql.svg";
import payment from "../../assets/icons/payment.svg";
import sdk from "../../assets/icons/sdk.svg";
import account from "../../assets/icons/account.svg";
import { Typography } from "@mui/material";
import React from "react";
import { CONTACT_US_MAIL_TO_LINK } from "../../config";
import { MixpanelEvents } from "../../MixpanelConfig";
import {
  toContractTransactions,
  toParserDataExplorer,
  toParserDynamicAPI,
  toParserGraphQLPlayground,
  toParserMonitor,
  toParserNotifications,
  toParserOverview,
  toParserStatistics,
  toSakhiDashboards,
  toSakhiQuestions,
} from "../common/Routes";
import { find } from "lodash";

const Indexer = () => {
  return (
    <>
      <Typography display={"inline"} sx={{ mr: 1, fontWeight: 600, fontSize: "18px" }}>
        Parser{" "}
      </Typography>
    </>
  );
};

const Sakhi = () => {
  return (
    <>
      <Typography
        display={"flex"}
        sx={{ mr: 1, alignItems: "center", fontWeight: 600, fontSize: "16px" }}
      >
        Ask S<span style={{ color: "rgb(221,122,68)" }}>A</span>kh
        <span style={{ color: "rgb(221,122,68)" }}>I</span>{" "}
        <span
          style={{
            backgroundColor: "#0DB678",
            borderRadius: "4px",
            paddingInline: "4px",
            color: "#000000",
            fontSize: "12px",
            marginLeft: "8px",
          }}
        >
          New
        </span>
      </Typography>
    </>
  );
};

export enum SideNavItems {
  Home = "Home",
  API = "API",
  TopAPI = "Top API",
  PaymentHistory = "Payment History",
  UsageStatistics = "UsageStatistics",
  SDK = "SDK",
  Referrals = "Referrals",
  FAQs = "FAQs",
  Parser = "Parser",
  Details = "Details",
  Analytics = "Analytics",
  GraphQL = "GraphQL",
  DataExplorer = "DataExplorer",
  DynamicAPI = "Dynamic API",
  Notifications = "Notifications",
  Transactions = "Transactions",
  Monitor = "Monitor",
  Dashboards = "Dashboards",
  Sakhi = "Sakhi",
  SakhiQuestions = "SakhiQuestions",
  SakhiDashboards = "SakhiDashboards",
  Academy = "Academy",
  FeatureRequest = "FeatureRequest",
  ApiDocumentation = "ApiDocumentation",
  ParserDocumentation = "ParserDocumentation",
  CustomerSupport = "CustomerSupport",
  MyPlan = "MyPlan",
  Account = "Account",
  Feedback = "Feedback",
  None = "",
}

export enum LinkType {
  INTERNAL,
  EXTERNAL,
}

export type ParserSubMenuLinkGenerator = (parserId: string) => string;

export interface SubMenu {
  label: any;
  icon: any;
  value: SideNavItems;
  link: (data?: any) => string;
  linkType: LinkType;
  mixpanelEvent: string;
  whitelisted_access?: boolean;
}

export interface NavBarItem extends SubMenu {
  subMenu: SubMenu[];
}

const navBarItems: Array<NavBarItem> = [
  {
    label: "API",
    icon: home,
    value: SideNavItems.API,
    link: () => "/",
    linkType: LinkType.INTERNAL,
    mixpanelEvent: MixpanelEvents.Home,
    subMenu: [
      {
        label: "Most Used APIs",
        icon: api,
        value: SideNavItems.TopAPI,
        link: () => "/top-api",
        linkType: LinkType.INTERNAL,
        mixpanelEvent: MixpanelEvents.API,
      },
      {
        label: "Documentation",
        icon: folder,
        value: SideNavItems.ApiDocumentation,
        link: () => "/documentation",
        linkType: LinkType.INTERNAL,
        mixpanelEvent: MixpanelEvents.APIDocumentation,
      },
      {
        label: "SDK Setup",
        icon: sdk,
        value: SideNavItems.SDK,
        link: () => "/sdk/ts",
        linkType: LinkType.INTERNAL,
        mixpanelEvent: MixpanelEvents.SDK,
      },
    ],
  },
  {
    label: <Indexer />,
    icon: indexer,
    value: SideNavItems.Parser,
    link: () => "/parser",
    linkType: LinkType.INTERNAL,
    mixpanelEvent: MixpanelEvents.Parser,
    subMenu: [
      // {
      //   label: <Sakhi />,
      //   icon: sakhi,
      //   value: SideNavItems.SakhiQuestions,
      //   link: toSakhiQuestions,
      //   linkType: LinkType.INTERNAL,
      //   mixpanelEvent: MixpanelEvents.SakhiQuestions,
      //   whitelisted_access: true,
      // },
      // {
      //   label: "Dashboards",
      //   icon: dashboards,
      //   value: SideNavItems.SakhiDashboards,
      //   link: toSakhiDashboards,
      //   linkType: LinkType.INTERNAL,
      //   mixpanelEvent: MixpanelEvents.SakhiDashboards,
      //   whitelisted_access: true,
      // },
      {
        label: "Overview",
        icon: details,
        value: SideNavItems.Details,
        link: toParserOverview,
        linkType: LinkType.INTERNAL,
        mixpanelEvent: MixpanelEvents.ViewDetails,
      },
      {
        label: "Transactions",
        icon: transactions,
        value: SideNavItems.Transactions,
        link: toContractTransactions,
        linkType: LinkType.INTERNAL,
        mixpanelEvent: MixpanelEvents.ParserTransactions,
      },
      {
        label: "Data Explorer",
        icon: metabaseData,
        value: SideNavItems.DataExplorer,
        link: toParserDataExplorer,
        linkType: LinkType.INTERNAL,
        mixpanelEvent: MixpanelEvents.DataExplorer,
      },
      {
        label: "Dynamic API",
        icon: dynamicAPI,
        value: SideNavItems.DynamicAPI,
        link: toParserDynamicAPI,
        linkType: LinkType.INTERNAL,
        mixpanelEvent: MixpanelEvents.DynamicAPI,
      },
      {
        label: "GraphQL",
        icon: graphQL,
        value: SideNavItems.GraphQL,
        link: toParserGraphQLPlayground,
        linkType: LinkType.INTERNAL,
        mixpanelEvent: MixpanelEvents.GraphQLPlayground,
      },
      {
        label: "Analytics",
        icon: analytics,
        value: SideNavItems.Analytics,
        link: toParserStatistics,
        linkType: LinkType.INTERNAL,
        mixpanelEvent: MixpanelEvents.Analytics,
      },
      {
        label: "Notifications",
        icon: notifications,
        value: SideNavItems.Notifications,
        link: toParserNotifications,
        linkType: LinkType.INTERNAL,
        mixpanelEvent: MixpanelEvents.Notifications,
      },
      {
        label: "Monitor",
        icon: monitor,
        value: SideNavItems.Monitor,
        link: toParserMonitor,
        linkType: LinkType.INTERNAL,
        mixpanelEvent: MixpanelEvents.Monitor,
      },
      {
        label: "Documentation",
        icon: folder,
        value: SideNavItems.ParserDocumentation,
        link: () => "https://docs.unmarshal.io/reference/what-is-unmarshal-parser",
        linkType: LinkType.EXTERNAL,
        mixpanelEvent: MixpanelEvents.ParserDocumentation,
      },
    ],
  },
  {
    label: "Academy",
    icon: academy,
    value: SideNavItems.Academy,
    link: () => "/academy",
    linkType: LinkType.INTERNAL,
    mixpanelEvent: MixpanelEvents.Academy,
    subMenu: [],
  },
  {
    label: "Account",
    icon: account,
    value: SideNavItems.Account,
    link: () => "/plan",
    linkType: LinkType.INTERNAL,
    mixpanelEvent: MixpanelEvents.Account,
    subMenu: [
      {
        label: "Usage statistics",
        icon: statistics,
        value: SideNavItems.UsageStatistics,
        link: () => "/statistics",
        linkType: LinkType.INTERNAL,
        mixpanelEvent: MixpanelEvents.UsageStatistics,
      },
    ],
  },
];

export const appBarItems: Array<NavBarItem> = [
  {
    label: "Payment History",
    icon: payment,
    value: SideNavItems.PaymentHistory,
    link: () => "/payment-history",
    linkType: LinkType.INTERNAL,
    mixpanelEvent: MixpanelEvents.PaymentHistory,
    subMenu: [],
  },
  {
    label: "Refer and earn",
    icon: currencyDollar,
    value: SideNavItems.Referrals,
    link: () => "/referrals",
    linkType: LinkType.INTERNAL,
    mixpanelEvent: MixpanelEvents.ReferAndEarn,
    subMenu: [],
  },
  {
    label: "Feature request",
    icon: plus,
    value: SideNavItems.FeatureRequest,
    link: () => "https://governance.unmarshal.io/",
    linkType: LinkType.EXTERNAL,
    mixpanelEvent: MixpanelEvents.FeatureRequest,
    subMenu: [],
  },
  {
    label: "Customer support",
    icon: support,
    value: SideNavItems.CustomerSupport,
    link: () => CONTACT_US_MAIL_TO_LINK,
    linkType: LinkType.EXTERNAL,
    mixpanelEvent: MixpanelEvents.CustomerSupport,
    subMenu: [],
  },
  {
    label: "FAQs",
    icon: chat,
    value: SideNavItems.FAQs,
    link: () => "/faqs",
    linkType: LinkType.INTERNAL,
    mixpanelEvent: MixpanelEvents.FAQ,
    subMenu: [],
  },
];

const defaultFunction = () => "";

export const getNavbarItemLink = (parent: SideNavItems, child?: SideNavItems) => {
  const mainItem = find(navBarItems, { value: parent });
  if (!child) return mainItem?.link || defaultFunction;
  return find(mainItem?.subMenu, { value: child })?.link || defaultFunction;
};

export default navBarItems;

import React, { useEffect } from "react";
import { Controller, SubmitHandler, useForm } from "react-hook-form";
import { Alert, Stack, TextField } from "@mui/material";
import { AbiFormValidation } from "./Validations";
import StepsController from "./StepsController";
import { AbiFormValues } from "./types";
import useNewIndexerFormValues, { useContractDetails } from "./FormStateHandler";
import useRestClient, { GetOptions } from "../../common/rest-client/RestClient";
import { default as MuiSkeleton } from "@mui/material/Skeleton/Skeleton";
import { toLower } from "lodash";

export const useAsyncRestClient = () => {
  const { getWithoutAuthorization } = useRestClient();

  const get = (url: string, options: GetOptions = {}) => {
    return new Promise((resolve, reject) => {
      getWithoutAuthorization(url, {
        onSuccess: ({ data }) => {
          resolve(data);
        },
        onError: (error) => reject(error),
        ...options,
      });
    });
  };

  return { get };
};

const Form = () => {
  const {
    updateAbiForm,
    resetEventsFunctionsAndPlugins,
    indexerFormValue: { contractAddress, abi, isProxy, proxyImplementationContract },
  } = useNewIndexerFormValues();

  const methods = useForm<AbiFormValues>({
    defaultValues: {
      abi,
      isProxy,
      proxyImplementationContract,
    },
    resolver: AbiFormValidation,
  });
  const { handleSubmit } = methods;

  useEffect(() => {
    methods.setValue("abi", abi);
  }, [abi]);

  const { isFailedToFetchABI } = useContractDetails();
  const onSubmit: SubmitHandler<AbiFormValues> = (value) => updateAbiForm(value);

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Stack spacing={3}>
        {isProxy && (
          <Alert severity="warning">
            This looks like a proxy contract. The parser might not index the contract as intended.{" "}
            {toLower(proxyImplementationContract) != toLower(contractAddress) ? (
              <>
                ABI is replaced by that of the implementation contract present on{" "}
                <span style={{ color: "orange" }}>{proxyImplementationContract}</span>
              </>
            ) : (
              <>Please provide an implementation ABI for creating the parser</>
            )}
          </Alert>
        )}
        {isFailedToFetchABI && (
          <Alert severity="warning">
            Couldn't fetch ABI for this contract. Please enter ABI manually
          </Alert>
        )}
        <Controller
          control={methods.control}
          render={({ field: { onChange, name, value, onBlur }, fieldState }) => (
            <TextField
              fullWidth
              autoComplete={"off"}
              multiline
              rows={5}
              name={name}
              value={value}
              onBlur={onBlur}
              onChange={(e) => {
                onChange(e?.target?.value);
                resetEventsFunctionsAndPlugins();
              }}
              label="Please enter the abi"
              helperText={fieldState?.error?.message}
              error={!!fieldState?.error}
            />
          )}
          name={"abi"}
        />
        <StepsController />
      </Stack>
    </form>
  );
};

const AbiForm = () => {
  const { isFetchingAbi } = useNewIndexerFormValues();

  if (isFetchingAbi) {
    return (
      <Stack spacing={1}>
        <MuiSkeleton variant={"rectangular"} width={200} />
        <MuiSkeleton variant={"rectangular"} height={100} />
      </Stack>
    );
  }

  return <Form />;
};

export default AbiForm;

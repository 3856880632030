import React, { useMemo } from "react";
import { APIData } from "../../common/rest-client/types";
import { Route, Routes, useParams } from "react-router-dom";
import useIndexerDetailsByID, { ExtendedIndexerDetails } from "../IndexerDetailsClient";
import FunctionOrEventDashboard from "./FunctionOrEventDashboard";
import ContractDashboard from "./ContractDashboard";
import "graphiql/graphiql.min.css";
import "../graphQL/GraphqlRoot.css";
import { IndexerType } from "../IndexerClient";

export interface StatisticsProps {
  indexerId: string;
  indexerDetails: APIData<ExtendedIndexerDetails>;
}

const Statistics = () => {
  const { indexerDetails } = useIndexerDetailsByID();
  const { id } = useParams();
  const indexerID = id || "";
  return useMemo(
    () => (
      <>
        <Routes>
          <Route path="/*" element={<ContractDashboard />} />

          <Route
            path="/analytics"
            element={
              indexerDetails?.data?.indexer_type !== IndexerType.SourceOnly ? (
                <FunctionOrEventDashboard />
              ) : (
                <></>
              )
            }
          />
        </Routes>
      </>
    ),
    [indexerID, indexerDetails.data?.contract_address]
  );
};

export default Statistics;

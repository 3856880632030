import { getUnmarshalChainName, indexerConfig } from "../../../config";
import useRestClient from "../../common/rest-client/RestClient";
import { useNavigate } from "react-router-dom";
import { toParserOverview } from "../../common/Routes";
import { toast } from "react-toastify";
import Mixpanel, { MixpanelEvents } from "../../../MixpanelConfig";
import useUser from "../../user/useUser";
import useIndexerDetails from "../IndexerClient";

interface CreateParserResponse {
  auto_generation_status: string;
  auto_generated_indexer_id: string;
  type?: string;
}

interface SuggestedDatabaseName {
  suggested_name: string;
}

interface Constants {
  constant_type: string;
  value: Array<string>;
}

const useParserClient = () => {
  const navigate = useNavigate();
  const { post, get } = useRestClient();
  const { getAllIndexersByUserID, getIndexersByStatus } = useIndexerDetails();

  const createIndexer = (data: any, onRequestComplete: () => void, resetForm: () => void) => {
    post(indexerConfig.indexerGenerationEndPoint, {
      data,
      onSuccess({ data }: { data: CreateParserResponse }) {
        Mixpanel.track(MixpanelEvents.ParserCreation);
        toast.success("Request submitted successfully! Parser is being processed");
        getAllIndexersByUserID();
        getIndexersByStatus("Running");
        resetForm();
        navigate(toParserOverview(data.auto_generated_indexer_id));
      },
      onError({ response }) {
        Mixpanel.track(MixpanelEvents.FailedParser, { reason: response?.data?.message });
        toast.error(`${response?.data?.message}`);
      },
      finally: onRequestComplete,
    });
  };

  const createParentChildIndexer = (
    data: any,
    onRequestComplete: () => void,
    resetParentForm: () => void,
    resetChildForm: () => void
  ) => {
    post(indexerConfig.parentChildParserGenerationEndpoint, {
      data,
      onSuccess({ data }: { data: Array<CreateParserResponse> }) {
        Mixpanel.track(MixpanelEvents.ParentChildParserCreation);
        toast.success("Request submitted successfully! Parsers are being processed");
        getIndexersByStatus("Running");
        getAllIndexersByUserID();
        resetParentForm();
        resetChildForm();
        navigate(toParserOverview(data[0].auto_generated_indexer_id));
      },
      onError({ response }) {
        Mixpanel.track(MixpanelEvents.FailedParser, { reason: response?.data?.message });
        toast.error(`${response?.data?.message}`);
      },
      finally: onRequestComplete,
    });
  };

  const getSuggestedDatabaseName = (
    parserName: string,
    onComplete: (value: SuggestedDatabaseName) => void
  ) => {
    get(indexerConfig.indexerSuggestedDBNameEndpoint, {
      params: {
        name: parserName,
      },
      onSuccess: ({ data }: { data: SuggestedDatabaseName }) => onComplete(data),
    });
  };

  const getExistingParserSchemaNames = (onComplete: (value: Array<string>) => void) => {
    get(indexerConfig.existingSchemaNamesEndpoint, {
      onSuccess: ({ data }: { data: string[] }) => {
        onComplete(data);
      },
    });
  };

  const getSupportedChains = (onComplete: (value: Array<string>) => void) => {
    get(indexerConfig.fetchConstantsEndpoint + "/supported_chains", {
      onSuccess: ({ data }: { data: Constants }) => onComplete(data.value),
    });
  };

  return {
    createIndexer,
    createParentChildIndexer,
    getSuggestedDatabaseName,
    getExistingParserSchemaNames,
    getSupportedChains,
  };
};

export default useParserClient;

export const useSyncContractMetrics = () => {
  const { apiKey } = useUser();
  const { getFromUnmarshalApi } = useRestClient();
  return (chain: string, contractAddress: string) => {
    getFromUnmarshalApi(
      apiKey,
      `/v1/${getUnmarshalChainName(chain)}/contract-metrics/${contractAddress}/sync`
    );
  };
};

import React, { useEffect } from "react";
import useDialogState from "../common/DialogState";
import { atom, useRecoilState } from "recoil";
import Button from "@mui/material/Button";
import Stack from "@mui/material/Stack";
import TextField from "@mui/material/TextField";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import { useForm } from "react-hook-form";
import LoadingButton from "@mui/lab/LoadingButton";
import useSakhi from "./SakhiClient";
import { toast } from "react-toastify";
import APIDataLoader from "../common/apiDataLoader/APIDataLoader";
import Skeleton from "@mui/material/Skeleton";
import { Autocomplete } from "@mui/material";

const isAddToDashboardFormDialogIsOpen = atom({
  key: "IS_ADD_TO_DASHBOARD_FORM_DIALOG_IS_OPEN",
  default: false,
});

const analyticsTypeAtom = atom({
  key: "ANALYTICS_TYPE_ATOM",
  default: "",
});

export const useAddToDashboardDialog = () => {
  const dialogState = useDialogState(isAddToDashboardFormDialogIsOpen);
  const [, analyticsTypeSetter] = useRecoilState(analyticsTypeAtom);

  return {
    ...dialogState,
    openDialog: (analyticsType: string) => {
      analyticsTypeSetter(analyticsType), dialogState.openDialog();
    },
  };
};

interface FormData {
  dashboardName: string;
}

interface AddToDashboardProps {
  question: string;
}

const FromLoadingIndicator = () => (
  <DialogContent>
    <Stack spacing={3}>
      <Skeleton variant={"text"} width={250} />
      <Skeleton variant={"rectangular"} width={"100%"} />
      <DialogActions>
        <Stack direction={"row"} justifyContent={"space-between"}>
          <Skeleton variant={"rectangular"} width={50} />
          <Skeleton variant={"rectangular"} width={50} />
        </Stack>
      </DialogActions>
    </Stack>
  </DialogContent>
);

const Form = ({ question }: AddToDashboardProps) => {
  const { sakhiResponse, getDashboards, dashboards } = useSakhi();
  const { closeDialog } = useAddToDashboardDialog();
  const { addToDashboard } = useSakhi();
  const [analyticsType] = useRecoilState(analyticsTypeAtom);
  const {
    register,
    handleSubmit,
    formState: { isSubmitting, errors },
  } = useForm<FormData>({ defaultValues: { dashboardName: "" } });

  useEffect(() => {
    getDashboards();
  }, []);

  const onSubmit = handleSubmit((data) =>
    addToDashboard({
      dashboard_name: data.dashboardName,
      query: sakhiResponse.data?.query_string || "",
      graph_type: analyticsType,
      metadata: {},
      question,
    })
      .then(() => {
        toast.success("Successfully added to dashboard");
        closeDialog();
      })
      .catch((error) => {
        return toast.error(`${error.response?.data?.message}` || `Failed to add to dashboard`);
      })
  );

  return (
    <APIDataLoader {...dashboards} loadingIndicator={<FromLoadingIndicator />} hasError={false}>
      <form onSubmit={onSubmit}>
        <DialogTitle>Add to Dashboard</DialogTitle>
        <DialogContent>
          <Autocomplete
            freeSolo
            options={(dashboards.data || []).map(({ name }) => name)}
            renderInput={(params) => (
              <TextField
                {...params}
                autoFocus
                margin="dense"
                label="Select or Enter a new dashboard name"
                fullWidth
                variant="outlined"
                type="text"
                {...register("dashboardName", { required: true })}
                helperText={errors?.dashboardName?.message}
                error={!!errors?.dashboardName}
              />
            )}
          />
        </DialogContent>
        <DialogActions>
          <Stack direction={"row"} justifyContent={"space-between"}>
            <Button onClick={closeDialog} color={"secondary"}>
              Cancel
            </Button>
            <LoadingButton loading={isSubmitting} type={"submit"} variant={"text"}>
              Add
            </LoadingButton>
          </Stack>
        </DialogActions>
      </form>
    </APIDataLoader>
  );
};

const AddToDashboard = ({ question }: AddToDashboardProps) => {
  const { isDialogOpen, closeDialog } = useAddToDashboardDialog();

  return (
    <Dialog open={isDialogOpen} onClose={closeDialog} maxWidth={"sm"} fullWidth>
      <Form question={question} />
    </Dialog>
  );
};

export default AddToDashboard;

import { indexerConfig } from "../../../config";
import useRestClient from "../../common/rest-client/RestClient";
import { toast } from "react-toastify";
import { atom, useRecoilState, useResetRecoilState } from "recoil";
import DefaultAPIDataValues, { APIData } from "../../common/rest-client/types";
import useGetRequest from "../../common/rest-client/get";

interface Subscribe {
  name: string;
  schema: string;
  events: string[];
  methods: string[];
  webhook_url: string;
  from_block: number;
}

export interface Subscription {
  id: number;
  subscription_name: string;
  parser_id: number;
  schema_name: string;
  webhook_url: string;
  enabled: boolean;
  last_event_block: number;
  last_method_block: number;
  tables: Table[];
}

export interface Table {
  Table: string;
  SyncType: string;
}

const subscriptionsAtom = atom<APIData<Array<Subscription>>>({
  key: "INDEXER_SUBSCRIPTIONS",
  default: DefaultAPIDataValues,
});

const useNotificationsClient = () => {
  const { post, deleteRequest } = useRestClient();
  const [subscriptions, updateSubscriptions] = useRecoilState(subscriptionsAtom);

  const resetSubscriptions = useResetRecoilState(subscriptionsAtom);

  const get = useGetRequest();

  const getSubscriptions = (indexerID: string) => {
    get<Array<Subscription>>(updateSubscriptions, indexerConfig.getSubscriptions(indexerID));
  };

  const deleteSubscription = (subId: string, indexerID: string) => {
    deleteRequest(indexerConfig.deleteSubscription(subId), {
      onSuccess() {
        toast.success("Successfully deleted!");
        getSubscriptions(indexerID);
      },
    });
  };

  const createSubscription = (
    indexerID: string,
    data: Subscribe,
    onRequestComplete: () => void,
    resetForm: () => void
  ) => {
    post(indexerConfig.subscribeNotifications(indexerID), {
      data,
      onSuccess() {
        toast.success("Subscribed successfully!");
        resetForm();
        getSubscriptions(indexerID);
      },
      onError({ response }) {
        toast.error(`${response?.data?.message}`);
      },
      finally: onRequestComplete,
    });
  };

  return {
    deleteSubscription,
    resetSubscriptions,
    getSubscriptions,
    createSubscription,
    subscriptions,
  };
};

export default useNotificationsClient;

import React, { useMemo } from "react";
import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";
import { CountOverTime } from "../details/types";
import LoadingIndicator, { ErrorIndicator } from "./LoadingIndicator";
import { isEmpty } from "lodash";

interface GraphProps {
  isLoading: boolean;
  hasError: boolean;
  data: Array<CountOverTime>;
  xAxisLabelFormatter?: (value: any) => any;
}

const labelColor = "rgba(255,255,255,0.8)";

const Graph = ({ data, xAxisLabelFormatter, isLoading, hasError }: GraphProps) => {
  return useMemo(() => {
    if (isLoading) return <LoadingIndicator />;

    if (hasError || isEmpty(data)) return <ErrorIndicator />;

    const options = {
      chart: {
        type: "spline",
        backgroundColor: "rgba(27, 32, 35, 0.9)",
        height: 300,
      },
      title: {
        text: "",
      },
      yAxis: {
        gridLineColor: "rgba(255, 255, 255, 0.03)",
        labels: {
          style: {
            color: labelColor,
            fontSize: 14,
          },
          x: 0,
        },
        title: null,
      },
      xAxis: {
        lineColor: "rgba(255, 255, 255, 0.06)",
        categories: (data || []).map((value) => {
          const x = value.x || value?.date;
          return !!xAxisLabelFormatter ? xAxisLabelFormatter(x) : x;
        }),
        accessibility: {
          description: "Days",
        },
        labels: {
          step: 8,
          style: {
            color: labelColor,
            fontSize: 14,
          },
          x: 30,
        },
      },
      series: [
        {
          color: "#0DB678",
          showInLegend: false,
          name: "Count",
          data: (data || []).map((value) => value.y),
        },
      ],
    };

    return <HighchartsReact highcharts={Highcharts} options={options} />;
  }, [data, isLoading, hasError]);
};

export default Graph;

import React from "react";
import { Stack, TextField } from "@mui/material";
import { Controller, SubmitHandler, useForm } from "react-hook-form";
import StepsController from "./ChildParserStepsController";
import useNewChildParserFormValues from "./ChildParserFormStateHandler";
import { ChildAbiFormValues } from "../types";
import { ChildABIFormValidation, contractAddressValidation } from "../Validations";
import { chainDetailsMap } from "../../../../config";
import { useAsyncRestClient } from "../AbiForm";
import { toast } from "react-toastify";
import { atom, useRecoilState } from "recoil";
import useNewIndexerFormValues from "../FormStateHandler";
import { default as MuiSkeleton } from "@mui/material/Skeleton/Skeleton";

const fetchingChildABIFromExplorer = atom<boolean>({
  key: "FETCHING_CHILD_ABI_FROM_EXPLORER",
  default: false,
});

const getChildParserABI = () => {
  const [isFetchingDetails, setABIFetchingStatus] = useRecoilState(fetchingChildABIFromExplorer);
  const { get } = useAsyncRestClient();

  const getABIFromExplorer = (chain: string, contract: string) => {
    const chainDetails = chainDetailsMap.get(chain);
    const url = `${chainDetails?.explorer}/api`;
    return get(url, {
      params: {
        module: "contract",
        action: "getabi",
        address: contract,
        apikey: chainDetails?.api_key,
      },
    }).then(({ result, message }: any) => {
      if (message !== "OK") {
        throw new Error("Status not ok");
      }
      return result;
    });
  };

  const fetchABI = (chain: string, address: string, onFetch: (abi: string) => void) => {
    setABIFetchingStatus(true);
    getABIFromExplorer(chain, address)
      .then((abi: any) => {
        onFetch(abi);
      })
      .catch(() => toast.warn("Failed to get contract details"))
      .finally(() => setABIFetchingStatus(false));
  };

  return { fetchABI, isFetchingDetails };
};

const ChildParserABIForm = () => {
  const {
    updateAbiForm,
    childParserFormValue: { abi, contractAddress },
    resetEventsFunctionsAndPlugins,
  } = useNewChildParserFormValues();
  const methods = useForm<ChildAbiFormValues>({
    defaultValues: { abi: abi, contractAddress: contractAddress },
    resolver: ChildABIFormValidation,
  });
  const { handleSubmit, setValue, control } = methods;
  const { isFetchingDetails, fetchABI } = getChildParserABI();
  const { indexerFormValue } = useNewIndexerFormValues();
  const chain = indexerFormValue.chain;

  const onSubmit: SubmitHandler<ChildAbiFormValues> = (value) => updateAbiForm(value);
  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Stack spacing={3}>
        <Controller
          control={control}
          render={({ field: { onChange, name, value, onBlur }, fieldState }) => (
            <TextField
              name={name}
              onBlur={onBlur}
              value={value}
              label={"Enter sample contract address*"}
              helperText={fieldState?.error?.message}
              error={!!fieldState?.error}
              onChange={(e) => {
                const address = e?.target?.value;
                onChange(address);
                contractAddressValidation.isValid(address).then((res) => {
                  if (res)
                    fetchABI(chain?.value || "", address, (abi) => {
                      setValue("abi", abi);
                      resetEventsFunctionsAndPlugins();
                    });
                });
              }}
            />
          )}
          name={"contractAddress"}
        />

        {isFetchingDetails ? (
          <Stack spacing={1}>
            <MuiSkeleton variant={"rectangular"} width={200} />
            <MuiSkeleton variant={"rectangular"} height={100} />
          </Stack>
        ) : (
          <Controller
            control={control}
            render={({ field: { onChange, name, value, onBlur }, fieldState }) => (
              <TextField
                fullWidth
                autoComplete={"off"}
                multiline
                rows={5}
                name={name}
                value={value}
                onBlur={onBlur}
                onChange={onChange}
                label="Enter child contract abi*"
                helperText={fieldState?.error?.message}
                error={!!fieldState?.error}
              />
            )}
            name={"abi"}
          />
        )}
        <StepsController />
      </Stack>
    </form>
  );
};

export default ChildParserABIForm;

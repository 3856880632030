import React, { useMemo, useState } from "react";
import { Controller, SubmitHandler, useFieldArray, useForm, UseFormWatch } from "react-hook-form";
import { Alert, Autocomplete, Divider, Link, Stack, TextField } from "@mui/material";
import StepsController from "./StepsController";
import { TokenPlugin, TokenPluginFormValues } from "./types";
import useNewIndexerFormValues, { doesFieldSupportTokenPlugin } from "./FormStateHandler";
import Button from "@mui/material/Button";
import { CommonAbi, Input } from "./ABIDetailsClient";
import { concat, findIndex, includes, some } from "lodash";
import { Control, UseFormSetValue } from "react-hook-form/dist/types/form";
import Typography from "@mui/material/Typography";
import Card from "@mui/material/Card";
import IconButton from "@mui/material/IconButton";
import DeleteIcon from "@mui/icons-material/Delete";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import { TokenPluginFormValidation } from "./Validations";
import { wrapMiddle } from "../../common/string/format";
import InfoIcon from "@mui/icons-material/Info";
import AddIcon from "@mui/icons-material/Add";
import { isTestnet } from "../../../config";

const Inputs = ({
  control,
  index: pluginIndex,
  watch,
  setValue,
}: {
  control: Control<TokenPluginFormValues>;
  watch: UseFormWatch<TokenPluginFormValues>;
  setValue: UseFormSetValue<TokenPluginFormValues>;
  index: number;
}) => {
  const inputFields = useFieldArray({
    control,
    name: `plugins.${pluginIndex}.selectedInputs`,
  });

  const [selectedInputFields, updateSelectedInputFields] = useState<Array<any>>([]);
  const {
    indexerFormValue: { contractAddress },
  } = useNewIndexerFormValues();
  const plugin: TokenPlugin = watch(`plugins.${pluginIndex}`);

  return (
    <Stack spacing={2}>
      <Divider />
      {inputFields.fields.map((field, index) => (
        <>
          <Stack direction={"row"} justifyContent={"end"} spacing={2}>
            <Controller
              key={field.id}
              control={control}
              name={`plugins.${pluginIndex}.selectedInputs.${index}.isTokenAddressIsInInputField`}
              render={({ field: { onChange, value } }) => (
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={value}
                      onChange={(e) => {
                        setValue(
                          `plugins.${pluginIndex}.selectedInputs.${index}.addressOrInputField`,
                          null
                        );
                        onChange(e.target.checked);
                      }}
                    />
                  }
                  labelPlacement={"end"}
                  label="Configure token address from input field"
                />
              )}
            />
            <div />
          </Stack>
          <Stack
            key={field.id}
            direction={"row"}
            justifyContent={"space-between"}
            alignItems={"center"}
            spacing={4}
          >
            <Typography>{index + 1}</Typography>
            <Controller
              key={field.id}
              control={control}
              name={`plugins.${pluginIndex}.selectedInputs.${index}.input`}
              render={({ field: { onChange, name, value, onBlur }, fieldState }) => (
                <Autocomplete
                  fullWidth
                  sx={{ maxWidth: 300 }}
                  onBlur={onBlur}
                  value={value}
                  options={plugin.entity?.inputs || []}
                  getOptionLabel={(option) => option.name}
                  filterOptions={(options: Array<Input>) =>
                    options.filter(
                      (input) =>
                        doesFieldSupportTokenPlugin(input.type) &&
                        !includes(selectedInputFields, input)
                    )
                  }
                  isOptionEqualToValue={(option, value) => option.name === value.name}
                  onChange={(e, newValue) => {
                    if (value) {
                      const index = findIndex(selectedInputFields, value);
                      selectedInputFields.splice(index, 1, newValue);
                    } else {
                      updateSelectedInputFields([...selectedInputFields, newValue]);
                    }
                    onChange(newValue);
                  }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      name={name}
                      label="Select token input field"
                      helperText={!!fieldState?.error ? "Input field is required!" : ""}
                      error={!!fieldState?.error}
                    />
                  )}
                />
              )}
            />
            <Controller
              key={field.id}
              control={control}
              name={`plugins.${pluginIndex}.selectedInputs.${index}.addressOrInputField`}
              render={({ field: { onChange, name, value, onBlur }, fieldState }) => {
                return !watch(
                  `plugins.${pluginIndex}.selectedInputs.${index}.isTokenAddressIsInInputField`
                ) ? (
                  <Autocomplete
                    fullWidth
                    onBlur={onBlur}
                    value={value as string}
                    freeSolo
                    groupBy={() => "Contract Address"}
                    options={[contractAddress]}
                    renderOption={(props, option) => (
                      <Typography {...props}>{wrapMiddle(option, 20, 6)}</Typography>
                    )}
                    disableClearable
                    getOptionLabel={(option) => option}
                    onChange={(e, value) => {
                      onChange(value);
                    }}
                    onInputChange={(e, value) => {
                      onChange(value);
                    }}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        name={name}
                        label={"Enter Token Address"}
                        helperText={!!fieldState?.error ? "Token address is invalid!" : ""}
                        error={!!fieldState?.error}
                      />
                    )}
                  />
                ) : (
                  <Autocomplete
                    fullWidth
                    onBlur={onBlur}
                    value={value as Input}
                    options={plugin.entity?.inputs || []}
                    getOptionLabel={(option) => option.name}
                    filterOptions={(options: Array<Input>) =>
                      options.filter((input) => input.type === "address")
                    }
                    isOptionEqualToValue={(option, value) => option.name === value.name}
                    onChange={(e, value) => {
                      onChange(value);
                    }}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        name={name}
                        label={"Select input field"}
                        helperText={!!fieldState?.error ? "Token input field is required!" : ""}
                        error={!!fieldState?.error}
                      />
                    )}
                  />
                );
              }}
            />
            <IconButton color="secondary" aria-label="add to shopping cart">
              <DeleteIcon
                onClick={() => {
                  const fields = watch(`plugins.${pluginIndex}.selectedInputs`);
                  updateSelectedInputFields(
                    selectedInputFields.filter((field) => field !== fields[index].input)
                  );
                  inputFields.remove(index);
                }}
              />
            </IconButton>
          </Stack>
          <Divider />
        </>
      ))}
      {inputFields.fields.length <
        (plugin.entity?.inputs || []).filter((input) => doesFieldSupportTokenPlugin(input.type))
          .length && (
        <Button
          onClick={() =>
            inputFields.append({
              input: null,
              addressOrInputField: null,
              isTokenAddressIsInInputField: false,
            })
          }
        >
          Add
        </Button>
      )}
    </Stack>
  );
};

const TokenPluginForm = () => {
  const {
    updateTokenPluginForm,
    indexerFormValue: { events, functions, plugins, chain },
  } = useNewIndexerFormValues();
  const methods = useForm<TokenPluginFormValues>({
    defaultValues: { plugins },
    resolver: TokenPluginFormValidation,
  });
  const { handleSubmit, control, watch, setValue } = methods;

  const [selectedPlugins, updateSelectedPlugins] = useState<Array<any>>([]);
  const eventPluginsFields = useFieldArray({
    control,
    name: "plugins",
  });

  const onSubmit: SubmitHandler<TokenPluginFormValues> = updateTokenPluginForm;

  const pluginOptions = useMemo(() => concat(events, functions), [events, functions]);

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Stack spacing={3}>
        {isTestnet(chain?.value || "") ? (
          <Typography>Plugins are not supported for testnet contracts</Typography>
        ) : (
          <Typography color={"info"}>
            Add price/token plugin to track price at the time of transaction{" "}
            <Link
              href={"https://docs.unmarshal.io/reference/what-is-unmarshal-parser"}
              target={"_blank"}
            >
              <IconButton>
                <InfoIcon />
              </IconButton>
            </Link>
          </Typography>
        )}
        {eventPluginsFields.fields.map((field, index) => (
          <Card key={field.id} variant={"outlined"}>
            <Stack spacing={3}>
              <Stack direction={"row"} spacing={2} justifyContent={"space-between"}>
                <Controller
                  key={field.id}
                  control={control}
                  name={`plugins.${index}.entity`}
                  render={({ field: { onChange, name, value, onBlur }, fieldState }) => (
                    <Autocomplete
                      fullWidth
                      onBlur={onBlur}
                      value={value}
                      options={pluginOptions}
                      groupBy={(option) => option.type}
                      getOptionLabel={(option) => option.signature}
                      filterOptions={(options: Array<CommonAbi>) =>
                        options.filter(
                          (option) =>
                            some(option.inputs, (input) =>
                              doesFieldSupportTokenPlugin(input.type)
                            ) && !includes(selectedPlugins, option)
                        )
                      }
                      isOptionEqualToValue={(option, value) => option.signature === value.signature}
                      onChange={(e, newValue) => {
                        if (value) {
                          const index = findIndex(selectedPlugins, value);
                          selectedPlugins.splice(index, 1, newValue);
                        } else {
                          updateSelectedPlugins([...selectedPlugins, newValue]);
                        }
                        setValue(`plugins.${index}.selectedInputs`, [
                          {
                            input: null,
                            addressOrInputField: null,
                            isTokenAddressIsInInputField: false,
                          },
                        ]);
                        onChange(newValue);
                      }}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          name={name}
                          label="Select event or function"
                          helperText={
                            fieldState?.error?.message || (fieldState?.error as any)?.value?.message
                          }
                          error={!!fieldState?.error}
                        />
                      )}
                    />
                  )}
                />
                <IconButton color="secondary" aria-label="add to shopping cart">
                  <DeleteIcon
                    onClick={() => {
                      const plugins = watch("plugins");
                      updateSelectedPlugins(
                        selectedPlugins.filter((plugin) => plugin !== plugins[index].entity)
                      );
                      eventPluginsFields.remove(index);
                    }}
                  />
                </IconButton>
              </Stack>
              <Controller
                key={field.id}
                control={control}
                name={`plugins.${index}.isTrackNativeTokenDeposit`}
                render={({ field: { onChange, value } }) => (
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={value}
                        onChange={(e) => {
                          onChange(e.target.checked);
                        }}
                      />
                    }
                    labelPlacement={"end"}
                    label="Track Native Token Deposit"
                  />
                )}
              />
              <Inputs control={control} watch={watch} index={index} setValue={setValue} />
            </Stack>
          </Card>
        ))}
        {!isTestnet(chain?.value || "") &&
          chain?.value !== "velas-mainnet" &&
          eventPluginsFields.fields.length <
            pluginOptions.filter((option) =>
              some(option.inputs, (input) => doesFieldSupportTokenPlugin(input.type))
            ).length && (
            <Button
              onClick={() =>
                eventPluginsFields.append({
                  entity: null,
                  selectedInputs: [],
                })
              }
            >
              <AddIcon />
              Add token and price plugin
            </Button>
          )}
        {(isTestnet(chain?.value || "") || chain?.value === "velas-mainnet") && (
          <Alert severity={"info"}>
            Token / Price plugins are currently not supported for Velas Mainnet, Rinkeby Testnet,
            BSC Testnet and Mumbai Testnet
          </Alert>
        )}
        <StepsController />
      </Stack>
    </form>
  );
};

export default TokenPluginForm;

import React, { useEffect } from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import useDialogState from "../../common/DialogState";
import { atom } from "recoil";
import CardContent from "@mui/material/CardContent";
import Mixpanel, { MixpanelEvents } from "../../../MixpanelConfig";
import { steps, useStepController } from "./add-child/ChildParserStepsController";
import Spacing from "../../common/Spacing";
import Stepper from "@mui/material/Stepper";
import Step from "@mui/material/Step";
import StepLabel from "@mui/material/StepLabel";
import Card from "@mui/material/Card";
import ChildParserABIForm from "./add-child/ChildParserABIForm";
import CloseButton from "../../common/CloseButton";
import ChildParserEventsAndFunctionForm from "./add-child/ChildParserEventsAndFunctionsForm";
import ChildParserTokenPluginForm from "./add-child/ChildParserTokenPluginsForm";
import ChildParserMetadataForm from "./add-child/ChildParserMetadataForm";
import StepsController from "./StepsController";
import { SubmitHandler, useForm } from "react-hook-form";
import { NewChildParserFormValues } from "./types";
import useNewIndexerFormValues from "./FormStateHandler";
import { Stack, Typography } from "@mui/material";
import { Delete, Edit, InfoRounded } from "@mui/icons-material";
import useNewChildParserFormValues, {
  useIsChildParser,
} from "./add-child/ChildParserFormStateHandler";
import ParentEventAndPluginForm from "./add-child/ParentEventAndPluginForm";

const isChildParserFormDialogIsOpen = atom({
  key: "IS_CHILD_PARSER_FORM_DIALOG_IS_OPEN",
  default: false,
});

export const useChildParserFormDialogOption = () => {
  const { isDialogOpen, toggleDialog, closeDialog } = useDialogState(isChildParserFormDialogIsOpen);

  return {
    isChildParserFormDialogIsOpen: isDialogOpen,
    openChildParserFormDialog: toggleDialog,
    closeChildParserFormDialog: closeDialog,
  };
};

const Steps = ({ step }: { step: number }) => {
  switch (step) {
    case 0:
      return <ParentEventAndPluginForm />;
    case 1:
      return <ChildParserABIForm />;
    case 2:
      return <ChildParserEventsAndFunctionForm />;
    case 3:
      return <ChildParserTokenPluginForm />;
    case 4:
      return <ChildParserMetadataForm />;
    default:
      return <>Default</>;
  }
};

export default function ChildParserForm() {
  const { isChildParserFormDialogIsOpen, openChildParserFormDialog, closeChildParserFormDialog } =
    useChildParserFormDialogOption();
  const { activeStep, resetSteps } = useStepController();
  const { addChildParser } = useNewIndexerFormValues();
  const { isChildParser, setIsChildParser } = useIsChildParser();
  const methods = useForm<NewChildParserFormValues>();
  const { handleSubmit } = methods;

  const { childParserFormValue, resetForm } = useNewChildParserFormValues();
  const onSubmit: SubmitHandler<NewChildParserFormValues> = addChildParser;
  useEffect(() => resetSteps, []);

  return (
    <div>
      <Typography sx={{ display: "flex", alignItems: "center", mb: 1 }}>
        <InfoRounded />
        You can skip this step if the contract you are indexing is not a factory contract
      </Typography>
      {isChildParser && (
        <>
          <Typography>Child Parser: {childParserFormValue?.name}</Typography>
          <Typography>
            Parent event responsible for generating child contract:{" "}
            {childParserFormValue?.eventName?.name}
          </Typography>
          <Typography>Child contract schema: {childParserFormValue?.schemaName}</Typography>
        </>
      )}
      <Stack direction={"row"} justifyContent={"flex-start"}>
        <Button variant="outlined" onClick={openChildParserFormDialog}>
          {!isChildParser ? "Add child parser" : <Edit />}
        </Button>
        {isChildParser && (
          <Button
            onClick={() => {
              setIsChildParser(false);
              resetForm();
              resetSteps();
              closeChildParserFormDialog();
            }}
          >
            <Delete />
          </Button>
        )}
      </Stack>
      <Spacing spacing={2} />
      <form onSubmit={handleSubmit(onSubmit)}>
        <StepsController />
      </form>
      <Dialog
        maxWidth={"xl"}
        open={isChildParserFormDialogIsOpen}
        onClose={closeChildParserFormDialog}
      >
        <DialogTitle>Child Parser</DialogTitle>
        <CloseButton
          onClose={() => {
            Mixpanel.track(MixpanelEvents.CloseChildParserForm, { stage: steps[activeStep] });
            if (!isChildParser) {
              resetForm();
            }
            resetSteps();
            closeChildParserFormDialog();
          }}
        />
        <DialogContent>
          <Card>
            <CardContent>
              <Spacing spacing={3} />
              <Stepper activeStep={activeStep} alternativeLabel>
                {steps.map((label, index) => {
                  return (
                    <Step key={label} completed={activeStep > index}>
                      <StepLabel>{label}</StepLabel>
                    </Step>
                  );
                })}
              </Stepper>
              <Spacing spacing={2} />
              <Card variant={"outlined"}>
                <CardContent>
                  <Steps step={activeStep} />
                </CardContent>
              </Card>
              <Spacing spacing={2} />
            </CardContent>
          </Card>
        </DialogContent>
      </Dialog>
    </div>
  );
}

import { atom, useRecoilState, useResetRecoilState } from "recoil";
import DefaultAPIDataValues, { APIData } from "../../common/rest-client/types";
import useUnmarshalApiGetRequest from "../../common/rest-client/unmarshalClient";
import useUser from "../../user/useUser";
import useRestClient from "../../common/rest-client/RestClient";
import { getUnmarshalChainName } from "../../../config";

export interface Sent {
  name: string;
  symbol: string;
  token_id: string;
  decimals: number;
  value: string;
  from?: string;
  logo_url?: string;
  to?: string;
}

export interface Received {
  name: string;
  symbol: string;
  token_id: string;
  decimals: number;
  value: string;
  from?: string;
  logo_url?: string;
  to?: string;
}

export interface Transaction {
  id: string;
  from: string;
  to: string;
  fee: string;
  date: number;
  status: string;
  type: string;
  value: string;
  description: string;
  sent: Sent[];
  received: Received[];
  chain_id?: string;
  block?: number;
  native_token_decimals?: number;
  transaction_id?: number;
  ttl?: number;
  target_chain_id?: number;
}

export interface PaginatedTransaction {
  page: number;
  total_pages: number;
  items_on_page: number;
  total_txs: number;
  transactions: Transaction[];
}

const ContractTransactionsAtom = atom<APIData<PaginatedTransaction>>({
  key: "CONTRACT_TRANSACTIONS",
  default: DefaultAPIDataValues,
});

const useContractTransactions = () => {
  const [contractTransactions, updateContractTransactions] =
    useRecoilState(ContractTransactionsAtom);
  const reset = useResetRecoilState(ContractTransactionsAtom);
  const fetcher = useUnmarshalApiGetRequest();
  const { getFromUnmarshalApi } = useRestClient();
  const { apiKey } = useUser();

  const getContractTransactions = (contractAddress: string, chain: string) => {
    const unmarshalChain = getUnmarshalChainName(chain);
    fetcher(
      apiKey,
      updateContractTransactions,
      `/v1/${unmarshalChain}/address/${contractAddress}/transactions`,
      {
        params: {
          pageSize: 20,
        },
      }
    );
  };

  const loadMore = (chain: string, contractAddress: string) => {
    console.log(chain);
    getFromUnmarshalApi(apiKey, `/v1/${chain}/address/${contractAddress}/transactions`, {
      onSuccess(response) {
        updateContractTransactions((currVal) => {
          const state = currVal;
          return {
            ...state,
            data: {
              ...response.data,
              transactions: [...state?.data?.transactions, ...response.data.transactions],
            },
          };
        });
      },
      params: {
        page: Math.ceil(contractTransactions.data?.transactions?.length / 20 + 1),
        pageSize: 20,
      },
    });
  };

  return { contractTransactions, getContractTransactions, loadMore, reset };
};

export default useContractTransactions;

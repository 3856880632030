import { atom, useRecoilState, useResetRecoilState, selector, useRecoilValue } from "recoil";
import DefaultAPIDataValues, { APIData } from "../common/rest-client/types";
import useGetRequest from "../common/rest-client/get";
import useRestClient from "../common/rest-client/RestClient";
import { toast } from "react-toastify";
import useIndexerDetails, { abiItem, IndexerDetails, IndexerType } from "./IndexerClient";
import { ComponentType, IndexedEventOrFunction } from "./details/types";
import { concat, map } from "lodash";
import Mixpanel, { MixpanelEvents } from "../../MixpanelConfig";

export interface SyncStatus {
  start_block: string;
  last_indexed_block: string;
  node_top_block: string;
}

export interface ExtendedIndexerDetails extends IndexerDetails {
  indexedEventsAndFunctions: Array<IndexedEventOrFunction>;
  allFunctions: Map<string, abiItem>;
  isDeployedAndMaintained: boolean;
  isSubgraph: boolean;
}

const IndexerDetailsClientAtom = atom<APIData<IndexerDetails>>({
  key: "Indexer_details",
  default: DefaultAPIDataValues,
});

function getIndexedEventAndFunction(indexer: APIData<IndexerDetails>) {
  if (!indexer.isSuccess) {
    return [];
  }
  const { events, functions } = indexer?.data?.additional_details || {};
  const eventsArray = map(events, (event) => ({ name: event.name, type: ComponentType.event }));
  const functionsArray = map(functions, (method) => ({
    name: method.name,
    type: ComponentType.function,
  }));
  return concat(eventsArray, functionsArray);
}

function getContractFunctionSignatures(indexer: APIData<IndexerDetails>) {
  if (!indexer.isSuccess) {
    return new Map();
  }
  const functions = indexer.data?.additional_details?.abi_details?.all_functions || [];
  return new Map(functions.map((method) => [method.hash, method]));
}

const indexerParsedDetails = selector<APIData<ExtendedIndexerDetails>>({
  key: "indexer_parsed_details",
  get: ({ get }) => {
    const indexer = get(IndexerDetailsClientAtom);
    return {
      ...indexer,
      data: {
        ...indexer.data,
        indexedEventsAndFunctions: getIndexedEventAndFunction(indexer),
        allFunctions: getContractFunctionSignatures(indexer),
        isDeployedAndMaintained: indexer.data?.indexer_type === IndexerType.DeployedAndMaintained,
        isSubgraph: indexer.data?.indexer_type === IndexerType.Subgraph,
      },
    };
  },
});

export const useUpdateIndexer = () => {
  const { deleteRequest, post } = useRestClient();
  const { getAllIndexersByUserID } = useIndexerDetails();
  const terminateIndexer = (indexerId: string, onComplete: () => void) => {
    deleteRequest(`/cis/v1/indexer/${indexerId}`, {
      onSuccess() {
        getAllIndexersByUserID();
        Mixpanel.track(MixpanelEvents.TerminateParser, { indexer_id: indexerId });
        toast.success("Successfully terminated the parser!");
        onComplete();
      },
      onError() {
        Mixpanel.track(MixpanelEvents.FailedParserTermination, { indexer_id: indexerId });
        toast.error("Failed to terminate parser!");
      },
    });
  };

  const pauseIndexer = (indexerId: string, onComplete: () => void) => {
    post(`/cis/v1/indexers/${indexerId}/pause`, {
      onSuccess() {
        getAllIndexersByUserID();
        Mixpanel.track(MixpanelEvents.PauseParser, { indexer_id: indexerId });
        toast.success("Successfully paused the parser!");
        onComplete();
      },
      onError() {
        Mixpanel.track(MixpanelEvents.FailedToPauseParser, { indexer_id: indexerId });
        toast.error("Failed to pause the parser!");
      },
    });
  };

  const resumeIndexer = (indexerId: string, onComplete: () => void) => {
    post(`/cis/v1/indexers/${indexerId}/resume`, {
      onSuccess() {
        getAllIndexersByUserID();
        Mixpanel.track(MixpanelEvents.ResumeParser, { indexer_id: indexerId });
        toast.success("Your parser will resume soon");
        onComplete();
      },
      onError() {
        Mixpanel.track(MixpanelEvents.FailedToResumeParser, { indexer_id: indexerId });
        toast.error("Failed to resume parser!");
      },
    });
  };

  const clearIndexerData = (indexerId: string, onComplete: () => void) => {
    deleteRequest(`/cis/v1/indexers/${indexerId}/clear-data`, {
      onSuccess() {
        getAllIndexersByUserID();
        Mixpanel.track(MixpanelEvents.ClearParserData, { indexer_id: indexerId });
        toast.success("Successfully cleared parser data!");
        onComplete();
      },
      onError() {
        Mixpanel.track(MixpanelEvents.FailedToPauseParser, { indexer_id: indexerId });
        toast.error("Failed to clear the parser data!");
      },
    });
  };

  return { terminateIndexer, pauseIndexer, resumeIndexer, clearIndexerData };
};

const useIndexerDetailsByID = () => {
  const [, updateIndexerByID] = useRecoilState(IndexerDetailsClientAtom);
  const indexerDetails = useRecoilValue(indexerParsedDetails);
  const reset = useResetRecoilState(IndexerDetailsClientAtom);

  const indexerFetcher = useGetRequest();

  const getIndexerById = (id: string) => {
    indexerFetcher<IndexerDetails>(updateIndexerByID, `/cis/v1/indexer/generation/${id}/details`);
  };

  return { indexerDetails, getIndexerById, reset };
};

export default useIndexerDetailsByID;

import { atom, useRecoilState } from "recoil";
import {
  AbiFormValues,
  ChildParserTokenFieldPlugin,
  ContractAndChainFormValues,
  EventsAndFunctionsFormValues,
  GeneratorTypeOptions,
  NativeTokenPlugin,
  NewChildParserFormValues,
  NewIndexerFormValues,
  NewIndexerRequest,
  NewParentChildParserRequest,
  ParserMetadataFormValues,
  TokenFieldPlugin,
  TokenPlugin,
  TokenPluginDetails,
  TokenPluginFormValues,
} from "./types";
import { useStepController } from "./StepsController";
import { toast } from "react-toastify";
import useParserClient, { useSyncContractMetrics } from "./ParserClient";
import useABIDetailsClient, { CommonAbi, Input } from "./ABIDetailsClient";
import { chainDetailsMap } from "../../../config";
import { max, min, toLower, toNumber } from "lodash";
import { useAsyncRestClient } from "./AbiForm";
import useNewChildParserFormValues, {
  useIsChildParser,
} from "./add-child/ChildParserFormStateHandler";

const defaultValues: NewIndexerFormValues = {
  chain: null,
  generatorType: GeneratorTypeOptions[0],
  contractAddress: "",
  abi: "",
  isProxy: false,
  proxyImplementationContract: "",
  contractStartBlock: null,
  events: [],
  functions: [],
  name: "",
  schemaName: "",
  startBlock: null,
  isSubmitting: false,
  plugins: [],
  hasSubgraphCode: false,
};

const formState = atom<NewIndexerFormValues>({
  key: "NEW_INDEXER_FORM",
  default: defaultValues,
});

function getPlugins(plugins: TokenPlugin[]) {
  const tokenColumnPlugin: Array<TokenFieldPlugin> = [];
  const tokenAddressPlugin: Array<TokenPluginDetails> = [];
  const nativeTokenDepositPlugin: Array<NativeTokenPlugin> = [];
  plugins.forEach((plugin) => {
    if (plugin.isTrackNativeTokenDeposit) {
      nativeTokenDepositPlugin.push({
        type: plugin.entity?.type || "",
        name: plugin.entity?.value || "",
        field_name: "!TxValue",
      });
    }
    plugin.selectedInputs.forEach((input) => {
      if (!input.isTokenAddressIsInInputField && typeof input.addressOrInputField === "string") {
        tokenAddressPlugin.push({
          name: plugin.entity?.value || "",
          inputs: {
            contract_address: input?.addressOrInputField || "",
          },
          field_name: input.input?.name || "",
          field_type: input.input?.type || "",
          type: plugin.entity?.type || "",
        });
      } else if ((input.addressOrInputField as Input)?.name) {
        tokenColumnPlugin.push({
          name: plugin.entity?.value || "",
          inputs: {
            field_name: (input.addressOrInputField as Input)?.name || "",
          },
          field_name: input.input?.name || "",
          field_type: input.input?.type || "",
          type: plugin.entity?.type || "",
        });
      }
    });
  });
  return { tokenColumnPlugin, tokenAddressPlugin, nativeTokenDepositPlugin };
}

const toNewParserRequest = (values: NewIndexerFormValues): NewIndexerRequest => {
  const { tokenColumnPlugin, tokenAddressPlugin, nativeTokenDepositPlugin } = getPlugins(
    values.plugins
  );
  return {
    abi: values.abi,
    plugins: [
      {
        plugin_type: "token",
        data: tokenAddressPlugin,
      },
      {
        plugin_type: "token_column",
        data: tokenColumnPlugin,
      },
      {
        plugin_type: "native_token_price",
        data: nativeTokenDepositPlugin,
      },
    ],
    methods: values.functions.map((method) => method.value),
    events: values.events.map((event) => event.value),
    display_name: values.name,
    type: values.generatorType.value,
    run_config: {
      chain: values.chain?.value as string,
      db_name: values.schemaName,
      start_block: values.startBlock as number,
      contract_address: values.contractAddress,
    },
  };
};

const toNewChildParserRequest = (
  parentValues: NewIndexerFormValues,
  childValues: NewChildParserFormValues
): NewParentChildParserRequest => {
  const tokenFromParentPlugin: Array<ChildParserTokenFieldPlugin> = [];
  const nativeTokenDepositPlugin: Array<NativeTokenPlugin> = [];
  childValues.plugins.forEach((plugin) => {
    if (plugin.isTrackNativeTokenDeposit) {
      nativeTokenDepositPlugin.push({
        type: plugin.entity?.type || "",
        name: plugin.entity?.value || "",
        field_name: "!TxValue",
      });
    }
    plugin.selectedInputs.forEach((input) => {
      tokenFromParentPlugin.push({
        name: plugin.entity?.value || "",
        inputs: {
          parent_component_type: "event",
          parent_component_name: childValues?.eventName?.name || "",
          parent_filter_column_name: childValues?.selectedInput?.input?.name || "",
          parent_token_column_name: (input.addressOrInputField as Input)?.name || "",
        },
        field_name: input.input?.name || "",
        field_type: input.input?.type || "",
        type: plugin.entity?.type || "",
      });
    });
  });
  return {
    parent: toNewParserRequest(parentValues),
    child: {
      abi: childValues.abi,
      plugins: [
        {
          plugin_type: "token_from_parent",
          data: tokenFromParentPlugin,
        },
        {
          plugin_type: "native_token_price",
          data: nativeTokenDepositPlugin,
        },
      ],
      contract_type: "child",
      parent_contract_info: {
        schema_name: parentValues?.schemaName,
        event_name: childValues?.eventName?.name || "",
        field_name: childValues?.selectedInput?.input?.name || "",
        field_type: childValues?.selectedInput?.input?.type || "",
        contract_address: parentValues?.contractAddress,
      },
      methods: childValues.functions.map((method) => method.value),
      events: childValues.events.map((event) => event.value),
      display_name: childValues.name,
      type: parentValues.generatorType.value,
      run_config: {
        chain: parentValues.chain?.value as string,
        db_name: childValues.schemaName,
        start_block: childValues.startBlock as number,
        contract_address: childValues.contractAddress,
      },
    },
  };
};

export function doesFieldSupportTokenPlugin(inputType: string) {
  const tokenRegex = new RegExp(`(u?)int(\d*)`);
  const matches = inputType.match(tokenRegex);
  if ((matches || []).length < 3) {
    return false;
  }
  switch (inputType) {
    case "uint8":
    case "int8":
    case "uint16":
    case "int16":
    case "uint32":
    case "int32":
    case "int64":
    case "uint64":
      return false;
    default:
      return true;
  }
}

const fetchingABIFRomExplorer = atom<boolean>({
  key: "FETCHING_ABI_FROM_EXPLORER",
  default: false,
});
const failedToFetchABIAtom = atom({
  key: "FAILED_TO_FETCH_ABI",
  default: false,
});
const failedToFetchStartBlockAtom = atom({
  key: "FAILED_TO_FETCH_START_BLOCK",
  default: false,
});
export const useContractDetails = () => {
  const [isFetchingDetails, setABIFetchingStatus] = useRecoilState(fetchingABIFRomExplorer);
  const [isFailedToFetchABI, setIsFailedToFetchABI] = useRecoilState(failedToFetchABIAtom);
  const [isFailedToFetchStartBlock, setIsFailedToFetchStartBlock] = useRecoilState(
    failedToFetchStartBlockAtom
  );
  const { get } = useAsyncRestClient();

  const getTxListInternal = (chain: string, contract: string) => {
    const chainDetails = chainDetailsMap.get(chain);
    const url = `${chainDetails?.explorer}/api`;
    return get(url, {
      params:
        chain === "fuse-mainnet"
          ? {
              module: "account",
              action: "txlistinternal",
              address: contract,
              // startblock: 0,
              // page: 1,
              // offset: 1,
              // sort: "asc",
              // apikey: chainDetails?.api_key,
            }
          : {
              module: "account",
              action: "txlistinternal",
              address: contract,
              startblock: 0,
              page: 1,
              offset: 1,
              sort: "asc",
              apikey: chainDetails?.api_key,
            },
    }).then(({ result, message }: any) => {
      if (message !== "OK") {
        return 0;
      }
      return toNumber(result[0]?.blockNumber);
    });
  };

  const getTxList = (chain: string, contract: string) => {
    const chainDetails = chainDetailsMap.get(chain);
    const url = `${chainDetails?.explorer}/api`;
    return get(url, {
      params:
        chain === "fuse-mainnet"
          ? {
              module: "account",
              action: "txlist",
              address: contract,
              // startblock: 0,
              // page: 1,
              // offset: 1,
              sort: "asc",
              // apikey: chainDetails?.api_key,
            }
          : {
              module: "account",
              action: "txlist",
              address: contract,
              startblock: 0,
              page: 1,
              offset: 1,
              sort: "asc",
              apikey: chainDetails?.api_key,
            },
    }).then(({ result, message }: any) => {
      if (message !== "OK") {
        return 0;
      }
      return toNumber(result[0]?.blockNumber);
    });
  };

  const getABIFromExplorer = (chain: string, contract: string) => {
    const chainDetails = chainDetailsMap.get(chain);
    const url = `${chainDetails?.explorer}/api`;
    return get(url, {
      params: {
        module: "contract",
        action: "getsourcecode",
        address: contract,
        apikey: chainDetails?.api_key,
      },
    }).then(({ result, message }: any) => {
      if (message !== "OK") {
        throw new Error("Status not ok");
      }
      return result[0];
    });
  };
  const getAllContractDetails = (
    chain: string,
    address: string,
    onFetchAbi: (abi: any) => void,
    onFetchStartBlock: (startBlock: number) => void
  ) => {
    setABIFetchingStatus(true);
    getABIFromExplorer(chain, address)
      .then((abi: any) => {
        if (abi.Proxy == "1" && toLower(abi.Implementation) != toLower(address)) {
          getABIFromExplorer(chain, abi.Implementation)
            .then((newAbi: any) => {
              abi.ABI = newAbi.ABI;
              setIsFailedToFetchABI(false);
            })
            .catch(() => {
              setIsFailedToFetchABI(true);
              toast.warn("Failed to fetch proxy implementations ABI");
              onFetchAbi("");
            })
            .finally(() => {
              onFetchAbi(abi);
              setABIFetchingStatus(false);
            });
        } else {
          onFetchAbi(abi);
        }
      })
      .catch(() => {
        setIsFailedToFetchABI(true);
        onFetchAbi("");
      })
      .finally(() => setABIFetchingStatus(false));

    Promise.allSettled([getTxList(chain, address), getTxListInternal(chain, address)])
      .then(([firstTxBlockNumber, firstInternalTxBlockNumber]: any) => {
        let startBlock = 0;
        if (firstTxBlockNumber.value > 0 && firstInternalTxBlockNumber.value > 0)
          startBlock = min([firstTxBlockNumber.value, firstInternalTxBlockNumber.value]);
        else startBlock = max([firstTxBlockNumber.value, firstInternalTxBlockNumber.value]);
        onFetchStartBlock(startBlock);
      })
      .catch(() => setIsFailedToFetchStartBlock(true))
      .finally(() => setABIFetchingStatus(false));
  };
  return {
    getABIFromExplorer,
    getAllContractDetails,
    setABIFetchingStatus,
    isFetchingDetails,
    isFailedToFetchABI,
    isFailedToFetchStartBlock,
    setIsFailedToFetchABI,
    setIsFailedToFetchStartBlock,
  };
};

const useNewIndexerFormValues = () => {
  const [indexerFormValue, updateIndexerFormValues] = useRecoilState(formState);
  const { handleNext } = useStepController();
  const { createIndexer, createParentChildIndexer } = useParserClient();
  const syncContractMetrics = useSyncContractMetrics();
  const {
    isFetchingDetails: isFetchingAbi,
    getAllContractDetails,
    setIsFailedToFetchStartBlock,
    setIsFailedToFetchABI,
  } = useContractDetails();
  const { getFunctionAndEventData } = useABIDetailsClient();
  const { isChildParser } = useIsChildParser();

  const { childParserFormValue, resetForm: resetChildParserForm } = useNewChildParserFormValues();
  const updateAbiForm = (values: AbiFormValues) => {
    updateIndexerFormValues((currentValue) => ({ ...currentValue, ...values }));
    getFunctionAndEventData(values.abi);
    handleNext();
  };

  const resetEventsFunctionsAndPlugins = () => {
    resetChildParserForm();
    updateIndexerFormValues((values) => ({ ...values, events: [], functions: [], plugins: [] }));
  };

  const updateChainAndContractForm = (values: ContractAndChainFormValues) => {
    const chain = values.chain?.value as string;
    const contractAddress = values.contractAddress;
    syncContractMetrics(chain, contractAddress);
    updateIndexerFormValues((currentValue) => ({ ...currentValue, ...values }));
    getAllContractDetails(
      chain,
      contractAddress,
      (abi) => {
        if (abi !== indexerFormValue.abi) {
          updateIndexerFormValues((values) => ({
            ...values,
            abi: abi.ABI,
            isProxy: abi.Proxy == "1" || abi.Proxy == "true",
            proxyImplementationContract: abi.Implementation,
          }));
          resetEventsFunctionsAndPlugins();
        }
      },
      (startBlock) => updateContractStartBlock(startBlock)
    );
    handleNext();
  };

  const updateHasSubgraph = (hasSubgraphCode: boolean) => {
    updateIndexerFormValues((values) => ({
      ...values,
      hasSubgraphCode,
    }));
    handleNext();
  };

  const updateEventsAndFunctionsForm = ({ events, functions }: EventsAndFunctionsFormValues) => {
    if (events.length + functions.length === 0) {
      toast.error("Please select at least one event or function.");
      return;
    }
    updateIndexerFormValues((currentValue) => ({ ...currentValue, events, functions }));
    handleNext();
  };
  const updateEventsForm = (events: Array<CommonAbi>) => {
    if (events.length === 0) {
      toast.error("Please select at least one event.");
      return;
    }
    updateIndexerFormValues((currentValue) => ({ ...currentValue, events }));
    handleNext();
  };

  const updateSubgraphFile = () => {
    handleNext();
  };

  const updateContractStartBlock = (startBlock: number) => {
    updateIndexerFormValues((currentValue) => ({
      ...currentValue,
      contractStartBlock: startBlock,
    }));
  };

  const updateTokenPluginForm = (values: TokenPluginFormValues) => {
    updateIndexerFormValues((currentValue) => ({ ...currentValue, ...values }));
    handleNext();
  };

  const setSubmittingFalse = () => {
    updateIndexerFormValues((currentValue) => ({
      ...currentValue,
      isSubmitting: false,
    }));
  };

  const resetForm = () => {
    updateIndexerFormValues(defaultValues);
    setIsFailedToFetchStartBlock(false);
    setIsFailedToFetchABI(false);
  };

  const addChildParser = () => {
    handleNext();
  };

  const updateParserMetadata = (values: ParserMetadataFormValues) => {
    updateIndexerFormValues((currentValue) => {
      const indexer = { ...currentValue, ...values, isSubmitting: true };
      if (isChildParser) {
        if ((indexer.startBlock || 0) > (childParserFormValue.startBlock || 0)) {
          toast.error("Child parser cannot have a start block smaller than parent parser");
          setSubmittingFalse();
        } else {
          createParentChildIndexer(
            toNewChildParserRequest(indexer, childParserFormValue),
            setSubmittingFalse,
            resetForm,
            resetChildParserForm
          );
        }
      } else {
        createIndexer(toNewParserRequest(indexer), setSubmittingFalse, resetForm);
      }
      return indexer;
    });
  };

  return {
    indexerFormValue,
    updateChainAndContractForm,
    updateAbiForm,
    resetEventsFunctionsAndPlugins,
    updateContractStartBlock,
    updateEventsAndFunctionsForm,
    updateTokenPluginForm,
    updateParserMetadata,
    addChildParser,
    isFetchingAbi,
    resetForm,
    updateHasSubgraph,
    updateEventsForm,
    updateSubgraphFile,
  };
};

export default useNewIndexerFormValues;

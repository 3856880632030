import { atom, useRecoilState, useResetRecoilState } from "recoil";
import DefaultAPIDataValues, { APIData } from "../common/rest-client/types";
import useGetRequest from "../common/rest-client/get";
import { indexerConfig } from "../../config";

export interface abiItem {
  name: string;
  signature: string;
  hash: string;
}

export enum IndexerType {
  CustomCode = "CustomCode",
  DeployedAndMaintained = "DeployedAndMaintained",
  SourceOnly = "SourceOnly",
  Subgraph = "Subgraph",
}

export interface PluginDetails {
  component_name: string;
  field_name: string;
  field_type: string;
  plugin_type: string;
  plugin_values: {
    contract_address?: string;
    field_name?: string;
    parent_component_name?: string;
    parent_component_type?: string;
    parent_filter_column_name?: string;
    parent_schema_name?: string;
    parent_table_name?: string;
    parent_token_column_name?: string;
  };
}

export interface Plugins {
  event_plugins: Array<PluginDetails>;
  method_plugins: Array<PluginDetails>;
}

export interface GraphiqlDetails {
  db_name: string;
  graphiql_url: string;
  graphql_url: string;
}

export interface SyncStatusDetails {
  start_block: string;
  last_indexed_block: string;
  node_top_block: string;
  sync_type: string;
}

export interface IndexerDetails {
  created_at: number;
  deploy_status: string;
  user_id: string;
  generated_indexer_id: string;
  description: string;
  indexer_name: string;
  download_link?: string;
  indexer_type: IndexerType;
  additional_details: {
    graphiql_details: GraphiqlDetails;
    db_name: string;
    description: string;
    events: abiItem[];
    functions: abiItem[];
    abi_details: {
      all_functions: abiItem[];
      all_events: abiItem[];
    };
    plugins: Plugins;
  };
  chain: string;
  contract_address: string;
  sync_status: SyncStatusDetails[];
  parent_auto_generator_id: string;
}

export interface IndexerArray {
  indexer_list: IndexerDetails[];
}

const IndexerByStatus = atom<APIData<IndexerArray>>({
  key: "INDEXER_DETAILS_BY_STATUS",
  default: DefaultAPIDataValues,
});

const IndexerByUserIDAtom = atom<APIData<IndexerArray>>({
  key: "INDEXER_DETAILS_BY_USER_ID",
  default: DefaultAPIDataValues,
});

const useIndexerDetails = () => {
  const [indexersByStatus, updateIndexersByStatus] = useRecoilState(IndexerByStatus);
  const reset = useResetRecoilState(IndexerByStatus);

  const indexerFetcher = useGetRequest();

  const [indexersByUserID, updateIndexersByUserID] = useRecoilState(IndexerByUserIDAtom);

  const resetAllIndexers = useResetRecoilState(IndexerByUserIDAtom);
  const getIndexersByStatus = (status: string = "") => {
    indexerFetcher<IndexerArray>(updateIndexersByStatus, indexerConfig.indexerListByUserEndpoint, {
      params: {
        status: status,
      },
    });
  };

  const fetcher = useGetRequest();
  const getAllIndexersByUserID = () => {
    fetcher<IndexerArray>(updateIndexersByUserID, indexerConfig.indexerListByUserEndpoint, {
      params: {
        status: "All",
      },
    });
  };

  return {
    indexersByUserID,
    indexersByStatus,
    getIndexersByStatus,
    getAllIndexersByUserID,
    reset,
    resetAllIndexers,
  };
};

export default useIndexerDetails;

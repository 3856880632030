import React, { useCallback, useEffect, useMemo, useState } from "react";
import { Controller, SubmitHandler, useForm } from "react-hook-form";
import { Autocomplete, debounce, Stack, TextField } from "@mui/material";
import StepsController from "./ChildParserStepsController";
import { ParserMetadataFormValues } from "../types";
import StartBlock from "./ChildParserStartBlock";
import Typography from "@mui/material/Typography";
import useParserClient from "../ParserClient";
import { InfoRounded } from "@mui/icons-material";
import { concat } from "lodash";
import useNewChildParserFormValues from "./ChildParserFormStateHandler";
import Spacing from "../../../common/Spacing";
import { ParserMetadataFormValidation } from "../Validations";
import useNewIndexerFormValues from "../FormStateHandler";

const ChildParserMetadataForm = () => {
  const {
    updateParserMetadata,
    childParserFormValue: { startBlock, name, schemaName },
  } = useNewChildParserFormValues();

  const {
    indexerFormValue: { contractStartBlock: parentStartBlock },
  } = useNewIndexerFormValues();
  const methods = useForm<ParserMetadataFormValues>({
    defaultValues: { name, schemaName, startBlock },
    resolver: ParserMetadataFormValidation,
  });
  const { handleSubmit, setValue, control, watch } = methods;
  const { getSuggestedDatabaseName, getExistingParserSchemaNames } = useParserClient();

  const [suggestedSchemaName, updateSuggestedSchemaName] = useState({
    type: "Suggested Name",
    value: "",
  });

  const [existingSchemaNames, updateExistingSchemaNames] = useState<Array<any>>([]);
  const parserName = watch("name");

  const getDatabaseName = useCallback(
    debounce(
      (parserName: string) =>
        getSuggestedDatabaseName(parserName, ({ suggested_name }) => {
          updateSuggestedSchemaName({ ...suggestedSchemaName, value: suggested_name });
        }),
      300
    ),
    []
  );

  const getExistingSchemas = useCallback(
    debounce(() =>
      getExistingParserSchemaNames((suggestedNames) => {
        const suggestedSchemaNames = Array.from(suggestedNames, (schema: string) => {
          return { type: "Existing Schemas", value: schema };
        });
        updateExistingSchemaNames(suggestedSchemaNames);
      })
    ),
    []
  );

  useEffect(() => {
    getExistingSchemas();
  }, []);

  useEffect(() => {
    if (!!parserName) getDatabaseName(parserName);
  }, [parserName]);

  const onSubmit: SubmitHandler<ParserMetadataFormValues> = updateParserMetadata;

  const schemaNameOptions = useMemo(
    () => concat(suggestedSchemaName, existingSchemaNames),
    [suggestedSchemaName, existingSchemaNames]
  );

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Stack spacing={3}>
        <StartBlock
          contractStartBlock={parentStartBlock || 0}
          setValue={setValue}
          control={control}
        />
        <Stack spacing={1}>
          <Typography fontSize={"18px"} fontWeight={600}>
            Name your parser
          </Typography>
          <Controller
            control={methods.control}
            name={"name"}
            render={({ field: { onChange, name, value, onBlur }, fieldState }) => (
              <TextField
                fullWidth
                name={name}
                value={value}
                label="Please enter parser name"
                onBlur={onBlur}
                onChange={onChange}
                helperText={fieldState?.error?.message}
                error={!!fieldState?.error}
              />
            )}
          />
        </Stack>
        <Stack spacing={1}>
          <Typography fontSize={"18px"} fontWeight={600}>
            Schema Name
          </Typography>
          <Stack direction={"row"} alignItems={"center"} sx={{ opacity: 0.5 }}>
            <InfoRounded fontSize={"small"} />
            &nbsp;
            <Typography>
              You could re-use a schema which you already created to simplify querying
            </Typography>
          </Stack>
          <Spacing spacing={1} />
          <Controller
            control={methods.control}
            name={"schemaName"}
            render={({ field: { onChange, name, value, onBlur }, fieldState }) => (
              <Autocomplete
                fullWidth
                onBlur={onBlur}
                value={value as string}
                freeSolo
                groupBy={(option) => option.type}
                options={schemaNameOptions}
                getOptionLabel={(option) => option.value || option}
                isOptionEqualToValue={(option, value) => option.value === value}
                filterOptions={(options) => options.filter((option) => option.value !== "")}
                onChange={(e, value) => {
                  onChange(value?.value);
                }}
                onInputChange={(e, value) => {
                  onChange(value);
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    name={name}
                    label="Enter schema name"
                    helperText={
                      fieldState?.error?.message || (fieldState?.error as any)?.value?.message
                    }
                    error={!!fieldState?.error}
                  />
                )}
              />
            )}
          />
        </Stack>
        <StepsController />
      </Stack>
    </form>
  );
};

export default ChildParserMetadataForm;

import {
  array,
  boolean,
  number,
  object,
  string,
  addMethod,
  MixedSchema,
  AnySchema,
  mixed,
} from "yup";
import { every } from "lodash";
import { yupResolver } from "@hookform/resolvers/yup";

export const contractAddressValidation = string()
  .required("Contract address is required!")
  .matches(/^0x[a-zA-Z0-9]{40}$/, "Please enter a valid Address");

const chainValidation = string().required("Chain is required!");

const runConfig = object({
  contract_address: contractAddressValidation,
  chain: chainValidation,
  start_block: number().required("Start block is required."),
  db_name: string()
    .required("Schema name is required.")
    .matches(
      new RegExp(`^[a-z_][a-z0-9_]{0,31}$`),
      "Parser name should start with alphabet or - and contain 3-15 characters. Can include " +
        " alphabets, numbers, space, - and _"
    ),
});

const abi = string()
  .required("ABI is required.")
  .test("check-is-valid-abi", "Please enter valid ABI in JSON format", (value) => {
    try {
      JSON.parse(value);
      return true;
    } catch (e) {
      return false;
    }
  });

const EventOrFunction = object({
  value: string().required(""),
});

const events = array().of(EventOrFunction);
const methods = array().of(EventOrFunction);
const plugins = array().of(
  object({
    plugin_type: string().required("Plugin type is required."),
    data: array().of(
      object({
        type: string().required("Token plugin type is required."),
        name: string().required("Token plugin name is required."),
        field_name: string().required("Token plugin field name is required."),
        field_type: string().required("Token plugin field type is required."),
        inputs: object({
          contract_address: string()
            .required("Token plugin token address is required.")
            .matches(/^0x[a-zA-Z0-9]{40}$/, "Please enter a valid Address"),
        }),
      })
    ),
  })
);
const displayName = string()
  .required("Display name is required.")
  .matches(new RegExp(`^[a-z0-9-]([\\s]?[a-z0-9-_]){2,24}$`, "i"), {
    message:
      "Parser name should start with alphabet" +
      " or - and contain 3-15 characters." +
      " Can include alphabets, numbers, space, - and _",
  });

export const isValidChainAndContractAddress = (chain: string, address: string) => {
  return Promise.all([
    chainValidation.isValid(chain),
    contractAddressValidation.isValid(address),
  ]).then((values) => every(values));
};

const sourceOnlyFormFieldValidations = {
  abi: abi,
  events: events,
  methods: methods,
  plugins: plugins,
  display_name: displayName,
};

export const validationSchemaSourceOnlyForm = object().shape(sourceOnlyFormFieldValidations);

export const validationSchemaDeployAndMaintainForm = object().shape({
  run_config: runConfig,
  ...sourceOnlyFormFieldValidations,
});

export const ChainAndContractFormValidation = yupResolver(
  object().shape({
    contractAddress: contractAddressValidation,
    chain: object().shape({
      label: string().required(),
      value: chainValidation,
    }),
    generatorType: object().shape({
      value: string().required("Generator type is required"),
    }),
  })
);

export const AbiFormValidation = yupResolver(
  object().shape({
    abi,
  })
);

export const ChildABIFormValidation = yupResolver(
  object().shape({
    contractAddress: contractAddressValidation,
    abi: abi,
  })
);

export const EventsAndFunctionsFormValidation = yupResolver(
  object().shape({
    events: events,
    functions: methods,
  })
);

export const EventsFormValidation = yupResolver(
  object().shape({
    events: events,
  })
);

addMethod(MixedSchema, "oneOfSchemas", function (schemas: AnySchema[]) {
  // eslint-disable-next-line no-invalid-this
  return this.test(
    "one-of-schemas",
    "Not all items in ${path} match one of the allowed schemas",
    (item) => schemas.some((schema) => schema.isValidSync(item, { strict: true }))
  );
});

const mixedSchema: any = mixed();
export const TokenPluginFormValidation = yupResolver(
  object().shape({
    plugins: array(
      object().shape({
        entity: object().shape({
          name: string().required(""),
        }),
        selectedInputs: array(
          object().shape({
            input: object().shape({
              name: string().required("Input field is required"),
            }),
            isTokenAddressIsInInputField: boolean(),
            addressOrInputField: mixedSchema.oneOfSchemas([
              string()
                .required("Token address is required")
                .matches(/^0x[a-zA-Z0-9]{40}$/, "Please enter a valid Address"),
              object().shape({
                name: string().required("Token field is required"),
              }),
            ]),
          })
        ),
      })
    ),
  })
);

export const ParserMetadataFormValidation = yupResolver(
  object().shape({
    startBlock: number().required("Start block is required."),
    name: string().required("Parser name is required."),
    schemaName: string()
      .required("Schema name is required.")
      .matches(
        new RegExp(`^[a-z_][a-z0-9_]{0,31}$`),
        "Parser name should start with alphabet or - and contain 3-15 characters. Can include " +
          " alphabets, numbers, space, - and _"
      ),
  })
);

export const ParserMetadataWithoutSchemaNameFormValidation = yupResolver(
  object().shape({
    startBlock: number().required("Start block is required."),
    name: string().required("Parser name is required."),
  })
);

export const ParentContractInfoFormValidation = yupResolver(
  object().shape({
    eventName: object().shape({
      name: string().required("Event is required"),
    }),
    selectedInput: object().shape({
      input: object().shape({
        name: string().required("Input field is required"),
      }),
      isTokenAddressIsInInputField: boolean(),
      addressOrInputField: mixedSchema.oneOfSchemas([
        string()
          .required("Token address is required")
          .matches(/^0x[a-zA-Z0-9]{40}$/, "Please enter a valid Address"),
        object().shape({
          name: string().required("Token field is required"),
        }),
      ]),
    }),
  })
);

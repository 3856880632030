import axios, { AxiosRequestConfig, AxiosResponse } from "axios";
import { useAuth } from "@aws-amplify/ui-react/internal";
import { endsWith } from "lodash";
import { doNothing } from "../types";

const UnmarshalDevAPIKey = process.env.REACT_APP_UNMARSHAL_API_DEV_API_KEY as string;
const env = process.env.REACT_APP_ENVIRONMENT as string;
const proxyPrefix = "/api";

interface RestClientOptions extends AxiosRequestConfig {
  beforeFetch?: () => void;
  onSuccess?: (response: AxiosResponse) => void;
  onError?: (error?: any) => void;
  finally?: () => void;
}

export type GetOptions = RestClientOptions;

export type PostOptions = RestClientOptions;

const exchange = (promise: Promise<AxiosResponse>, options: RestClientOptions) => {
  const onError = async (error: any) => {
    if (error?.response?.status === 401) {
      console.log("unauthorised request");
      // try {
      //   const cognitoUser = await Auth.currentAuthenticatedUser();
      //   const currentSession = await Auth.currentSession();
      //   cognitoUser.refreshSession(currentSession.getRefreshToken(), (err: any, session: any) => {
      //     const { idToken, refreshToken, accessToken } = session;
      //     // eslint-disable-next-line guard-for-in
      //     for (const key in localStorage) {
      //       if (endsWith(key, "idToken")) {
      //         localStorage.setItem(key, idToken);
      //       }
      //       if (endsWith(key, "accessToken")) {
      //         localStorage.setItem(key, accessToken);
      //       }
      //       if (endsWith(key, "refreshToken")) {
      //         localStorage.setItem(key, refreshToken);
      //       }
      //     }
      //   });
      // } catch (e) {
      //   console.log(e);
      // }
    }
    (options?.onError || doNothing)(error);
  };
  (options?.beforeFetch || doNothing)();
  promise
    .then(options?.onSuccess || doNothing)
    .catch(onError)
    .then(options?.finally || doNothing);
};

axios.defaults.headers.post["Content-Type"] = "application/json";
axios.defaults.headers.put["Content-Type"] = "application/json";

const useRestClient = () => {
  const auth = useAuth();

  const getAuthToken = () => {
    if (auth.isLoading) {
      for (const key in localStorage) {
        if (endsWith(key, "idToken")) {
          return localStorage.getItem(key) || "";
        }
      }
    }
    return auth?.user?.getSignInUserSession()?.getIdToken()?.getJwtToken() || "";
  };

  const get = (uri: string, options: GetOptions = {}) => {
    exchange(
      axios.get(proxyPrefix + uri, {
        ...options,
        headers: {
          Authorization: getAuthToken(),
          ...(options?.headers || {}),
        },
      }),
      options
    );
  };

  const getFromUnmarshalApi = (userApiKey: string, uri: string, options: GetOptions = {}) => {
    const isProd = env === "PRODUCTION";
    const authKey = isProd ? userApiKey : UnmarshalDevAPIKey;
    const url = isProd ? "https://api.unmarshal.com" : "https://api-test.cmd.unmarshal.com";
    exchange(
      axios.get(url + uri, {
        ...options,
        params: {
          // NOTE: Mocking api key for testing and development in dev environment
          auth_key: authKey,
          ...(options?.params || {}),
        },
      }),
      options
    );
  };

  const postFromUnmarshalApi = (
    userApiKey: string,
    uri: string,
    { data = {}, ...options }: PostOptions
  ) => {
    const isProd = env === "PRODUCTION";
    const url = isProd ? "https://api.unmarshal.com" : "https://api-test.cmd.unmarshal.com";
    exchange(
      axios.post(url + uri, data, {
        ...options,
        params: {
          // NOTE: Mocking api key for testing and development in dev environment
          auth_key: userApiKey,
          ...(options?.params || {}),
        },
      }),
      options
    );
  };

  const getWithoutAuthorization = (uri: string, options: GetOptions = {}) => {
    exchange(
      axios.get(uri, {
        ...options,
        headers: {
          ...(options?.headers || {}),
        },
      }),
      options
    );
  };

  const post = (uri: string, { data = {}, ...options }: PostOptions = {}) => {
    exchange(
      axios.post(proxyPrefix + uri, data, {
        ...options,
        headers: {
          Authorization: getAuthToken(),
          ...(options?.headers || {}),
        },
      }),
      options
    );
  };

  const put = (uri: string, { data = {}, ...options }: PostOptions = {}) => {
    exchange(
      axios.put(proxyPrefix + uri, data, {
        ...options,
        headers: {
          Authorization: getAuthToken(),
          ...(options?.headers || {}),
        },
      }),
      options
    );
  };

  const deleteRequest = (uri: string, options: PostOptions = {}) => {
    exchange(
      axios.delete(proxyPrefix + uri, {
        ...options,
        headers: {
          Authorization: getAuthToken(),
          ...(options?.headers || {}),
        },
      }),
      options
    );
  };

  const getCurl = (url: string, apiKey: string): string => {
    return `curl -X GET ${url}?auth_key=${apiKey}`;
  };

  return {
    get,
    post,
    put,
    getWithoutAuthorization,
    deleteRequest,
    getFromUnmarshalApi,
    getCurl,
    postFromUnmarshalApi,
  };
};

export default useRestClient;

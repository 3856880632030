import React from "react";
import { default as MuiAppBar } from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import { Link } from "react-router-dom";
import Container from "@mui/material/Container";
import { Divider, Grid, Stack } from "@mui/material";
import unmarshalConsoleTextPrimary from "../../assets/images/unmarshalConsoleTextPrimary.svg";
import MacBookPro from "../../assets/images/MacBookPro.svg";
import FAQsPreview from "../../assets/images/FAQsPreview.svg";
import Spacing from "../common/Spacing";
import Logo from "../dashboard/Logo";
// import UpgradeOptions from "../payments/UpgradeOptions";

const AppBar = () => (
  <MuiAppBar position="fixed">
    <Toolbar>
      <Typography variant="h6" component="div" sx={{ flexGrow: 1 }}>
        <Logo />
      </Typography>
      <Button component={Link} to="auth" color="primary">
        Sign in/Sign up
      </Button>
    </Toolbar>
  </MuiAppBar>
);

const LandingPageContent = () => (
  <Container component={"main"}>
    <Spacing spacing={15} />
    <Grid container justifyContent={"space-between"} spacing={20}>
      <Grid item xs={12} md={6}>
        <Box display={"flex"} justifyContent={"center"}>
          <Stack>
            <img src={unmarshalConsoleTextPrimary} alt="UNMARSHAL.CONSOLE" width={270} />
            <Spacing spacing={1} />
            <Typography variant={"h3"} fontWeight={"bold"}>
              BUIDL a dApp that you’re proud of
            </Typography>
            <Spacing spacing={2} />
            <Typography variant={"subtitle1"} sx={{ opacity: 0.4 }}>
              Our Unified console gives you the freedom to manage and develop your blockchain
              application exactly the way you want
            </Typography>
          </Stack>
        </Box>
      </Grid>
      <Grid item xs={12} md={6}>
        <img src={MacBookPro} alt="MacBookPro" />
      </Grid>
    </Grid>
    <Spacing spacing={15} />
    <Stack width={"100%"} justifyContent={"center"}>
      <Typography align={"center"} variant={"h4"} fontWeight={"bold"}>
        Everything you need to BUIDL on Blockchains
      </Typography>
      <Spacing spacing={1} />
      <Typography align={"center"} sx={{ opacity: 0.4 }}>
        Access our entire product suite on a single platform
      </Typography>
    </Stack>
    <Spacing spacing={10} />
    <Grid container justifyContent={"space-between"} spacing={20}>
      <Grid item xs={12} md={8}>
        <img src={FAQsPreview} alt="FAQsPreview" />
      </Grid>
      <Grid item xs={12} md={4}>
        <Stack>
          <Stack>
            <Typography align={"left"} variant={"h4"}>
              Easy to access and search
            </Typography>
            <Spacing spacing={2} />
            <Typography align={"left"} sx={{ opacity: 0.4 }}>
              Tokens that are verified by CoinMarketCap and Coingecko.
            </Typography>
          </Stack>
          <Spacing spacing={5} />
          <Divider />
          <Spacing spacing={5} />
          <Stack>
            <Typography align={"left"} variant={"h4"}>
              Detailed answers for each query
            </Typography>
            <Typography align={"left"} sx={{ opacity: 0.4 }}>
              Dedicated FAQ’s to help you out with regular problems and questions you have.
            </Typography>
          </Stack>
        </Stack>
      </Grid>
    </Grid>
    <Spacing spacing={10} />
    {/* <Stack width={"100%"} justifyContent={"center"}>*/}
    {/*  <Typography id={"pricing"} align={"center"} variant={"h4"} fontWeight={"bold"}>*/}
    {/*    Pricing*/}
    {/*  </Typography>*/}
    {/* </Stack>*/}
    {/* <Spacing spacing={10} />*/}
    {/* <UpgradeOptions />*/}
    {/* <Spacing spacing={20} />*/}
  </Container>
);

function Landing({ children }: React.PropsWithChildren<any>) {
  return (
    <Box sx={{ flexGrow: 1 }}>
      <AppBar />
      <LandingPageContent />
      {children}
    </Box>
  );
}

export default Landing;

import { atom, useRecoilState, useResetRecoilState } from "recoil";
import Box from "@mui/material/Box";
import React from "react";
import Button from "@mui/material/Button";

export const steps = [
  "Select parent event and plugin",
  "Enter sample contract or ABI",
  "Select events",
  "Add Plugins",
  "Name your child parser",
];

const stepAtom = atom<number>({
  key: "CHILD_INDEXER_STEP_STATE",
  default: 0,
});

export const useStepController = () => {
  const [activeStep, setActiveStep] = useRecoilState(stepAtom);
  const resetSteps = useResetRecoilState(stepAtom);

  const handleNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };
  return { handleBack, handleNext, activeStep, resetSteps };
};

const StepsController = () => {
  const { activeStep, handleBack } = useStepController();

  return (
    <React.Fragment>
      <Box sx={{ display: "flex", flexDirection: "row" }}>
        <Button
          color="inherit"
          variant={"outlined"}
          disabled={activeStep === 0}
          onClick={handleBack}
          sx={{ mr: 1 }}
        >
          Back
        </Button>
        <Box sx={{ flex: "1 1 auto" }} />
        <Button type={"submit"} variant={"outlined"}>
          {activeStep === steps.length - 1 ? "Add Child Parser" : "Next"}
        </Button>
      </Box>
    </React.Fragment>
  );
};

export default StepsController;

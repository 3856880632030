import React from "react";
import useNewChildParserFormValues from "./ChildParserFormStateHandler";
import { Controller, SubmitHandler, useForm, UseFormWatch } from "react-hook-form";
import { ParentContractInfoFormValues } from "../types";
import { CommonAbi, Input } from "../ABIDetailsClient";
import { Autocomplete, Stack, TextField, Typography } from "@mui/material";
import { some } from "lodash";
import StepsController from "./ChildParserStepsController";
import Spacing from "../../../common/Spacing";
import { Control, UseFormSetValue } from "react-hook-form/dist/types/form";
import { ParentContractInfoFormValidation } from "../Validations";
import { InfoRounded } from "@mui/icons-material";
import useNewIndexerFormValues from "../FormStateHandler";

const Inputs = ({
  control,
  watch,
}: {
  control: Control<ParentContractInfoFormValues>;
  watch: UseFormWatch<ParentContractInfoFormValues>;
  setValue: UseFormSetValue<ParentContractInfoFormValues>;
}) => {
  const plugin: CommonAbi | null = watch("eventName");

  return (
    <>
      {/* <Stack direction={"row"} justifyContent={"end"} spacing={2}>*/}
      {/*  <Controller*/}
      {/*    control={control}*/}
      {/*    name={"selectedInput.isTokenAddressIsInInputField"}*/}
      {/*    render={({ field: { onChange, value } }) => (*/}
      {/*      <FormControlLabel*/}
      {/*        control={*/}
      {/*          <Checkbox*/}
      {/*            checked={value}*/}
      {/*            onChange={(e) => {*/}
      {/*              setValue("selectedInput.addressOrInputField", null);*/}
      {/*              onChange(e.target.checked);*/}
      {/*            }}*/}
      {/*          />*/}
      {/*        }*/}
      {/*        labelPlacement={"end"}*/}
      {/*        label="Configure token address from input field"*/}
      {/*      />*/}
      {/*    )}*/}
      {/*  />*/}
      {/* </Stack>*/}
      <Stack direction={"row"} justifyContent={"space-between"} alignItems={"center"} spacing={4}>
        <Controller
          name={"selectedInput.input"}
          control={control}
          render={({ field: { onChange, name, value, onBlur }, fieldState }) => {
            return (
              <Autocomplete
                fullWidth
                // sx={{ maxWidth: 350 }}
                onBlur={onBlur}
                value={value}
                options={plugin?.inputs || []}
                getOptionLabel={(option) => option.name}
                filterOptions={(options: Array<Input>) =>
                  options.filter((input) => input.type === "address")
                }
                isOptionEqualToValue={(option, value) => option.name === value.name}
                onChange={(e, newValue) => {
                  onChange(newValue);
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    name={name}
                    label="Select token input field*"
                    helperText={!!fieldState?.error ? "Input field is required!" : ""}
                    error={!!fieldState?.error}
                  />
                )}
              />
            );
          }}
        />
        {/* <Controller*/}
        {/*  control={control}*/}
        {/*  name={"selectedInput.addressOrInputField"}*/}
        {/*  render={({ field: { onChange, name, value, onBlur }, fieldState }) => {*/}
        {/*    return !watch(`selectedInput.isTokenAddressIsInInputField`) ? (*/}
        {/*      <TextField*/}
        {/*        fullWidth*/}
        {/*        name={name}*/}
        {/*        value={value}*/}
        {/*        label="Enter Token Address"*/}
        {/*        onBlur={onBlur}*/}
        {/*        onChange={onChange}*/}
        {/*        helperText={!!fieldState?.error ? "Token address is required!" : ""}*/}
        {/*        error={!!fieldState?.error}*/}
        {/*      />*/}
        {/*    ) : (*/}
        {/*      <Autocomplete*/}
        {/*        fullWidth*/}
        {/*        onBlur={onBlur}*/}
        {/*        value={value as Input}*/}
        {/*        options={plugin?.inputs || []}*/}
        {/*        getOptionLabel={(option) => option.name}*/}
        {/*        filterOptions={(options: Array<Input>) =>*/}
        {/*          options.filter((input) => input.type === "address")*/}
        {/*        }*/}
        {/*        isOptionEqualToValue={(option, value) => option.name === value.name}*/}
        {/*        onChange={(e, value) => {*/}
        {/*          onChange(value);*/}
        {/*        }}*/}
        {/*        renderInput={(params) => (*/}
        {/*          <TextField*/}
        {/*            {...params}*/}
        {/*            name={name}*/}
        {/*            label={"Select input field"}*/}
        {/*            helperText={!!fieldState?.error ? "Token input field is required!" : ""}*/}
        {/*            error={!!fieldState?.error}*/}
        {/*          />*/}
        {/*        )}*/}
        {/*      />*/}
        {/*    );*/}
        {/*  }}*/}
        {/* />*/}
      </Stack>
    </>
  );
};
const ParentEventAndPluginForm = () => {
  const {
    updateParentContractInfo,
    childParserFormValue: { eventName, selectedInput },
  } = useNewChildParserFormValues();
  const methods = useForm<ParentContractInfoFormValues>({
    defaultValues: { eventName, selectedInput },
    resolver: ParentContractInfoFormValidation,
  });

  const {
    indexerFormValue: { events },
  } = useNewIndexerFormValues();
  const { handleSubmit, control, watch, setValue } = methods;

  const onSubmit: SubmitHandler<ParentContractInfoFormValues> = updateParentContractInfo;

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Stack spacing={3}>
        <Stack>
          <Typography sx={{ display: "flex", alignItems: "center" }}>
            Select the parent event and input field responsible for generating child contracts
          </Typography>
          <Typography sx={{ display: "flex", alignItems: "center", mb: 1, opacity: 0.4 }}>
            <InfoRounded />
            {" If you don't find the event in the list, please go back " +
              "and select it in parent events"}
          </Typography>
        </Stack>
        <Controller
          control={control}
          name={"eventName"}
          render={({ field: { onChange, name, value, onBlur }, fieldState }) => (
            <Autocomplete
              fullWidth
              onBlur={onBlur}
              value={value}
              options={events}
              filterOptions={(options: Array<CommonAbi>) =>
                options.filter((option) => some(option.inputs, (input) => input.type === "address"))
              }
              getOptionLabel={(option) => option.signature}
              isOptionEqualToValue={(option, value) => option.signature === value.signature}
              onChange={(e, newValue) => {
                onChange(newValue);
              }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  name={name}
                  label="Select event*"
                  helperText={
                    fieldState?.error?.message || (fieldState?.error as any)?.value?.message
                  }
                  error={!!fieldState?.error}
                />
              )}
            />
          )}
        />
        <Inputs control={control} watch={watch} setValue={setValue} />
      </Stack>
      <Spacing spacing={3} />
      <StepsController />
    </form>
  );
};

export default ParentEventAndPluginForm;

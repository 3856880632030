import React, { useEffect } from "react";
import { useParams } from "react-router-dom";
import useNotificationsClient from "./NotificationClient";
import { DataGrid, GridColDef } from "@mui/x-data-grid";
import { IconButton, Tooltip } from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";

const ListSubscriptions = () => {
  const { id } = useParams();
  const indexerID = id || "";
  const { subscriptions, getSubscriptions, resetSubscriptions, deleteSubscription } =
    useNotificationsClient();
  const columns: GridColDef[] = [
    {
      field: "subscription_name",
      width: 250,
      headerName: "Subscription Name",
    },
    {
      field: "webhook_url",
      headerName: "Webhook URL",
      flex: 1,
      renderCell: ({ value }) => <div style={{ overflow: "scroll" }}>{value}</div>,
    },
    {
      field: "last_event_block",
      width: 200,
      headerName: "Last Event Block",
      type: "number",
    },
    {
      field: "last_method_block",
      width: 200,
      headerName: "Last Method Block",
      type: "number",
    },
    {
      field: "Events",
      headerName: "Events",
      width: 200,
      renderCell: (params) => (
        <div style={{ overflow: "scroll" }}>
          {params.row?.tables
            .filter((table: any) => table?.SyncType === "event")
            .map((table: any) => table?.Table)
            .join(", ")}
        </div>
      ),
    },
    {
      field: "Functions",
      headerName: "Functions",
      width: 200,
      renderCell: (params) => (
        <div style={{ overflow: "scroll" }}>
          {params.row?.tables
            .filter((table: any) => table?.SyncType === "method")
            .map((table: any) => table?.Table)
            .join(", ")}
        </div>
      ),
    },
    {
      field: "id",
      headerName: "",
      type: "number",
      width: 50,
      renderCell: ({ value }) => (
        <Tooltip title={"Delete subscription"}>
          <IconButton onClick={() => deleteSubscription(value, indexerID)}>
            <DeleteIcon color={"secondary"} />
          </IconButton>
        </Tooltip>
      ),
    },
  ];

  useEffect(() => {
    getSubscriptions(indexerID);
    return resetSubscriptions;
  }, [indexerID]);

  return (
    <div>
      <DataGrid
        sx={{ fontSize: "16px" }}
        rows={subscriptions.data || []}
        columns={columns}
        getRowId={(row) => row.id}
        autoHeight
        loading={subscriptions.isLoading}
      />
    </div>
  );
};

export default ListSubscriptions;
